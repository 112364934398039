import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TechnicalDesignService from "services/technicaldesign/TechnicalDesignService";
import { useDispatch, useSelector } from "react-redux";
import Message from "components/common/Message";
import {
  setDesign,
  setDesignLayoutColumn,
} from "components/technicaldesign/reducer/TechnicalDesignAction";
import { Enums } from "components/builder/BuilderEnum";
import TechnicalDesignOverview from "components/technicaldesign/component/TechnicalDesignOverview";
import FunctionalSpecification from "components/technicaldesign/component/FunctionalSpecification";
import TechnicalSpecification from "components/technicaldesign/component/TechnicalSpecification";
import TechnicalDesignSelectionLayout from "./TechnicalDesignSelectionLayout";
import ObjectUtils from "components/common/utils/ObjectUtils";
import TechnicalDesignScreenLayout from "components/technicaldesign/component/TechnicalDesignScreenLayout";
import User from "components/common/utils/UserUtils";
import ArrayUtils from "components/common/utils/ArrayUtils";

const TechnicalDesignBuilderMain = (props) => {
  const technicalDesign = useSelector((state) => state.technicalDesign);

  const dispatch = useDispatch();

  useEffect(() => {
    getDesignMst();
  }, []);

  /**
   * Multilang Text 값을 찾아주는 함수
   * @param {String} multilangCd
   */
  const findMultilang = (multilangCd) => {
    if (technicalDesign.design && technicalDesign.design.designMultilang) {
      const multilangText = technicalDesign.design.designMultilang.find(
        (item) => item.multilangCd === multilangCd
      );
      if (!ObjectUtils.isEmpty(multilangText))
        return multilangText.multilangText;
      else return "";
    }
  };

  const tabContents = [
    {
      key: "overview",
      title: "Overview",
      program: <TechnicalDesignOverview />,
    },
    {
      key: "screen layout",
      title: "Screen Layout",
      program: <TechnicalDesignScreenLayout />,
    },
    {
      key: "functional specification",
      title: "Functional Specification",
      program: <FunctionalSpecification findMultilang={findMultilang} />,
    },
    {
      key: "technical specification",
      title: "Technical Specification",
      program: <TechnicalSpecification findMultilang={findMultilang} />,
    },
  ];

  const [activeTab, setActiveTab] = useState();
  const [designMultilang, setDesignMultilang] = useState();

  // Design Mst 조회 후 redux에 저장
  const getDesignMst = () => {
    //if (props.designMstId) {
    const data = {
      designMstId: 1,
    };
    TechnicalDesignService.getDesignMst(data, (result) => {
      if (result.isError) {
        Message.alert(
          "An error occurred while get data.",
          Enums.MessageType.ERROR
        );
      } else {
        if (!ArrayUtils.isEmpty(result.data.designMultilang)) {
          const designMultilang = result.data.designMultilang.filter(
            (item) => item.langCd === User.getLanguage().toLowerCase()
          );
          result.data.designMultilang = designMultilang;
        }
        dispatch(setDesign(result.data));
      }
    });
    TechnicalDesignService.getLayoutColumnBtn(data, (result) => {
      if (result.isError) {
        Message.alert(
          "An error occurred while get data.",
          Enums.MessageType.ERROR
        );
      } else {
        if (!ArrayUtils.isEmpty(result.data.designLayoutColumnLang)) {
          const designLayoutColumnLang =
            result.data.designLayoutColumnLang.filter(
              (item) => item.langCd === User.getLanguage().toLowerCase()
            );
          result.data.designLayoutColumnLang = designLayoutColumnLang;
        }
        dispatch(setDesignLayoutColumn(result.data));
      }
    });
    //}
  };

  // tab 클릭
  const onClickTab = (e, content) => {
    setActiveTab(content);
  };

  return (
    <Tabs id="technicalTabs" defaultActiveKey={activeTab?.key} justify>
      {tabContents.map((content) => {
        return (
          <Tab
            key={content.key}
            eventKey={content.key}
            onClick={(e) => onClickTab(e, content)}
            title={content.title}
          >
            {content.program}
          </Tab>
        );
      })}
    </Tabs>
  );
};

export default TechnicalDesignBuilderMain;

import { Card, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import SunEditor from "suneditor-react";
import { styled } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ObjectUtils from "components/common/utils/ObjectUtils";
import ArrayUtils from "components/common/utils/ArrayUtils";
import produce from "immer";
import { setDesign } from "../reducer/TechnicalDesignAction";
import CommonUtils from "components/common/utils/CommonUtils";
import TechnicalDesignSelectionLayout from "page/technicalDesign/TechnicalDesignSelectionLayout";

export const ScrollDiv = styled.div`
  margin-top: 30px;
  margin-left: 200px;
  margin-right: 200px;
  margin-bottom: 50px;
  padding-bottom: 50px;
  max-height: calc(100vh - 81px);
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #ccc;
  }
`;

const TechnicalDesignScreenLayout = () => {
  const technicalDesign = useSelector((state) => state.technicalDesign);
  const workspace = useSelector((state) => state.workspace);

  const [designMst, setDesignMst] = useState({});
  const [layoutContent, setLayoutContent] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    setDesignMst(technicalDesign.design);
  }, [technicalDesign]);

  /**
   * multilang text 가져오기
   * @param {*} key
   * @returns
   */
  const getMultilangText = (key) => {
    if (!ArrayUtils.isEmpty(designMst.designMultilang)) {
      return designMst.designMultilang.filter(
        (item) => item.multilangCd === key
      )[0]?.multilangText;
    } else {
      return "";
    }
  };

  /**
   * designMst 변경
   * @param {*} key
   * @param {*} value
   */
  const onChangeDesignMst = (key, value) => {
    if (ObjectUtils.isObject(designMst)) {
      const newDesignMst = produce(designMst, (draft) => {
        draft[key] = value;
      });
      setDesignMst(newDesignMst);
      dispatch(setDesign(newDesignMst));
    }
  };

  /**
   * designMultilang 변경
   * @param {*} key
   * @param {*} value
   */
  const onChangeDesignMultilang = (key, value) => {
    if (ObjectUtils.isObject(designMst)) {
      let newDesignMultilang = [];

      if (!ArrayUtils.isEmpty(designMst.designMultilang)) {
        newDesignMultilang = produce(designMst.designMultilang, (draft) => {
          draft.filter((item) => {
            if (item.multilangCd === key) {
              item.multilangText = value;
            }
            return item;
          });
        });
      } else {
        const newData = {
          multilangCd: key,
          multilangText: value,
        };
        newDesignMultilang.push(newData);
      }
      onChangeDesignMst("designMultilang", newDesignMultilang);
    }
  };

  const renderScreenLayout = () => {
    return (
      <Row className="mt-3">
        <Col>
          <SunEditor
            lang={"ko"}
            onBlur={(value) => {
              onChangeDesignMultilang("screen_layout", value);
            }}
            setContents={getMultilangText("screen_layout")}
            setDefaultStyle="font-family: 'Noto Sans KR', sans-serif;  min-height : 500px; max-height : 55vh"
            setOptions={{
              font: [
                "Noto Sans KR",
                "Arial",
                "Comic Sans MS",
                "Courier New",
                "Impact",
                "Georgia",
                "tahoma",
                "Trebuchet MS",
                "Verdana",
              ],
              fontSize: [
                8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48,
                72,
              ],
              buttonList: [
                ["font", "fontSize", "formatBlock"],
                [
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "fontColor",
                  "hiliteColor",
                ],
              ],
            }}
          />
        </Col>
      </Row>
    );
  };

  const tabContents = [
    {
      key: "screenLayout",
      title: "Screen Layout",
      program: renderScreenLayout(),
    },
    {
      key: "selectionLayout",
      title: "Selection Layout",
      program: <TechnicalDesignSelectionLayout />,
    },
    {
      key: "displayLayout",
      title: "Display Layout",
      program: <TechnicalDesignSelectionLayout />,
    },
  ];

  return (
    <ScrollDiv>
      <Row>
        <Col>
          <Card style={{ backgroundColor: "rgb(237 242 250)" }}>
            <Card.Body>
              <Card.Text style={{ textAlign: "center" }}>
                Screen Layout
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={2}>
          <Form.Label>Module</Form.Label>
        </Col>
        <Col xs={4}>
          <Form.Control value={workspace.moduleNm} disabled />
        </Col>
        <Col xs={2}>
          <Form.Label>Sub Module</Form.Label>
        </Col>
        <Col xs={4}>
          <Form.Control
            defaultValue={getMultilangText("sub_module_nm")}
            onBlur={(e) =>
              onChangeDesignMultilang("sub_module_nm", e.target.value)
            }
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={2}>
          <Form.Label>Program ID</Form.Label>
        </Col>
        <Col xs={4}>
          <Form.Control
            defaultValue={designMst.programId}
            onBlur={(e) => onChangeDesignMst("programId", e.target.value)}
          />
        </Col>
        <Col xs={2}>
          <Form.Label>Editor</Form.Label>
        </Col>
        <Col xs={4}>
          <Form.Control defaultValue={designMst.updtUserId} disabled />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={2}>
          <Form.Label>Program Name</Form.Label>
        </Col>
        <Col xs={4}>
          <Form.Control
            defaultValue={getMultilangText("program_nm")}
            onBlur={(e) =>
              onChangeDesignMultilang("program_nm", e.target.value)
            }
          />
        </Col>
        <Col xs={2}>
          <Form.Label>Edit Date</Form.Label>
        </Col>
        <Col xs={4}>
          <Form.Control
            id="updtDt"
            disabled
            defaultValue={CommonUtils.getDate(designMst.updtDt)}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={2}>
          <Form.Label>Program Overview</Form.Label>
        </Col>
        <Col xs={10}>
          <Form.Control
            as="textarea"
            defaultValue={getMultilangText("program_desc")}
            onBlur={(e) =>
              onChangeDesignMultilang("program_desc", e.target.value)
            }
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Tabs>
            {tabContents.map((content) => {
              return (
                <Tab
                  key={content.key}
                  eventKey={content.key}
                  title={content.title}
                >
                  {content.program}
                </Tab>
              );
            })}
          </Tabs>
        </Col>
      </Row>
    </ScrollDiv>
  );
};
export default TechnicalDesignScreenLayout;

import { TextField as MInput } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import UITemplateHelper from "components/builder/ui/editor/helper/UITemplateHelper";
import { TemplateCheckbox } from "components/builder/ui/uiComponents/UIComponentStyle";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import Popup from "components/common/Popup";
import ArrayUtils from "components/common/utils/ArrayUtils";
import JsonUtils from "components/common/utils/JsonUtils";
import NamingUtils from "components/common/utils/NamingUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, ModalFooter, Row, Table } from "react-bootstrap";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { MdDelete } from "react-icons/md";
import DataModelService from "services/datamodel/DataModelService";
import TrdService from "services/trd/TrdService";

const FormTypeSelectPopup = ({
  componentInfo,
  defaultValues,
  componentContext,
  templateComponents,
  updateData,
  output,
  ...props
}) => {
  const [formLayoutType, setFormLayoutType] = useState(
    ArrayUtils.isEmpty(componentInfo.propertyValue.entityFieldList)
      ? Enums.FormType.SEARCH
      : Enums.FormType.SAVE
  ); // 폼 레이아웃 타입 설정 : dataModel인 경우 검색 폼, workflow인 경우 입력 폼
  const [inputValue, setInputValue] = useState({
    row: 1,
    col: 3,
    hasHeaderTitle: true,
    hasButtons: true,
  });
  const [fieldList, setFieldList] = useState([]);
  const [dropTableData, setDropTableData] = useState([]); // 실재 그려질 2차원배열 테이블 형식의 데이터

  // init
  useEffect(() => {
    // 데이터모델인 경우 identifier는 숫자데이터
    if (!isNaN(componentInfo.propertyValue.identifier)) {
      const dataModelEntityId = componentInfo.propertyValue.identifier;
      // 데이터 모델인 경우 entity field들을 조회해서 세팅
      DataModelService.getDataModelEntityFieldList(
        { entityId: dataModelEntityId },
        async (result) => {
          if (ArrayUtils.isEmpty(result.data)) {
            Message.alert(
              "There is no column information for the selected Data Entity.",
              Enums.MessageType.ERROR
            );
          } else {
            const elementIdObj = await getDataModelTrdFieldList();

            result.data.forEach((field) => {
              field.elementId = elementIdObj[field.columnNm].elementId;
              field.fieldDefaultValue =
                elementIdObj[field.columnNm].fieldDefaultValue;
              field.element = elementIdObj[field.columnNm].element;
              // 참조 currency 정보가 있는경우에 추가
              if (elementIdObj[field.columnNm].refFieldId) {
                field.refFieldId = elementIdObj[field.columnNm].refFieldId;
                field.refTableId = elementIdObj[field.columnNm].refTableId;
              }
            });
            setFieldList(result.data);
          }
        }
      );
    } else if (
      !ArrayUtils.isEmpty(componentInfo.propertyValue.entityFieldList)
    ) {
      // workflow의 경우 propertyValue의 entityFieldList를 참조해서 세팅
      // 참조 fieldId가 있는 경우 해당 entity에는 refFieldId, refTableId를 추가로 넣어주는 로직을 추가해야함. tableFieldIdList : []
      const refFieldIdList = componentInfo.propertyValue.entityFieldList.map(
        (item) => {
          return item.trdTableFieldId;
        }
      );
      TrdService.getTableFieldList({ tableFieldIdList: refFieldIdList }).then(
        (res) => {
          if (res.data) {
            const hasRefFieldIds = res.data.filter(
              (field) => !StringUtils.isEmpty(field.refFieldId)
            );
            componentInfo.propertyValue.entityFieldList.forEach((item) => {
              const trdField = hasRefFieldIds.find((field) =>
                StringUtils.equalsIgnoreCase(
                  field.fieldId.toString(),
                  item.trdTableFieldId.toString()
                )
              );
              if (!ObjectUtils.isEmpty(trdField)) {
                item.refFieldId = trdField.refFieldId;
                item.refTableId = trdField.refTableId;
              }
            });
            setFieldList(componentInfo.propertyValue.entityFieldList);
          } else {
            setFieldList(componentInfo.propertyValue.entityFieldList);
          }
        }
      );
    }
    tableDataSetting(inputValue.row, inputValue.col);
  }, []);

  /**
   * elementCd : elementId 의 keyValue Obj로 뽑아내는 함수
   */
  const getDataModelTrdFieldList = async () => {
    const tableMstId = componentInfo.propertyValue.tableMstId;
    const trdTable = await TrdService.getTableInfo({
      tableMstId,
    });
    let elementIdList = {};
    for (const tableField of trdTable.data.trdTableField) {
      elementIdList[tableField.element.elementCd] = {
        elementId: tableField.element.elementId,
        fieldDefaultValue: tableField.fieldDefaultValue,
        element: tableField.element,
        refFieldId: tableField.refFieldId,
        refTableId: tableField.refTableId,
      };
    }
    return elementIdList;
  };

  const getDefaultHeading = {
    headingType: "H5",
    className: "tit-grid",
    text: "Form title",
  };

  const searchColPropertyValue = {
    style: {
      display: "flex",
      width: "100px",
      justifyContent: "right",
      textAlign: "right",
      flex: "0 0 100px",
    },
  };

  /**
   * 생성할 form이 검색폼인지 확인하는 함수
   * @returns {Boolean}
   */
  const isSearchForm = () => {
    return StringUtils.equalsIgnoreCase(formLayoutType, Enums.FormType.SEARCH);
  };

  /**
   * row 와 col 길이를 입력받아 table데이터를 세팅하는 함수
   * @param {Number} row
   * @param {Number} col
   */
  const tableDataSetting = (row, col) => {
    const table = [...dropTableData];
    let settingTable = Array.from({ length: row }, () =>
      Array.from({ length: col })
    );
    for (let rowIdx = 0; rowIdx < row; rowIdx++) {
      for (let colIdx = 0; colIdx < col; colIdx++) {
        if (table.length > rowIdx) {
          if (table[0].length > colIdx) {
            settingTable[rowIdx][colIdx] = table[rowIdx][colIdx];
          }
        }
      }
    }
    setDropTableData(settingTable);
  };

  /**
   * table에서 삭제버튼 이벤트
   */
  const deleteTableData = (rowIdx, colIdx) => {
    const settingTable = [...dropTableData];
    settingTable[rowIdx][colIdx] = "";
    setDropTableData(settingTable);
  };

  const closePopup = () => {
    if (props.callbackFn) {
      const newForm = createFormComponent();
      props.callbackFn(newForm, updateData);

      Popup.close();
    }
  };

  /**
   * Popup에 설정한 option을 기준으로 그릴 Form Component를 생성하는 함수
   */
  const createFormComponent = () => {
    /**
     * 1. form layout type : 검색 or 입력 form
     * 1.1. 입력 form 일때 has title, has button
     */
    let newForm = UITemplateHelper.getFormTemplate(templateComponents);
    // 하위 component dnd 인식을 위해 indentifier, isDataStudioElement 추가
    newForm.propertyValue.identifier = componentInfo.propertyValue.identifier;
    newForm.propertyValue.isDataStudioElement =
      componentInfo.propertyValue.isDataStudioElement;
    // dataModel로 생성된 form인 경우 데이터모델 세팅
    if (!isNaN(componentInfo.propertyValue.identifier))
      newForm.propertyValue.dataModelEntityId =
        componentInfo.propertyValue.identifier;
    if (isSearchForm()) {
      newForm.propertyValue.className = "search-wrap";
      newForm.propertyValue.formType = Enums.FormType.SEARCH;
      newForm.propertyValue.id = NamingUtils.getComponentId(
        Enums.ComponentType.FORM,
        output.page,
        newForm.propertyValue
      );
      // 2. row, col : 선택한 그리드 row를 순서대로 col에 세팅
      createSearchFormRowCol(newForm);
    } else {
      newForm.propertyValue.formType = Enums.FormType.SAVE;
      newForm.propertyValue.id = NamingUtils.getComponentId(
        Enums.ComponentType.FORM,
        output.page,
        newForm.propertyValue
      );
      let newTitleRow = UITemplateHelper.getRowTemplate(templateComponents);

      //header title이 선택된 경우
      if (inputValue.hasHeaderTitle) {
        let newTitleCol = UITemplateHelper.getColTemplate(templateComponents);
        let newHeading =
          UITemplateHelper.getHeadingTemplate(templateComponents);
        newHeading.propertyValue = {
          ...newHeading.propertyValue,
          ...getDefaultHeading,
        };
        newTitleCol.child.push(newHeading);
        newTitleRow.child.push(newTitleCol);
      }

      //버튼 포함이 표시된 경우
      if (inputValue.hasButtons && defaultValues) {
        let newButtonCol = UITemplateHelper.getColTemplate(templateComponents);

        //button block
        let newBlock = UITemplateHelper.getBlockTemplate(templateComponents);
        newBlock.propertyValue.style = {
          ...newBlock.propertyValue.style,
          ...UITemplateHelper.getDefaultRightButtonGroupStyle(),
        };
        newBlock.propertyValue.isButtonGroup = true;

        for (let i = 0; i < defaultValues.buttons.length; i++) {
          let newButton =
            UITemplateHelper.getButtonTemplate(templateComponents);
          newButton.propertyValue = {
            ...newButton.propertyValue,
            ...defaultValues.buttons[i],
          };
          newBlock.child.push(newButton);
        }
        newButtonCol.child.push(newBlock);
        newTitleRow.child.push(newButtonCol);
      }
      newForm.child.push(newTitleRow);
      // 2. row, col : 선택한 그리드 row를 순서대로 col에 세팅
      createInputFormRowCol(newForm);
    }

    return newForm;
  };

  /**
   * 입력 form의 상세데이터를 파라미터로 받은 newForm에 추가하는 함수
   * @param {Form} newForm
   */
  const createInputFormRowCol = (newForm) => {
    //container
    let newContainer =
      UITemplateHelper.getContainerTemplate(templateComponents);
    newContainer.propertyValue.className = "alpha-grid border";

    //Row
    for (let i = 0; i < inputValue.row; i++) {
      let newRow = UITemplateHelper.getRowTemplate(templateComponents);

      for (let j = 0; j < inputValue.col; j++) {
        let newCol = UITemplateHelper.getColTemplate(templateComponents);
        const data = { ...dropTableData[i][j] };
        if (!ObjectUtils.isEmpty(data)) {
          const newComponent = dataTemplate(data);
          let newInput = UITemplateHelper.getInputTemplate(templateComponents);
          newInput.propertyValue.layoutType = "E"; //입력 form : E , 검색 form : D
          newInput.propertyValue.labelWidth = "4";

          newCol.child.push(newComponent);
        }
        newRow.child.push(newCol);
      } //end of col

      newContainer.child.push(newRow);
    } //end of row
    newForm.child.push(newContainer);
  };

  /**
   * 검색 form의 상세데이터를 파라미터로 받은 newForm에 추가하는 함수
   * @param {Form} newForm
   */
  const createSearchFormRowCol = (newForm) => {
    //Row
    for (let i = 0; i < inputValue.row; i++) {
      let newRow = UITemplateHelper.getRowTemplate(templateComponents);

      //col + input + [button col + button]
      for (let j = 0; j <= inputValue.col; j++) {
        if (j < inputValue.col) {
          let newCol = UITemplateHelper.getColTemplate(templateComponents);
          const data = { ...dropTableData[i][j] };

          if (!ObjectUtils.isEmpty(data)) {
            const newComponent = dataTemplate(data);
            let newInput =
              UITemplateHelper.getInputTemplate(templateComponents);
            newInput.propertyValue.layoutType = "D"; //입력 form : E , 검색 form : D
            newInput.propertyValue.labelWidth = "4";

            newCol.child.push(newComponent);
          }
          newRow.child.push(newCol);

          //검색버튼 공간
        } else {
          let newCol = UITemplateHelper.getColTemplate(templateComponents);
          newCol.propertyValue = {
            ...newCol.propertyValue,
            ...searchColPropertyValue,
          };
          //첫번째 row에 검색버튼 추가 마지막 -> if (i === inputValue.row - 1) {
          if (i === 0) {
            let newButton =
              UITemplateHelper.getButtonTemplate(templateComponents);
            newButton.propertyValue = {
              ...newButton.propertyValue,
              ...UITemplateHelper.getSearchBtnPropertyValue(),
            };

            newCol.child.push(newButton);
          }
          newRow.child.push(newCol);
          //나머지 row에 space
        }
      } //end of col
      newForm.child.push(newRow);
    } //end of row
  };

  /**
   * Data Type에 따라 input, select, singleDatePicker 중 하나로 세팅
   * @param {Object} data  table[row][col] data
   * @returns {component}
   */
  const dataTemplate = (data) => {
    let component = null;
    // domain에서 combo 데이터로 삽입하겠다고 정의시
    if (StringUtils.equalsIgnoreCase(data.element?.domain?.entryDisType, "C")) {
      component = UITemplateHelper.getSelectTemplate(templateComponents);
      component.propertyValue.idColumn = "id";
      component.propertyValue.textColumn = "text";
      component.propertyValue.comboType = "basic";

      const entryType = data.element.entryType || data.element.domain.entryType;
      const isElementEntry = !StringUtils.isEmpty(data.element.entryRefTable)
        ? true
        : false;

      // entryType이 entry = possibleEntry / table = table참조
      if (entryType === "entry") {
        component.propertyValue.searchTp = "STATIC";
        const comboMapping = (possibleEntryList) => {
          const data = possibleEntryList.map((item) => {
            return { id: item.entryValue, text: item.entryText };
          });
          return !ArrayUtils.isEmpty(data) && data;
        };
        const elementPEL = comboMapping(data.element?.possibleEntryList);
        const domainPEL = comboMapping(data.element?.domain.possibleEntryList);
        component.propertyValue.comboItems = elementPEL || domainPEL;
      } else if (entryType === "table") {
        component.propertyValue.searchTp = "REF";
        component.propertyValue.entryRefTable = isElementEntry
          ? data.element.entryRefTable
          : data.element.domain.entryRefTable;
        component.propertyValue.entryRefWhere = isElementEntry
          ? data.element.entryRefWhere
          : data.element.domain.entryRefWhere;
        component.propertyValue.idColumn = isElementEntry
          ? data.element.entryRefKey
          : data.element.domain.entryRefKey;
        component.propertyValue.textColumn = isElementEntry
          ? data.element.entryRefValue
          : data.element.domain.entryRefValue;
        component.propertyValue.comboUrl =
          "/common/builder/runtime/tableRef/readCombo.do";
      }
    } else if (
      StringUtils.equalsIgnoreCase(data.option?.dataType, "date") ||
      StringUtils.equalsIgnoreCase(data.columnType, "date")
    ) {
      // dataType이 date 인 경우 singleDatePicker 템플릿
      const datePickerInfo = componentContext.getComponentInfo("B", 76);
      const datePickerComponent = {
        compId: "",
        type: "",
      };
      datePickerComponent.compId = `${StringUtils.substringAfter(
        datePickerInfo.componentClass,
        "/"
      )}-${StringUtils.getUuid()}`;
      datePickerComponent.type = datePickerInfo.componentType;
      datePickerComponent.baseCompId = datePickerInfo.componentDtlId;
      datePickerComponent.propertyValue =
        JsonUtils.parseJson(datePickerInfo.defaultProperty) || {};
      datePickerComponent.editorAttr = JsonUtils.parseJson(
        datePickerInfo.editorAttr
      );
      datePickerComponent.viewerAttr = JsonUtils.parseJson(
        datePickerInfo.viewerAttr
      );

      if (NamingUtils.hasIdNamingRule(datePickerInfo.componentType)) {
        datePickerComponent.propertyValue.id = NamingUtils.getComponentId(
          datePickerComponent.type,
          output.page,
          datePickerComponent.propertyValue
        );
      }
      component = datePickerComponent;
    } else {
      component = UITemplateHelper.getInputTemplate(templateComponents);
      if (
        data.option?.columnType.includes("(") ||
        data.columnType?.includes("(")
      ) {
        component.propertyValue.maxlength =
          data.option?.columnType.slice(
            data.option.columnType.indexOf("(") + 1,
            data.option.columnType.indexOf(")")
          ) ||
          data.columnType?.slice(
            data.columnType.indexOf("(") + 1,
            data.columnType.indexOf(")")
          );
      }
      component.propertyValue.type = "text";
      convertDataType(component.propertyValue, data.element);

      // // 소수의 경우 사용자 정의 포멧설정을 해줘야하나????
      // if (getDataCase(data.element?.domain.dataType) === CaseType.Decimal) {
      //   const decimalFotmat = {
      //     formatId: NamingUtils.getUuid(),
      //     formatName: data.element.elementNm + " format",
      //     decimalPoint: data.element.domain.decimals,
      //     roundingPolicy: "1", // 기본 반올림
      //   };
      //   output.page.propertyValue.userDefindFormat = [
      //     ...output.page.propertyValue.userDefindFormat,
      //     decimalFotmat,
      //   ];
      // }
    }

    component.propertyValue.layoutType = StringUtils.equalsIgnoreCase(
      formLayoutType,
      Enums.FormType.SEARCH
    )
      ? "D"
      : "E";
    component.propertyValue.labelWidth = "4";
    component.propertyValue.id = data.fieldId || data.physFieldNm;
    component.propertyValue.formLabel = data.fieldNm || data.logFieldNm;
    // workflow return data에서 updatableYn은 N일때 readonly
    component.propertyValue.isReadonly =
      (data.option?.updatableYn &&
        !StringUtils.equalsIgnoreCase(data.option?.updatableYn, "Y")) ||
      StringUtils.equalsIgnoreCase(data.readonlyYn, "Y");
    component.propertyValue.isRequired =
      StringUtils.equalsIgnoreCase(data.option?.requiredYn, "Y") ||
      StringUtils.equalsIgnoreCase(data.requiredYn, "Y");

    component.propertyValue.dataBinding = data.id;
    component.propertyValue.default = data.value || data.fieldDefaultValue;
    component.propertyValue.elementId = data.elementId;
    component.propertyValue.refTableId = data.refTableId;
    component.propertyValue.refFieldId = data.refFieldId;

    return component;
  };

  const convertDataType = (field, element) => {
    // type : text or number or date
    // numberType : 숫자일때만 들어가는 것 === formType
    const dataCase = Enums.getDataCase(element.domain.dataType);
    if (
      StringUtils.includesIgnoreCase(dataCase, [
        Enums.CaseType.Number,
        Enums.CaseType.Decimal,
      ])
    ) {
      //숫자 number, decimal 소수점 숫자 의 경우
      field.type = "number";
      if (
        StringUtils.includes(element.domain.formType, [
          "0", //금액
          "1", //수량
          "2", //단가
          "3", //환율
        ])
      ) {
        field.numberType = element.domain.formType.toString();
      }
    } else if (StringUtils.equalsIgnoreCase(dataCase, Enums.CaseType.Date)) {
      field.type = "datetime";
    }
  };

  /**
   * Drag flag
   */
  const draggableItem = { FIELD: "field", IN_TABLE: "table" };

  /**
   * Drag 할 셀 랜더
   * @param {*} param0
   * @returns
   */
  const DraggableCell = ({ data, filedJson }) => {
    const [{ isDragging }, drag] = useDrag({
      type: draggableItem.FIELD,
      item: { data, type: draggableItem.FIELD }, // drag 로 넘기는 데이터
      collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    });
    return (
      <td
        ref={drag}
        style={{
          opacity: isDragging ? 0.5 : 1,
          cursor: "move",
          border: "1px solid #ccc",
          width: "100%",
        }}
      >
        {data.fieldId || data.physFieldNm}
      </td>
    );
  };

  /**
   * Drop 될 셀 랜더
   * @param {*} param0
   * @returns
   */
  const DroppableCell = ({ data, onDrop, rowIndex, colIndex }) => {
    const [{ isOver }, drop] = useDrop({
      accept: [draggableItem.FIELD, draggableItem.IN_TABLE], // drop을 허용하는 요소
      drop: (item) => {
        if (item.type === draggableItem.FIELD) {
          // 외부에서 테이블로 드래그 앤 드롭
          onDrop(item.data, rowIndex, colIndex);
        } else if (item.type === draggableItem.IN_TABLE) {
          // 테이블 내부 셀 간 드래그 앤 드롭
          if (item.rowIndex !== rowIndex || item.colIndex !== colIndex) {
            moveCell(item.rowIndex, item.colIndex, rowIndex, colIndex);
            item.rowIndex = rowIndex;
            item.colIndex = colIndex;
          }
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    });
    const [{ isDragging }, drag] = useDrag({
      type: draggableItem.IN_TABLE,
      item: { rowIndex, colIndex, type: draggableItem.IN_TABLE },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    return (
      <td
        ref={(node) => drag(drop(node))}
        style={{
          backgroundColor: isOver && "lightblue",
          verticalAlign: "center",
          minHeight: "40px",
          opacity: isDragging ? 0.5 : 1,
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <Col xs={10}>{data}</Col>
          <Col xs={2}>
            {!StringUtils.isEmpty(data) && (
              <Button
                variant="outline-danger"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "5px",
                }}
                size="sm"
                onClick={() => deleteTableData(rowIndex, colIndex)}
              >
                <MdDelete />
              </Button>
            )}
          </Col>
        </div>
      </td>
    );
  };

  /**
   * Drop시 이벤트
   * @param {Object} droppedData
   * @param {Index} rowIdx
   * @param {Index} colIdx
   */
  const handleDrop = (droppedData, rowIdx, colIdx) => {
    const tempTable = [...dropTableData];
    tempTable[rowIdx][colIdx] = droppedData;
    setDropTableData(tempTable);
  };

  /**
   * 테이블 내부에서 dnd시 이벤트
   * @param {*} fromRow
   * @param {*} fromCol
   * @param {*} toRow
   * @param {*} toCol
   */
  const moveCell = (fromRow, fromCol, toRow, toCol) => {
    const newData = [...dropTableData];
    const temp = newData[fromRow][fromCol];
    newData[fromRow][fromCol] = newData[toRow][toCol];
    newData[toRow][toCol] = temp;

    setDropTableData(newData);
  };

  return (
    <Modal>
      <Modal.Header title="Form Type Select" />
      <Modal.Body>
        <Row className="row p-10">
          <Col>
            <Form.Label>Form Layout Type</Form.Label>
            <Form.Select
              id="formLayoutType"
              value={formLayoutType}
              disabled={
                !ArrayUtils.isEmpty(componentInfo.propertyValue.entityFieldList)
              }
              onChange={(e) => setFormLayoutType(e.target.value)}
            >
              <option value={Enums.FormType.SEARCH}>Search Form</option>
              <option value={Enums.FormType.SAVE}>Input Form</option>
            </Form.Select>
          </Col>
        </Row>
        <Row className="p-10">
          <Col>
            Please enter the number of rows and columns to configure the Form
            Layout.
          </Col>
        </Row>
        <Row className="row p-10">
          <Col>
            <MInput
              id="row"
              label="Row"
              size="small"
              type="number"
              className="xmall-input"
              value={inputValue.row}
              onChange={(e) => {
                setInputValue({ ...inputValue, row: e.target.value });
                tableDataSetting(e.target.value, inputValue.col);
              }}
            />
          </Col>
        </Row>
        <Row className="p-10">
          <Col>
            <MInput
              id="col"
              label="Column"
              size="small"
              type="number"
              className="xmall-input"
              value={inputValue.col}
              onChange={(e) => {
                setInputValue({ ...inputValue, col: e.target.value });
                tableDataSetting(inputValue.row, e.target.value);
              }}
            />
          </Col>
        </Row>
        {/* 입력 Form 일때만 활성화 + 입력 Form일때만 has 설정을 세팅 */}
        {StringUtils.equalsIgnoreCase(formLayoutType, Enums.FormType.SAVE) && (
          <React.Fragment>
            <Row className="p-10">
              <Col>
                <TemplateCheckbox
                  type="checkbox"
                  name="hasHeaderTitle"
                  className="xmall-input"
                  defaultChecked={inputValue.hasHeaderTitle}
                  onChange={(e) =>
                    setInputValue((prevState) => ({
                      ...prevState,
                      hasHeaderTitle: e.target.checked, // 체크 상태를 업데이트
                    }))
                  }
                />
                Include Form Title Area
              </Col>
            </Row>
            <Row className="p-10">
              <Col>
                <TemplateCheckbox
                  type="checkbox"
                  name="hasButtons"
                  className="xmall-input"
                  defaultChecked={inputValue.hasButtons}
                  onChange={(e) =>
                    setInputValue((prevState) => ({
                      ...prevState,
                      hasButtons: e.target.checked, // 체크 상태를 업데이트
                    }))
                  }
                />
                Include Form Button Area
              </Col>
            </Row>
          </React.Fragment>
        )}
        <DndProvider backend={HTML5Backend}>
          <Row className="p-10">
            <Row style={{ height: "25px" }}>
              <div style={{ width: "20%" }}>
                <Form.Label htmlFor="dataModelEntityField">
                  Data Entity Field List
                </Form.Label>
              </div>
              <div style={{ width: "6%" }}></div>
              <div style={{ width: "74%" }}>
                <Form.Label htmlFor="dataModelEntityField">
                  Field List to Apply
                </Form.Label>
              </div>
            </Row>
            <Row>
              <div
                style={{
                  width: "20%",
                  maxHeight: "30vh",
                  overflow: "auto",
                }}
              >
                {/* form component가 가지고 있는 data */}
                <Table
                  striped
                  bordered
                  hover
                  style={{
                    width: "100%",
                    tableLayout: "fixed",
                    marginBottom: "0px",
                    borderCollapse: "collapse",
                    borderSpacing: 0,
                  }}
                >
                  <thead>
                    <tr
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      <th>ID</th>
                    </tr>
                  </thead>
                  <tbody
                    style={{
                      width: "100%",
                    }}
                  >
                    {fieldList.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <DraggableCell data={item} />
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <div style={{ width: "6%" }}></div>
              {/* 그려질 form 컴포넌트 table */}
              <div
                style={{ width: "74%", maxHeight: "30vh", overflowY: "auto" }}
              >
                <Table
                  striped
                  bordered
                  hover
                  style={{
                    width: "100%",
                    tableLayout: "fixed",
                    marginBottom: "0px",
                    borderCollapse: "collapse",
                    borderSpacing: 0,
                  }}
                >
                  <tbody>
                    {!ArrayUtils.isEmpty(dropTableData) &&
                      dropTableData.map((row, rowIdx) => {
                        return (
                          <tr key={rowIdx} style={{ height: "40px" }}>
                            {row.map((col, colIdx) => {
                              return (
                                <DroppableCell
                                  key={`${rowIdx}-${colIdx}`}
                                  data={col?.fieldId || col?.physFieldNm}
                                  rowIndex={rowIdx}
                                  colIndex={colIdx}
                                  onDrop={handleDrop}
                                />
                              );
                            })}
                            {isSearchForm() && rowIdx === 0 && (
                              <td
                                key={"btnSearch"}
                                style={{
                                  width: "75px",
                                }}
                              >
                                {"Search Button"}
                              </td>
                            )}
                            {isSearchForm() && rowIdx !== 0 && (
                              <td key={"none" + rowIdx}>
                                <div>{"none"}</div>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </Row>
          </Row>
        </DndProvider>
      </Modal.Body>
      <ModalFooter>
        <Button onClick={(e) => closePopup()}>Confirm</Button>
        <Button className="btn-default" onClick={(e) => Popup.close()}>
          close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FormTypeSelectPopup;

import React, { Component, Fragment } from "react";
import produce from "immer";
import {
  Accordion,
  ToggleButtonGroup,
  ToggleButton,
  ButtonGroup,
  InputGroup,
  Button,
} from "react-bootstrap";
import {
  PropertiesHeader,
  PropertyLable,
  PropertyValue,
} from "components/builder/ui/uiComponents/UIComponentStyle";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import styled from "styled-components";
import {
  FaAlignLeft,
  FaAlignCenter,
  FaAlignRight,
  FaAlignJustify,
  FaItalic,
  FaUnderline,
  FaStrikethrough,
} from "react-icons/fa";
import { FiDelete } from "react-icons/fi";
import { MdOutlineImageSearch } from "react-icons/md";
import { AiOutlineFunction } from "react-icons/ai";

import {
  StringUtils,
  JsonUtils,
  ObjectUtils,
  ArrayUtils,
} from "components/common/utils/CommonUtils";
import USelectbox from "components/common/element/USelectbox";
import Popup from "components/common/Popup";
import DataModelPopup from "page/popup/dataModel/DataModelPopup";
import UInputPopup from "components/common/element/UInputPopup";
import Message from "components/common/Message";
import DataModelService from "services/datamodel/DataModelService";
import ValidationPopup from "page/popup/ValidationPopup";
import { AppContext } from "components/common/AppContextProvider";
import UElementList from "components/common/element/UElementList";
import UTextarea from "components/common/element/UTextarea";
import { Enums } from "components/builder/BuilderEnum";
import PopupHandleConfigPopup from "page/popup/PopupHandleConfigPopup";
import MesFunTabPopup from "page/popup/MesFunTabPopup";
import ColorPicker from "components/builder/ui/ColorPicker";
import { ComponentSavePopupButton } from "page/popup/ComponentSavePopup";
import ExtendPopup from "page/popup/ExtendPopup";
import UCodeMirrorButton from "components/common/element/UCodeMirrorButton";
import WorkflowListPopup from "page/popup/workflow/WorkflowListPopup";
import WorkflowDetailPopup from "page/popup/workflow/WorkflowDetailPopup";

const CommonStyled = styled.div`
  /** Style 항목: Alignment 관련 **/
  .btn-align {
    background-color: #333333 !important;
    background-image: none;
    border: none;
  }
  .btn-align:hover,
  input[type="radio"]:checked + label.btn-align {
    background-color: #1e282c !important;
  }
  .btn-align svg:hover {
    background-image: none !important;
    background-color: #1e282c !important;
    border: none;
  }

  .w-13p {
    width: 13% !important;
  }
  .w-87p {
    width: 87% !important;
  }
  .data-persent {
    background-color: #0d6efd !important;
    color: #fff !important;
  }

  .filewrap {
    position: relative;
    display: table;
    border-collapse: separate;
    width: 100%;
    height: 100%;
  }
  .filewrap input {
    display: table-cell;
    box-sizing: border-box;
    padding-right: 20px;
    border-radius: 0.2rem 0 0 0.2rem;
    border-right: none;
  }
  .filewrap label {
    min-width: auto;
    display: table-cell;
    width: 1%;
    white-space: nowrap;
    vertical-align: top;
    background-color: #e3e3e3;
    padding: 0px 3px;
    height: 100%;
    border: 1px solid #cdcdcd;
    border-radius: 0;
    border-left: none;
  }
  .filewrap label:last-child {
    border-radius: 0 0.2rem 0.2rem 0;
  }
  .filewrap .hide {
    display: none;
  }
`;

/**
 * 1. Properties tab render 에 대한 props 값
 *  - compId - 현재 component의 고유 ID
 *  - componentInfo - componentDtl에 의 값
 *  - updateProperty - properties 입력값을 update 하기 위한 함수
 *  - getDataModel - Data model을 조회 하는 함수
 *  - event="renderPropertiesPanel"  - 요청 구분
 *
 * 2. Editor에서 component render에 대한 props 값
 *   2-1) Layout Component
 *        - compId - 현재 component의 고유 ID
 *        - componentInfo - drag & drop시 생성된 component object
 *        - style - dragging style이 포함된 style (사용자가 정의한 style은 각 component에서 적절히 적용해야함)
 *        - event="renderEditor" - 요청 구분
 *   2-2) 기타 Component (Form, Mics)
 *        - compId - 현재 component의 고유 ID
 *        - componentInfo - drag & drop시 생성된 component object
 *        - isFocus - 현재 해당 component에 foucs 되어있는지 여부 (선택되어졌는지 여부)
 *        - onClick - component Click시 호출되는 event 함수
 *        - event="renderEditor" - 요청 구분
 */
class UIComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      propertyValue: this.props.componentInfo
        ? this.props.componentInfo.propertyValue || {}
        : {},
      editorAttr: this.props.componentInfo
        ? this.props.componentInfo.editorAttr || {}
        : {},
      entityId: null,
    };
    this.onDeleteEventWorkspace = this.onDeleteEventWorkspace.bind(this);
    this.onChangeEvent = this.onChangeEvent.bind(this);
    this.showEventChangeConfirmMessage =
      this.showEventChangeConfirmMessage.bind(this);
    this.onWorkflowSettingClick = this.onWorkflowSettingClick.bind(this);
  }
  colorPickerPalette = {
    red: "#ff0000",
    blue: "#0000ff",
    green: "#00ff00",
    yellow: "yellow",
    cyan: "cyan",
    lime: "lime",
    gray: "gray",
    orange: "orange",
    purple: "purple",
    black: "black",
    white: "white",
    pink: "pink",
    darkblue: "darkblue",
  };

  textDecoration = [
    { Icon: FaUnderline, value: "underline" },
    { Icon: FaStrikethrough, value: "line-through" },
  ];

  static contextType = AppContext;

  /**
   * determine re-rendering
   * @param {*} nextProps
   * @param {*} nextState
   * @returns
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.event === "renderEditor") {
      return (
        JSON.stringify(this.props.componentInfo) !==
        JSON.stringify(nextProps.componentInfo)
      );
    } else if (nextProps.event === "renderPropertiesPanel") {
      return true;
    }
  }
  /**
   * 해당 Component의 Title
   * @param {*} child
   * @returns
   */
  renderComponentTitle = (title) => {
    return (
      <PropertiesHeader isMobile={this.props.mobile.isMobileEditor}>
        <div>{title}</div>
        <ComponentSavePopupButton
          getComponentCodeFromOutput={this.props.fn.getComponentCodeFromOutput}
          componentInfo={this.props.componentInfo}
        />
      </PropertiesHeader>
    );
  };

  /**
   * on Change
   * @param {Event} event
   */
  onChange = (event) => {
    let value = event.target.value;

    if (
      !StringUtils.isEmpty(value) &&
      event.currentTarget &&
      event.currentTarget.type === "number"
    ) {
      value = Number(value);
    }
    this.onChangePropertyValue(event.target.id, value);
  };

  /**
   * 이벤트 변경이 있을때, 이벤트워크스페이르를 초기화 시키는지 확인하는 메세지
   * 동일한 내용의 컨펌메세지들이 많아서 통합 처리
   * @param {Function} okCb
   * @param {Function} cancelCb
   * @param {String} message
   * @returns
   */
  showEventChangeConfirmMessage = (okCb, cancelCb, message) => {
    return Message.confirm(
      message || (
        <>
          EventBuilder Function is already exists.
          <br />
          Modifying will reset this Event Builder. <br />
          Do you want to Continue?
        </>
      ),
      okCb,
      cancelCb
    );
  };

  /**
   * - 이벤트 워크스페이스 관련해서 온체인지 이벤트 추가
   * - 결과는 onChange랑 같음
   * @param {*} event
   * @param {*} eventCodeSpace eventWorkspace 오브젝트가 보관된곳
   * @return {Object}
   */
  onChangeEvent = (event, eventCodeSpace = this.state.propertyValue) => {
    let value = event.target.value;

    if (
      !StringUtils.isEmpty(value) &&
      event.currentTarget &&
      event.currentTarget.type === "number"
    ) {
      value = Number(value);
    }
    let eventWorkspace = {};
    if (eventCodeSpace) {
      eventWorkspace = eventCodeSpace.eventWorkspace || {};
    }
    if (
      !ObjectUtils.isEmpty(eventWorkspace[event.target.id]) &&
      // 개행문자 치환
      eventCodeSpace[event.target.id].replaceAll("\n", "") !==
        event.target.value.replaceAll("\n", "")
    ) {
      this.showEventChangeConfirmMessage(
        () => {
          const propertyValue = this.onDeleteEventWorkspace(event.target.id);
          const newPropertyValue = produce(propertyValue, (draft) => {
            if (StringUtils.isEmpty(value)) {
              delete draft[event.target.id];
            } else {
              draft[event.target.id] = value;
            }
          });
          this.setState(
            {
              propertyValue: newPropertyValue,
            },
            () => {
              //propertyValue.compId 로 target component node를 찾아 json정보를 update한다.
              this.props.fn.updateProperty(this.state.propertyValue);
            }
          );
        },
        () => {
          let prevText = eventCodeSpace[event.target.id];
          /*▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ 코드 로직 수정할 것 ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼*/
          this.onChangePropertyValue(event.target.id, value);
          setTimeout(() => {
            this.onChangePropertyValue(event.target.id, prevText);
          }, 200);
          /*▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲ 코드 로직 수정할 것 ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲*/
        }
      );
    } else this.onChangePropertyValue(event.target.id, value);
  };

  /**
   * on Change style
   * @param {Event} event
   */
  onChangeStyle = (event) => {
    const newStyle = {
      ...this.state.propertyValue.style,
      [event.target.id]: event.target.value,
    };
    if (StringUtils.isEmpty(event.target.value)) {
      delete newStyle[event.target.id];
    }
    this.onChangePropertyValue("style", newStyle);
  };

  /**
   * on Change style
   * @param {Event} event
   */
  onChangeStyleByName = (event) => {
    const newStyle = {
      ...this.state.propertyValue.style,
      [event.target.name]: event.target.value,
    };
    if (StringUtils.isEmpty(event.target.value)) {
      delete newStyle[event.target.name];
    }
    this.onChangePropertyValue("style", newStyle);
  };

  /**
   * 정렬 변경시
   * - 정렬을 다르게 적용해야하는 component는 해당 class에서 이 함수를 overriding한다.
   * @param {Event} event
   */
  onChangeAlignment = (event) => {
    this.onChangeStyle(event);
  };

  /**
   * component width 변경시
   * - 크기를 다르게 적용해야하는 component는 해당 class에서 이 함수를 overriding한다.
   * - ex) dislay가 flex일 경우 단순히 width만으로 크기를 변경할수 없음
   * @param {Event} event
   */
  onChangeCompWidth = (event) => {
    this.onChangeStyle(event);
  };

  /**
   * on Change style
   * @param {String} key
   * @param {String} value
   */
  onChangePropertyStyle = (styleId, styleValue) => {
    if (!this.state.propertyValue.style) {
      this.onChangePropertyValue("style", { [styleId]: styleValue });
    } else {
      if (
        StringUtils.defaultString(this.state.propertyValue.style[styleId]) !==
        styleValue
      ) {
        const newStyleValues = produce(
          this.state.propertyValue.style,
          (draft) => {
            if (StringUtils.isEmpty(styleValue)) {
              delete draft[styleId];
            } else {
              draft[styleId] = styleValue;
            }
          }
        );
        this.onChangePropertyValue("style", newStyleValues);
      }
    }
  };
  /**
   * onChangePropertyValue
   * @param {String} propId
   * @param {String} propValue
   */
  onChangePropertyValue = (propId, propValue) => {
    /*
    if (
      StringUtils.defaultString(this.state.propertyValue[propId]) !== propValue
    ) {
      const newPropertyValue = produce(this.state.propertyValue, (draft) => {
        if (StringUtils.isEmpty(propValue)) {
          delete draft[propId];
        } else {
          draft[propId] = propValue;
        }
      });
      this.updatePropertyValue(newPropertyValue);
    }
    */
    this.onChangePropertyValues({ [propId]: propValue });
  };

  /**
   * 목록에서 항목이 삭제될때 이벤트 워크스페이스도 초기화 시켜준다.
   * @param {*} propId
   * @param {*} propValue
   */
  onChangePropertyValueWithEventWorkspace = (propId, propValue) => {
    if (
      //컴포넌트 타입
      (StringUtils.includes(this.props.componentInfo.componentType, [
        Enums.ComponentType.COLUMN, //컬림
        Enums.ComponentType.ROW, //ROW
      ]) ||
        StringUtils.includesIgnoreCase(this.state.editorAttr.tagName, [
          "button",
        ])) &&
      ArrayUtils.isArray(propValue)
    ) {
      if (
        this.state.propertyValue.form &&
        this.state.propertyValue.form.length !== propValue.length &&
        this.state.propertyValue.form.length > propValue.length
      ) {
        // 기존 목록이 신규 프로퍼티 보다 길 경우 삭제라고 판단
        const willDeleteInex = [];
        for (const [idx, obj] of this.state.propertyValue.form.entries()) {
          if (
            !propValue.find((p) => JSON.stringify(p) === JSON.stringify(obj))
          ) {
            willDeleteInex.push(idx);
          }
        }
        const newEditorAttr = produce(
          this.props.componentInfo.editorAttr,
          (draft) => {
            const eventWorkspace =
              this.props.componentInfo.editorAttr.eventWorkspace || null;
            if (ArrayUtils.isArray(eventWorkspace)) {
              draft.eventWorkspace = eventWorkspace.filter(
                (e, index) => !willDeleteInex.includes(index)
              );
            }
          }
        );
        this.props.fn.updateEditorAttr(
          this.props.componentInfo.compId,
          newEditorAttr
        );
        this.onChangePropertyValues({ [propId]: propValue });
      } else {
        this.onChangePropertyValues({ [propId]: propValue });
      }
    } else {
      this.onChangePropertyValues({ [propId]: propValue });
    }
  };

  /**
   * 다수의 property value의 변경 사항을 반영
   * @param {Map} propertyValues
   */
  onChangePropertyValues = (propertyValues) => {
    let isChanged = false;
    const newPropertyValue = produce(this.state.propertyValue, (draft) => {
      Object.keys(propertyValues).forEach(function (key) {
        //변경여부를 판단하기위해
        if (StringUtils.defaultString(draft[key]) !== propertyValues[key]) {
          if (StringUtils.isEmpty(propertyValues[key])) {
            if (
              !StringUtils.includesIgnoreCase(key, [
                "dataModelNm",
                "dataModelId",
              ])
            ) {
              //dataModelNm dataModelId 의 경우 빈값이 넘어가는 경우도 있음
              delete draft[key];
            }

            // delete draft[key];
          } else {
            draft[key] = propertyValues[key];
          }
          isChanged = true;
        }
      });
    });

    if (isChanged === true) {
      this.updatePropertyValue(newPropertyValue);
    }
  };

  /**
   * Data binding 변경시 id가 입력되지 않았을경우 id를 dataBiding으로 넣어준다.
   *  -일반적으로 element의 ID는 Databinding과 동일하기 때문에.
   * @param {*} event
   */
  onChangeDataBindingWithId = (event) => {
    let changedPropertyValues = {};

    let targetValue = StringUtils.defaultString(event.target.value);
    if (!StringUtils.isEmpty(targetValue)) {
      changedPropertyValues.id = StringUtils.substringAfter(targetValue, ".");
      this.setElementValue("Input", "id", changedPropertyValues.id);

      if (!StringUtils.isEmpty(event.target.text)) {
        changedPropertyValues.formLabel = StringUtils.substringAfter(
          event.target.text,
          " ",
          true
        );
        this.setElementValue(
          "Input",
          "formLabel",
          changedPropertyValues.formLabel
        );
      }
    }
    changedPropertyValues[event.target.id] = targetValue;

    //입력Form이고 사용자 정의 채번일 경우 채번설정을 활성화 시킨다.
    if (!ObjectUtils.isEmpty(this.getForm())) {
      const formPropertyValue = this.getForm().propertyValue;
      if (
        !ObjectUtils.isEmpty(formPropertyValue) &&
        formPropertyValue.formType === Enums.FormType.SAVE &&
        !ObjectUtils.isEmpty(event.target._data) &&
        (event.target._data.autoNumberingType === Enums.Numbering.STANDARD ||
          event.target._data.autoNumberingType === Enums.Numbering.USER_DEF)
      ) {
        let insertOption = JsonUtils.parseJson(event.target._data.insertOption);
        if (!ObjectUtils.isEmpty(insertOption)) {
          changedPropertyValues["insertOption"] = insertOption;
          changedPropertyValues["autoNumberingType"] =
            event.target._data.autoNumberingType;

          //첫번째 output을 해당 column으로 binding시킨다.
          let outputIndex = ArrayUtils.getIndex(
            insertOption.arguments,
            "mode",
            "OUT"
          );
          if (outputIndex > -1) {
            insertOption.arguments[outputIndex]["dataBinding"] =
              StringUtils.defaultString(targetValue);
          }
        }
      }
    }

    //numbering 관련 data 정리
    if (ObjectUtils.isEmpty(changedPropertyValues.insertOption)) {
      changedPropertyValues["insertOption"] = "";
      changedPropertyValues["autoNumberingType"] = "";
    }

    this.onChangePropertyValues(changedPropertyValues);
  };

  /**
   * Update Property value
   * @param {Map} newPropertyValue
   */
  updatePropertyValue = (newPropertyValue) => {
    this.setState(
      {
        propertyValue: newPropertyValue,
      },
      () => {
        //propertyValue.compId 로 target component node를 찾아 json정보를 update한다.
        this.props.fn.updateProperty(this.state.propertyValue);
      }
    );
  };

  /**
   * workflow 상세
   * @param {Event} e
   */
  onWorkflowDetailClick = (e) => {
    if (e) {
      e.preventDefault();
    }

    Popup.open(
      <WorkflowDetailPopup
        serviceUid={this.state.propertyValue.serviceUid}
        edit={this.props.fn.updateService}
        navigate={this.props.navigate}
      />,
      {
        style: {
          content: {
            width: "500px",
          },
        },
      }
    );
  };

  /**
   * get DataModel
   * @return {String} datamodel
   */
  getDataModel = () => {
    return this.props.fn.getDataModel ? this.props.fn.getDataModel() : "";
  };

  /**
   * get Data Model Entity
   * @return {String} entityId
   */
  getEntity = () => {
    return this.props.fn.getEntity ? this.props.fn.getEntity() : "";
  };

  /**
   * get Data Model Entity
   * @return {String} entityId
   */
  getEntityType = () => {
    return this.props.fn.getEntityType ? this.props.fn.getEntityType() : "";
  };

  /**
   * Is Data Model Entity Type Table ? or Procedure | function
   * @return {String} entityId
   */
  isTableType = () => {
    return this.props.fn.isTableType ? this.props.fn.isTableType() : "";
  };

  getGridInfo = () => {
    return this.props.fn.getGridInfo ? this.props.fn.getGridInfo() : "";
  };
  getGridEntityType = () => {
    return this.props.fn.getGridEntityType
      ? this.props.fn.getGridEntityType()
      : "";
  };

  isGridEntityTableType = () => {
    return this.props.fn.isGridEntityTableType
      ? this.props.fn.isGridEntityTableType()
      : "";
  };

  /**
   * get Parent Form
   * @return {Map} 상위 Parent Form
   */
  getForm = () => {
    return this.props.fn.getForm ? this.props.fn.getForm() : {};
  };

  /**
   * Event 대상 component를 조회한다.
   * @param {*} componentTypes
   * @returns
   */
  getEventTargetNode = (componentTypes, parentFormPriority, excludeTypes) => {
    return this.props.fn.getEventTargetNode
      ? this.props.fn.getEventTargetNode(
          componentTypes,
          parentFormPriority,
          excludeTypes
        )
      : [];
  };

  /**
   * Data Model Entity Field List 조회(Data Binidng)
   * @param {*} entityId
   */
  getDataBindingList = (pEntityId) => {
    const entityId = !StringUtils.isEmpty(pEntityId)
      ? pEntityId
      : this.getEntity();
    if (!StringUtils.isEmpty(entityId)) {
      DataModelService.getDataBindingList({ entityId: entityId }, (res) => {
        return res.data ? res.data : [{}];
      });
    }
    return [{}];
  };

  /**
   * Properties tab panel을 Redering
   * @returns
   */
  renderPropertiesPanel = () => {
    return <React.Fragment>Default Properties</React.Fragment>;
  };
  /**
   * Editor의 component를 Redering
   * @returns
   */
  renderEditor = () => {
    return <React.Fragment>Default Editor</React.Fragment>;
  };

  /**
   * Element에 값을 programmatically 넣었을 경우 그에 대응하는 React Event dispatch 해준다.
   * @param {*} obj
   * @param {*} event
   * @returns
   */
  doInputChangeEvent = (obj, event) => {
    let eventObj = new Event(event, { target: obj, bubbles: true });
    return obj ? obj.dispatchEvent(eventObj) : false;
  };

  /**
   * Element에 값을 programmatically 넣었을 경우 그에 대응하는 React Event dispatch 해준다.
   * @param {*} elementId
   * @param {*} elementValue
   */
  setElementValue = (elementType, elementId, elementValue) => {
    //set element value
    let el = document.getElementById(elementId);
    el.value = elementValue;

    //dispatch react event
    this.doInputChangeEvent(el, elementType);
  };
  /**
   * Set Element값 & React event dispatch & update property values
   *  + call onChangePropertyValue
   * @param {String} elementType
   * @param {String} elementId
   * @param {String} elementValue
   */
  setInputValue = (elementType, elementId, elementValue) => {
    this.setElementValue(elementType, elementId, elementValue);

    //update property values
    this.onChangePropertyValue(elementId, elementValue);
  };

  /**
   * DataModel 선택 popup
   */
  openDataModelPopup = () => {
    const options = {
      style: {
        content: {
          width: "50%", //popup의 크기를 50% (default 60%)
          minWidth: "1000px",
        },
      },
    };
    Popup.open(
      <DataModelPopup
        workspace={this.context.workspace}
        programId="DataModelPopup"
        title="Data Model Selection"
        defaultValue={StringUtils.defaultString(
          this.state.propertyValue.dataModelId
        )}
        callbackFnc={(data) => {
          if (data) {
            this.setElementValue("input", "dataModelNm", data.dataModelNm);
            this.onChangePropertyValues({
              dataModelId: String(data.dataModelId).toString(),
              dataModelNm: data.dataModelNm,
            });
          }
        }}
      />,
      options
    );
  };

  /**
   * open Validation rule popup
   */
  openValidationRules = () => {
    /**
     * 발리데이션 저장
     * @param {*} valRules
     */
    const onSaveValidationRules = (valRules) => {
      this.onChangePropertyValue("validationRules", valRules);
    };

    //팝업창 열기
    const { componentValRule } = this.props.componentInfo;
    const { type = "text", validationRules } = this.state.propertyValue;
    let valRule = componentValRule.filter((cv) =>
      StringUtils.equalsIgnoreCase(cv.componentDtlType, type)
    );
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "45%", //popup의 크기를 50% (default 60%)
        },
      },
    };
    Popup.open(
      <ValidationPopup
        valRuleList={valRule}
        appliedRules={validationRules}
        onSave={onSaveValidationRules}
      />,
      options
    );
  };

  /**
   * Javascript 입력 popup을 open 한다.
   * @param {*} e
   * @param {*} title
   * @param {*} popupProps
   */
  openJavascriptPopup = (e, title, popupProps) => {
    const options = {
      keyDownEvent: false,
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          minHeight: "200px",
        },
      },
    };
    const id = e.currentTarget.formTarget;
    Popup.open(
      <ExtendPopup
        title={title}
        fieldType="javascript"
        defaultValue={this.state.propertyValue[id]}
        callbackFnc={(popVal) => {
          this.setElementValue("input", id, popVal);
          this.onChangePropertyValues({
            [id]: popVal,
          });
        }}
        {...popupProps}
      />,
      options
    );
  };
  /**
   * Editor의 공통 class + 각 component별 고유 editor Class + properties에서 입력된 class
   * 주의 : rederEditor에서 호출될때는 constructor는 최초 1번만 호출되기 때문에 state값은 최초의값이다.
   * 따라서 re-redering시 props의 값과 state값이 다를수 있으니 props를 사용할것
   * @returns className
   */
  getEditorClassName = () => {
    let className = "editor-base draggable";
    if (
      !ObjectUtils.isEmpty(this.props.componentInfo.editorAttr) &&
      !StringUtils.isEmpty(this.props.componentInfo.editorAttr.className)
    )
      className += " " + this.props.componentInfo.editorAttr.className;

    if (
      !ObjectUtils.isEmpty(this.props.componentInfo.propertyValue) &&
      !StringUtils.isEmpty(this.props.componentInfo.propertyValue.className)
    )
      className += " " + this.state.propertyValue.className;

    return className;
  };

  /**
   * readonly update
   * @param {*} boolean
   */
  btnIsReadOnlyChange = (boolean) => {
    this.onChangePropertyValue("isReadonly", boolean);
  };

  /**
   * setComponentOperator
   */
  setComponentOperator = (type) => {
    if (this.props.event === "renderPropertiesPanel") {
      const formPropertyValue = this.getForm().propertyValue;
      if (
        !ObjectUtils.isEmpty(formPropertyValue) &&
        formPropertyValue.formType === Enums.FormType.SEARCH
      ) {
        const componentOperator = this.filterSearchOperator(
          this.props.componentInfo.componentOperator,
          type
        );
        this.setState({ componentOperator: componentOperator });
      }
    }
  };

  /**
   * 각 Component는 componentDtlType로 searchOperator를 filter해야한다.
   * ex) 특히 input의 경우 type이 text/number에 따라 다른 operator를 보여줘야한다.
   * @param {Array} componentOperators
   */
  filterSearchOperator = (componentOperators, type) => {
    return [...componentOperators];
  };

  /**
   * 검색 Form의 조건 element일경우 검색조건 operator property를 redering한다.
   */
  renderSearchOperator = () => {
    if (!ArrayUtils.isEmpty(this.state.componentOperator)) {
      return (
        <React.Fragment>
          <PropertyLable>Search Condition</PropertyLable>
          <PropertyValue>
            <USelectbox
              id="searchOperator"
              onChange={this.onChange}
              defaultValue={StringUtils.defaultString(
                this.state.propertyValue.searchOperator,
                "EQ"
              )}
              items={this.state.componentOperator}
              options={{
                matchCd: "componentOperatorCd",
                matchNm: "componentOperatorNm",
              }}
            />
          </PropertyValue>
        </React.Fragment>
      );
    } else {
      return "";
    }
  };

  /**
   * code mirror 창을 띄운다.
   * @param {*} e
   * @param {*} title
   * @param {*} fieldType
   * @param {*} jsonValidation
   * @param {*} callbackFnc
   */
  openExtendPopup = (e, title, fieldType, jsonValidation, callbackFnc) => {
    const id = e.currentTarget.formTarget;

    const options = {
      keyDownEvent: false,
      style: {
        content: {
          minHeight: "200px",
        },
      },
    };
    Popup.open(
      <ExtendPopup
        title={title}
        fieldType={fieldType}
        jsonValidation={jsonValidation}
        defaultValue={this.state.propertyValue[id]}
        callbackFnc={(val) => {
          this.setInputValue("input", id, val);
          if (callbackFnc) {
            callbackFnc.call(this, val);
          }
        }}
      />,
      options
    );
  };

  /**
   * Data Model Entity Field List 조회(Data Binidng)
   * @param {*} entityId
   */
  selectDataBindingList = (entityId, callbackFnc) => {
    if (!StringUtils.isEmpty(entityId)) {
      DataModelService.getDataBindingList({ entityId: entityId }, (res) => {
        callbackFnc.call(
          this,
          res.data ? res.data : [{ id: "none", text: "[none] None Data" }]
        );
      });
    }
  };

  /**
   * Editor Attr의 Workspace 삭제 로직
   * @param {*} eventName 이벤트 키값
   * @param {Number} index 이벤트 인덱스
   * @param {Object} props 기타 항목
   */
  onDeleteEventWorkspace = (eventName, index, props) => {
    let eventWorkspacePath =
      props?.eventWorkspacePath || this.state.propertyValue;
    let eventWorkspace = {};
    if (eventWorkspacePath?.eventWorkspace) {
      eventWorkspace = eventWorkspacePath.eventWorkspace || {};
    }
    if (ObjectUtils.isEmpty(eventWorkspace)) return {};
    const propertValueWithNewEventWorkspace = produce(
      eventWorkspacePath,
      (draft) => {
        if (!ArrayUtils.isEmpty(draft.eventWorkspace)) {
          if (index) {
            //이벤트 명이 배열인경우
            if (ArrayUtils.isArray(eventName)) {
              for (const name of eventName) {
                if (
                  draft.eventWorkspace[index] &&
                  draft.eventWorkspace[index][name]
                ) {
                  delete draft.eventWorkspace[index][name];
                }
              }
            } else {
              //이벤트 명이 배열이 아닌 경우 (스트링)
              if (
                draft.eventWorkspace[index] &&
                draft.eventWorkspace[index][eventName]
              ) {
                delete draft.eventWorkspace[index][eventName];
              }
            }
            // 이벤드 워크스페이스에 함수가 없다면 배열에서 해당 오브젝트 삭제
            if (ObjectUtils.isEmpty(draft.eventWorkspace[index])) {
              // draft.eventWorkspace.splice(index, 1);
              draft.eventWorkspace[index] = {};
            }
          } else {
            debugger;
          }
        } else {
          //인덱스가 없는 경우 Map 형태로 되어있음
          if (draft.eventWorkspace[eventName]) {
            delete draft.eventWorkspace[eventName];
          }

          // 이벤드 워크스페이스에 함수가 없다면 해당 오브젝트 삭제
          if (ObjectUtils.isEmpty(draft.eventWorkspace))
            delete draft.eventWorkspace;
        }
      }
    );
    return propertValueWithNewEventWorkspace;
  };

  /**
   * workflow 선택
   * @param {Event} e
   */
  onWorkflowSettingClick = (e) => {
    if (e) {
      e.preventDefault();
    }

    const callBack = (data) => {
      this.onChangePropertyValues(data);
      Popup.close();
    };
    const remove = () => {
      if (this.state.propertyValue.serviceUid) {
        this.onChangePropertyValues({
          serviceId: "",
          serviceName: "",
          serviceUid: "",
        });
        Popup.close();
      }
    };

    Popup.open(
      <WorkflowListPopup
        workspace={this.context.workspace.Info}
        callbackFnc={callBack}
        remove={this.state.propertyValue.serviceUid && remove}
      />,
      {
        style: {
          content: {
            width: "55%",
          },
        },
      }
    );
  };
  /**
   * 이벤트 메서드 기입하는 컴포넌트 생성자
   * @param {String} eventId 이벤트 타입
   * @param {String} popTitle 팝업 타이틀
   * @returns
   */
  renderEventTextArea = (eventId, popTitle, props = {}) => {
    let eventWorkspacePath =
      props.eventWorkspacePath || this.state.propertyValue;
    let eventWorkspace = {};
    if (eventWorkspacePath?.eventWorkspace) {
      eventWorkspace = eventWorkspacePath.eventWorkspace || {};
    }

    const getEventInfo = () => {
      return {
        eventWorkspace: eventWorkspace[eventId] || {},
        compId: this.props.componentInfo.compId,
        eventCd: props.eventCd,
        eventType:
          Enums.EventHandlerEventType[eventId] ||
          props.eventType ||
          Enums.EventHandlerEventType.USR_EVENT_FUNCTION,
        builderEventType: eventId,
        originalOutput: this.state.propertyValue[eventId] || "",
        programType: props.programType
          ? props.programType
          : this.props.output.page.propertyValue?.programType || "M",
        targetType: props.targetType || "all",
      };
    };
    return (
      <UCodeMirrorButton
        popTitle={popTitle}
        propId={eventId}
        fieldType="javascript"
        eventWorkspace={eventWorkspace[eventId] || {}}
        getEventInfo={getEventInfo}
        onClickEventBuilder={() => {
          this.props.fn.onClickEventBuilder(getEventInfo());
        }}
        defaultValue={StringUtils.defaultString(
          this.state.propertyValue[eventId]
        )}
        onBlur={this.onChangeEvent}
        {...props}
      />
    );
  };

  /**
   * Propoties panel의 Label 설정을 display
   * @param {String} componentType
   * @param {Int} index
   * @returns
   */
  renderLabelPanel = (componentType, formLabel, index, markRequired) => {
    return (
      <Accordion.Item eventKey={index}>
        <Accordion.Header>Label Settings</Accordion.Header>
        <Accordion.Body>
          <PropertyLable>Label</PropertyLable>
          <PropertyValue>
            <input
              type="text"
              id="formLabel"
              defaultValue={StringUtils.defaultString(
                this.state.propertyValue.formLabel
              )}
              className="form-control form-control-sm"
              onBlur={this.onChange}
              placeholder={formLabel}
            />
          </PropertyValue>
          <PropertyLable>Layout Type</PropertyLable>
          <PropertyValue>
            <USelectbox
              type="common"
              id="layoutType"
              mstCd="Z0004"
              onChange={this.onChange}
              defaultValue={StringUtils.defaultString(
                this.state.propertyValue.layoutType
              )}
            />
          </PropertyValue>
          <PropertyLable>Width</PropertyLable>
          <PropertyValue>
            <InputGroup size="sm">
              {this.state.propertyValue.isLabelWidthCustom === true ? (
                <Fragment>
                  <input
                    type="number"
                    id="customLabelWidth"
                    defaultValue={StringUtils.defaultString(
                      this.state.propertyValue.customLabelWidth
                    )}
                    className="form-control form-control-sm"
                    onBlur={this.onChange}
                  />
                  <InputGroup.Text>%</InputGroup.Text>
                </Fragment>
              ) : (
                <USelectbox
                  type="common"
                  id="labelWidth"
                  mstCd="Z0015"
                  onChange={this.onChange}
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.labelWidth,
                    Enums.Style.LABEL_DEFAULT
                  )}
                />
              )}
              <BootstrapSwitchButton
                id="dataLimit"
                checked={this.state.propertyValue.isLabelWidthCustom}
                size="sm"
                width={100}
                onstyle="primary"
                offstyle="dark"
                onlabel="Custom input"
                offlabel="Standard"
                onChange={(checked) => {
                  let propertyValues = { isLabelWidthCustom: checked };
                  if (checked) {
                    propertyValues.labelWidth = "";
                  } else {
                    propertyValues.customLabelWidth = "";
                  }

                  this.onChangePropertyValues(propertyValues);
                }}
              />
            </InputGroup>
          </PropertyValue>
          {markRequired === true ? (
            <React.Fragment>
              <PropertyLable>Required</PropertyLable>
              <PropertyValue className="w-25p">
                <BootstrapSwitchButton
                  id="isRequired"
                  checked={StringUtils.defaultString(
                    this.state.propertyValue.isRequired,
                    false
                  )}
                  size="sm"
                  onstyle="primary"
                  offstyle="dark"
                  onlabel="Yes"
                  offlabel="No"
                  onChange={(boolean) =>
                    this.onChange({
                      target: {
                        id: "isRequired",
                        value: boolean,
                      },
                    })
                  }
                />
              </PropertyValue>
            </React.Fragment>
          ) : (
            ""
          )}
        </Accordion.Body>
      </Accordion.Item>
    );
  };

  /**
   * Propoties panel의 Validation을 display
   * @param {String} componentType
   * @param {Int} index
   * @returns
   */
  renderValidationPanel(componentType, index) {
    return (
      <CommonStyled>
        <Accordion.Item eventKey={index}>
          <Accordion.Header>Validation</Accordion.Header>
          <Accordion.Body>
            <PropertyLable>Required</PropertyLable>
            <PropertyValue className="w-25p">
              <BootstrapSwitchButton
                id="isRequired"
                checked={StringUtils.defaultString(
                  this.state.propertyValue.isRequired,
                  false
                )}
                size="sm"
                onstyle="primary"
                offstyle="dark"
                onlabel="Yes"
                offlabel="No"
                onChange={(boolean) =>
                  this.onChange({
                    target: {
                      id: "isRequired",
                      value: boolean,
                    },
                  })
                }
              />
            </PropertyValue>
            <PropertyLable className="pl-5">Length</PropertyLable>
            <PropertyValue className="w-25p">
              <input
                type="number"
                id="length"
                defaultValue={StringUtils.defaultString(
                  this.state.propertyValue.length
                )}
                className="form-control form-control-sm"
                onBlur={this.onChange}
              />
            </PropertyValue>
            <PropertyLable>Max length</PropertyLable>
            <PropertyValue className="w-25p">
              <input
                type="number"
                id="maxlength"
                defaultValue={StringUtils.defaultString(
                  this.state.propertyValue.maxlength
                )}
                className="form-control form-control-sm"
                onBlur={this.onChange}
              />
            </PropertyValue>
            <PropertyLable className="pl-5">Min length</PropertyLable>
            <PropertyValue className="w-25p">
              <input
                type="number"
                id="minlength"
                defaultValue={StringUtils.defaultString(
                  this.state.propertyValue.minlength
                )}
                className="form-control form-control-sm"
                onBlur={this.onChange}
              />
            </PropertyValue>
            <PropertyLable>Others</PropertyLable>
            <PropertyValue>
              <UInputPopup
                id="validationRules"
                textReadonly
                defaultValue={StringUtils.defaultString(
                  JSON.stringify(this.state.propertyValue.validationRules)
                )}
                onClick={this.openValidationRules}
                onBlur={this.onChange}
              />
            </PropertyValue>
          </Accordion.Body>
        </Accordion.Item>
      </CommonStyled>
    );
  }

  /**
   * Propoties panel의 Style 설정을 display
   * @param {String} componentType
   * @param {Int} index
   * @returns
   */
  renderStylePanel(componentType, index) {
    return (
      <CommonStyled>
        <Accordion.Item eventKey={index}>
          <Accordion.Header>Style</Accordion.Header>
          <Accordion.Body>
            <PropertyLable>Display</PropertyLable>
            <PropertyValue>
              <BootstrapSwitchButton
                id="isDisplay"
                checked={StringUtils.defaultString(
                  this.state.propertyValue.isDisplay,
                  true
                )}
                size="sm"
                onstyle="primary"
                offstyle="dark"
                onlabel="Yes"
                offlabel="No"
                onChange={(boolean) =>
                  this.onChange({
                    target: {
                      id: "isDisplay",
                      value: boolean,
                    },
                  })
                }
              />
            </PropertyValue>
            <PropertyLable>Class</PropertyLable>
            <PropertyValue>
              <input
                type="text"
                id="className"
                defaultValue={StringUtils.defaultString(
                  this.state.propertyValue.className
                )}
                className="form-control form-control-sm"
                onBlur={this.onChange}
              />
            </PropertyValue>
            <PropertyLable>Font Size</PropertyLable>
            <PropertyValue>
              <input
                type="text"
                id="fontSize"
                defaultValue={JsonUtils.defaultString(
                  this.state.propertyValue.style,
                  "fontSize"
                )}
                className="form-control form-control-sm"
                onBlur={this.onChangeStyle}
              />
            </PropertyValue>
            <PropertyLable>Font Weight</PropertyLable>
            <PropertyValue>
              <USelectbox
                id="fontWeight"
                onChange={this.onChangeStyle}
                defaultValue={JsonUtils.defaultString(
                  this.state.propertyValue.style,
                  "fontWeight"
                )}
                items={[
                  { id: "unset", text: "None" },
                  { id: "bold", text: "Bold" },
                  { id: "100", text: "100" },
                  { id: "200", text: "200" },
                  { id: "300", text: "300" },
                  { id: "400", text: "400" },
                  { id: "500", text: "500" },
                  { id: "600", text: "600" },
                  { id: "700", text: "700" },
                  { id: "800", text: "800" },
                  { id: "900", text: "900" },
                ]}
                options={{ matchCd: "id", matchNm: "text" }}
              />
            </PropertyValue>
            <PropertyLable>Font Style</PropertyLable>
            <PropertyValue>
              <ButtonGroup>
                <ToggleButton
                  className="mb-2"
                  id="fontStyle"
                  type="checkbox"
                  variant="outline-primary"
                  checked={
                    JsonUtils.defaultString(
                      this.state.propertyValue.style,
                      "fontStyle"
                    ) === "italic"
                  }
                  value="italic"
                  onChange={(e) => this.onChangeStyle(e)}
                >
                  <FaItalic />
                </ToggleButton>
                {this.textDecoration.map((textDecoration, idx) => {
                  let Icon = textDecoration.Icon;
                  return (
                    <ToggleButton
                      key={idx}
                      id={`textDecoration-${idx}`}
                      type="radio"
                      variant="outline-primary"
                      name="textDecoration"
                      className="mb-2"
                      value={textDecoration.value}
                      checked={
                        JsonUtils.defaultString(
                          this.state.propertyValue.style,
                          "textDecoration"
                        ) === textDecoration.value
                      }
                      onChange={(e) => this.onChangeStyleByName(e)}
                    >
                      <Icon />
                    </ToggleButton>
                  );
                })}
              </ButtonGroup>
            </PropertyValue>
            <PropertyLable>Font Color</PropertyLable>
            <PropertyValue>
              <ColorPicker
                id="color"
                defaultValue={JsonUtils.defaultString(
                  this.state.propertyValue.style,
                  "color",
                  ""
                )}
                onBlur={this.onChangeStyle}
              />
            </PropertyValue>
            <PropertyLable>Background</PropertyLable>
            <PropertyValue>
              <USelectbox
                type="static"
                id="backgroundType"
                defaultValue={JsonUtils.defaultString(
                  this.state.propertyValue.style,
                  "backgroundType",
                  ""
                )}
                items={[
                  { id: "color", text: "Color" },
                  { id: "image", text: "Image" },
                ]}
                options={{ matchId: "id", matchNm: "text" }}
                onChange={this.onChangeStyle}
              />
            </PropertyValue>
            {StringUtils.isEmpty(this.state.propertyValue.style) ||
            this.state.propertyValue.style.backgroundType === "color" ? (
              <React.Fragment>
                <PropertyLable>Color</PropertyLable>
                <PropertyValue>
                  <ColorPicker
                    id="backgroundColor"
                    defaultValue={JsonUtils.defaultString(
                      this.state.propertyValue.style,
                      "backgroundColor",
                      ""
                    )}
                    onBlur={(e) => {
                      console.log(e);
                      this.onChangeStyle(e);
                    }}
                  />
                </PropertyValue>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <PropertyLable>Image</PropertyLable>
                <PropertyValue>
                  <div className="filewrap">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="fileName"
                      name="fileName"
                      defaultValue={StringUtils.defaultString(
                        this.state.propertyValue.fileName
                      )}
                      disabled={true}
                    />
                    <label htmlFor="backgroundImage" className="btn upload-btn">
                      <MdOutlineImageSearch />
                    </label>
                    <label htmlFor="delteImage" className="btn">
                      <FiDelete />
                    </label>
                    <input
                      type="file"
                      id="backgroundImage"
                      name="backgroundImage"
                      className="upload-input hide"
                      onChange={(e) => {
                        const reader = new FileReader();
                        const files = e.target.files[0];
                        reader.readAsDataURL(files);
                        this.setInputValue("input", "fileName", files.name);
                        return new Promise((resolve) => {
                          reader.onload = () => {
                            this.onChangeStyle({
                              target: {
                                id: e.target.id,
                                value: "url(" + reader.result + ")",
                              },
                            });
                            resolve();
                          };
                        });
                      }}
                    />
                    <button
                      id="delteImage"
                      className="deleteFile hide"
                      onClick={(e) => {
                        this.setElementValue("input", "fileName", "");

                        //update property values
                        this.onChangePropertyValues({
                          fileName: "",
                          style: {
                            ...this.state.propertyValue.style,
                            backgroundImage: "",
                          },
                        });
                      }}
                    />
                  </div>
                </PropertyValue>
              </React.Fragment>
            )}
            <PropertyLable className="w-33p">Width</PropertyLable>
            <PropertyLable className="pl-5 w-33p">Max Width</PropertyLable>
            <PropertyLable className="pl-5 w-33p">Min Width</PropertyLable>
            <PropertyValue className="pl-0 w-33p">
              <input
                type="text"
                id="width"
                defaultValue={JsonUtils.defaultString(
                  this.state.propertyValue.style,
                  "width"
                )}
                className="form-control form-control-sm"
                onBlur={this.onChangeCompWidth}
              />
            </PropertyValue>
            <PropertyValue className="w-33p">
              <input
                type="text"
                id="maxWidth"
                defaultValue={JsonUtils.defaultString(
                  this.state.propertyValue.style,
                  "maxWidth"
                )}
                className="form-control form-control-sm"
                onBlur={this.onChangeStyle}
              />
            </PropertyValue>
            <PropertyValue className="w-33p">
              <input
                type="text"
                id="minWidth"
                defaultValue={JsonUtils.defaultString(
                  this.state.propertyValue.style,
                  "minWidth"
                )}
                className="form-control form-control-sm"
                onBlur={this.onChangeStyle}
              />
            </PropertyValue>
            <PropertyLable className="w-33p">Height</PropertyLable>
            <PropertyLable className="pl-5 w-33p">Max Height</PropertyLable>
            <PropertyLable className="pl-5 w-33p">Min Height</PropertyLable>
            <PropertyValue className="pl-0 w-33p">
              <input
                type="text"
                id="height"
                defaultValue={JsonUtils.defaultString(
                  this.state.propertyValue.style,
                  "height"
                )}
                className="form-control form-control-sm"
                onBlur={this.onChangeStyle}
              />
            </PropertyValue>
            <PropertyValue className="w-33p">
              <input
                type="text"
                id="maxHeight"
                defaultValue={JsonUtils.defaultString(
                  this.state.propertyValue.style,
                  "maxHeight"
                )}
                className="form-control form-control-sm"
                onBlur={this.onChangeStyle}
              />
            </PropertyValue>
            <PropertyValue className="w-33p">
              <input
                type="text"
                id="minHeight"
                defaultValue={JsonUtils.defaultString(
                  this.state.propertyValue.style,
                  "minHeight"
                )}
                className="form-control form-control-sm"
                onBlur={this.onChangeStyle}
              />
            </PropertyValue>

            {/* Margin */}
            <PropertyLable className="w-20p"></PropertyLable>
            <PropertyLable className="pl-5 w-20p">Top</PropertyLable>
            <PropertyLable className="pl-5 w-20p">Right</PropertyLable>
            <PropertyLable className="pl-5 w-20p">Bottom</PropertyLable>
            <PropertyLable className="pl-5 w-20p">Left</PropertyLable>
            <PropertyLable className="pl-5 w-20p">Margin</PropertyLable>
            <PropertyValue className="pl-0 w-20p">
              <input
                type="text"
                id="marginTop"
                defaultValue={JsonUtils.defaultString(
                  this.state.propertyValue.style,
                  "marginTop"
                )}
                className="form-control form-control-sm"
                onBlur={this.onChangeStyle}
              />
            </PropertyValue>
            <PropertyValue className="w-20p">
              <input
                type="text"
                id="marginRight"
                defaultValue={JsonUtils.defaultString(
                  this.state.propertyValue.style,
                  "marginRight"
                )}
                className="form-control form-control-sm"
                onBlur={this.onChangeStyle}
              />
            </PropertyValue>
            <PropertyValue className="w-20p">
              <input
                type="text"
                id="marginBottom"
                defaultValue={JsonUtils.defaultString(
                  this.state.propertyValue.style,
                  "marginBottom"
                )}
                className="form-control form-control-sm"
                onBlur={this.onChangeStyle}
              />
            </PropertyValue>
            <PropertyValue className="w-20p">
              <input
                type="text"
                id="marginLeft"
                defaultValue={JsonUtils.defaultString(
                  this.state.propertyValue.style,
                  "marginLeft"
                )}
                className="form-control form-control-sm"
                onBlur={this.onChangeStyle}
              />
            </PropertyValue>

            {/* Padding */}
            <PropertyLable className="w-20p">Padding</PropertyLable>
            <PropertyValue className="pl-0 w-20p">
              <input
                type="text"
                id="paddingTop"
                defaultValue={JsonUtils.defaultString(
                  this.state.propertyValue.style,
                  "paddingTop"
                )}
                className="form-control form-control-sm"
                onBlur={this.onChangeStyle}
              />
            </PropertyValue>
            <PropertyValue className="w-20p">
              <input
                type="text"
                id="paddingRight"
                defaultValue={JsonUtils.defaultString(
                  this.state.propertyValue.style,
                  "paddingRight"
                )}
                className="form-control form-control-sm"
                onBlur={this.onChangeStyle}
              />
            </PropertyValue>
            <PropertyValue className="w-20p">
              <input
                type="text"
                id="paddingBottom"
                defaultValue={JsonUtils.defaultString(
                  this.state.propertyValue.style,
                  "paddingBottom"
                )}
                className="form-control form-control-sm"
                onBlur={this.onChangeStyle}
              />
            </PropertyValue>
            <PropertyValue className="w-20p">
              <input
                type="text"
                id="paddingLeft"
                defaultValue={JsonUtils.defaultString(
                  this.state.propertyValue.style,
                  "paddingLeft"
                )}
                className="form-control form-control-sm"
                onBlur={this.onChangeStyle}
              />
            </PropertyValue>

            {/* Alignment */}
            <PropertyLable className="w-20p">Alignment</PropertyLable>
            <PropertyValue className="pl-0 w-80p">
              <ToggleButtonGroup
                id="justifyContent"
                name="justifyContent"
                defaultValue={JsonUtils.defaultString(
                  this.state.propertyValue.style,
                  "justifyContent",
                  "flex-start"
                )}
                size="lg"
                onChange={(value) =>
                  this.onChangeAlignment({
                    target: {
                      id: "justifyContent",
                      value: value,
                    },
                  })
                }
                className="w-full"
              >
                <ToggleButton
                  className="btn-align w-25p"
                  id="alignLeft"
                  value={"left"}
                >
                  <FaAlignLeft />
                </ToggleButton>
                <ToggleButton
                  className="btn-align w-25p"
                  id="alignCenter"
                  value={"center"}
                >
                  <FaAlignCenter />
                </ToggleButton>
                <ToggleButton
                  className="btn-align w-25p"
                  id="alignRight"
                  value={"right"}
                >
                  <FaAlignRight />
                </ToggleButton>
                <ToggleButton
                  className="btn-align w-25p"
                  id="alignJustify"
                  value={"unset"}
                >
                  <FaAlignJustify />
                </ToggleButton>
              </ToggleButtonGroup>
            </PropertyValue>
          </Accordion.Body>
        </Accordion.Item>
      </CommonStyled>
    );
  }

  /**
   * Propoties panel의 Label 설정을 display
   * @param {String} componentType
   * @param {Int} index
   * @returns
   */
  renderClickEvent = (componentType, index) => {
    let targetList = [];

    if (this.state.propertyValue.eventType === "BTN_SCH") {
      targetList = this.getEventTargetNode(
        [
          Enums.ComponentType.FORM,
          Enums.ComponentType.GRID,
          Enums.ComponentType.TREE,
        ],
        false,
        [Enums.FormType.SEARCH]
      );
    } else {
      targetList = this.getEventTargetNode(
        [
          Enums.ComponentType.FORM,
          Enums.ComponentType.GRID,
          Enums.ComponentType.TREE,
        ],
        true,
        [Enums.FormType.SEARCH]
      );
    }

    if (ObjectUtils.isEmpty(this.state.componentEvent)) {
      this.setState({
        componentEvent: this.props.componentInfo.componentEvent,
      });
      // let params = {
      //   componentDtlId: "73",
      //   applyGridYn: "Y",
      // };
      // Api.post("/common/eventCombo", params, (res) => {
      //   if (res.data) {
      //     this.setState({ componentEvent: res.data });
      //   }
      // });
    }

    const openPopup = (e, title, msgId) => {
      const eventType = this.state.propertyValue.eventType;
      if (StringUtils.isEmpty(eventType)) {
        return Message.alert(
          "Please select the 'Event type' first.",
          Enums.MessageType.ERROR
        );
      }

      const targetId = e.currentTarget.formTarget || e.currentTarget.id;

      const id = StringUtils.substringBefore(targetId, "_");
      const index = StringUtils.substringAfter(targetId, "_");
      if (!this.state.propertyValue.form)
        return Message.alert("No target has been set.", Enums.MessageType.WARN);
      let items = this.state.propertyValue.form.slice();
      let item = items[index];

      let eventInfo = this.state.componentEvent.filter(
        (event, index) => event.eventHandleCd === eventType
      );

      eventInfo = ObjectUtils.isEmpty(eventInfo) ? {} : eventInfo[0];

      const options = {
        effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
        style: {
          content: {
            width: "40%",
          },
        },
      };

      //TAB, STEP은 위치 다름
      // let eventWorkspacePath = this.state.propertyValue.form;

      //editorAttr에 등록된 이벤트 워크스페이스가 있으면 설정
      let eventWorkspace = {};
      if (item.eventWorkspace) {
        eventWorkspace = item.eventWorkspace;
      }

      const getEventInfo = (evtType) => {
        return {
          eventWorkspace: eventWorkspace[evtType || id],
          eventIndex: index,
          compId: this.props.componentInfo.compId,
          eventCd: eventInfo.eventCd,
          eventType:
            Enums.EventHandlerEventType[evtType] ||
            Enums.EventHandlerEventType.USR_EVENT_FUNCTION,
          builderEventType: evtType || id,
          targetType: item.type,
          originalOutput: this.state.propertyValue[evtType] || "",
          programType: this.props.output.page.propertyValue?.programType || "M",
        };
      };
      const eventBuilderProps = {
        getEventInfo,
        onClickEventBuilder: (evtType) => {
          this.props.fn.onClickEventBuilder(getEventInfo(evtType));
        },
        callbackFnc: (backItems) => {
          let isEventEdited = false;
          for (const backItemKey in backItems) {
            isEventEdited =
              item.eventWorkspace[backItemKey] &&
              item[backItemKey] !== backItems[backItemKey];
            if (isEventEdited) break;
          }
          let data = { ...item, ...backItems };
          if (isEventEdited) {
            this.showEventChangeConfirmMessage(() => {
              data = produce(data, (draft) => {
                for (const backItemKey in backItems) {
                  if (item[backItemKey] !== backItems[backItemKey]) {
                    delete draft.eventWorkspace[backItemKey];
                  }
                }
              });
              JsonUtils.cleanup(data); //"" 공백값 제거
              items[index] = data;
              this.onChangePropertyValue("form", items);
            });
          } else {
            JsonUtils.cleanup(data); //"" 공백값 제거
            items[index] = data;
            this.onChangePropertyValue("form", items);
          }
        },
      };

      //Event 유형여 "open popup" 경우 팝업 설정 popup을 띄운다
      if (eventType === "BTN_OPEN_POPUP") {
        const sizeList = this.context.code.getCodeList("Z0021");
        const positionList = this.context.code.getCodeList("Z0013");

        Popup.open(
          <PopupHandleConfigPopup
            workspace={this.context.workspace.Info}
            title="Open Popup Setting"
            id={id}
            fieldType="json"
            item={item}
            data={{
              size: sizeList,
              position: positionList,
            }}
            entityId={this.getEntity()}
            // callbackFnc={(backItems) => {
            //   let data = { ...item, ...backItems };
            //   JsonUtils.cleanup(data); //"" 공백값 제거
            //   items[index] = data;
            //   this.onChangePropertyValue("form", items);
            // }}
            {...eventBuilderProps}
          />,
          options
        );
      } else {
        Popup.open(
          <MesFunTabPopup
            title={title}
            fieldType="json"
            item={item}
            eventInfo={eventInfo}
            entityId={this.getEntity()}
            // callbackFnc={(backItems) => {
            //   let data = { ...item, ...backItems };
            //   JsonUtils.cleanup(data); //"" 공백값 제거
            //   items[index] = data;

            //   //update property values
            //   this.onChangePropertyValue("form", items);
            // }}
            {...eventBuilderProps}
          />,
          { ...options, keyDownEvent: false }
        );
      }
    };

    return (
      <CommonStyled>
        <Accordion.Item
          eventKey={index}
          className={`event-${this.props.componentInfo.compId}`}
        >
          <Accordion.Header>Click Event</Accordion.Header>
          <Accordion.Body>
            <PropertyLable requried="true">Event Type</PropertyLable>
            <PropertyValue>
              <USelectbox
                type="static"
                id="eventType"
                defaultValue={StringUtils.defaultString(
                  this.state.propertyValue.eventType
                )}
                items={this.state.componentEvent}
                options={{
                  matchId: "eventHandleCd",
                  matchNm: "eventHandleNm",
                  isChoose: true,
                }}
                extIds={["BTN_SAVE", "BTN_USR_TNX", "BTN_CLOSE_POPUP"]}
                onChange={(e, item, event) => {
                  this.onChange(e);
                }}
              />
            </PropertyValue>
            {this.state.propertyValue.eventType === "BTN_USR_EVENT" && (
              <>
                <PropertyLable>Event Settings</PropertyLable>
                <PropertyValue>
                  {this.renderEventTextArea(
                    "usrEventFn",
                    "Custom Event Details"
                  )}
                </PropertyValue>
              </>
            )}

            <UElementList
              isDisplay={this.state.propertyValue.eventType !== "BTN_USR_EVENT"}
              isMobileEditor={this.props.mobile.isMobileEditor}
              bodyStyle={{ maxHeight: "250px" }}
              label="Target Settings"
              id="form"
              data={this.state.propertyValue.form}
              onBlur={
                this.onChangePropertyValueWithEventWorkspace
                // this.onChangePropertyValue
              }
              cols={[
                {
                  label: "Target",
                  type: "select",
                  id: "target",
                  className: "w-87p",
                  onChange: (item, value, comboItem) => {
                    item.type = comboItem.type;
                    return item;
                  },
                  settings: {
                    type: "static",
                    items: targetList,
                    options: {
                      matchId: "id",
                      matchNm: "text",
                    },
                  },
                },
                {
                  label: "Processing",
                  type: "button",
                  id: "btnOpenPopup",
                  isShowIcon: true,
                  isShowLabel: false,
                  icon: AiOutlineFunction,
                  className: "w-13p",
                  settings: {
                    onClick: (e) => openPopup(e, "Processing Details"),
                  },
                },
              ]}
              options={{
                isMulti: this.state.propertyValue.isComboMulti,
              }}
            />
          </Accordion.Body>
        </Accordion.Item>
      </CommonStyled>
    );
  };

  render() {
    switch (this.props.event) {
      //Properties Panel을 Rendering
      case "renderPropertiesPanel":
        // console.log(
        //   "[Check re-rendering]",
        //   "UIComponent has been re-rendered by the renderPropertiesPanel event !!! [" +
        //     this.props.componentInfo.compId +
        //     "]"
        // );

        return this.renderPropertiesPanel();

      //Editor에 Component를 Rendering
      case "renderEditor":
        // console.log(
        //   "[Check re-rendering]",
        //   "UIComponent has been re-rendered by the renderEditor event !!![" +
        //     this.props.componentInfo.compId +
        //     "]"
        // );
        return this.renderEditor();
      default:
        return <></>;
    }
  }
}
export default UIComponent;

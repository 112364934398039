import Domain from "./Domain.entity";

class Element {
  elementId = null;
  elementCd = null;
  elementNm = null;
  domain = new Domain();
  elementShortNm = null;
  description = null;
  entryType = null;
  entryRefTable = null;
  entryRefWhere = null;
  entryRefKey = null;
  entryRefValue = null;
  entryDisType = null;
  popupProgramIsd = null;
  popupSize = null;
  parameterId = null;
  elementType = null;
  possibleEntryList = null;
  isNull = true;
  constructor(element) {
    if (element) {
      this.elementId = element.elementId;
      this.elementCd = element.elementCd;
      this.elementNm = element.elementNm;
      this.domain = new Domain(element.domain);
      this.elementShortNm = element.elementShortNm;
      this.description = element.description;
      this.entryType = element.entryType;
      this.entryRefTable = element.entryRefTable;
      this.entryRefWhere = element.entryRefWhere;
      this.entryRefKey = element.entryRefKey;
      this.entryRefValue = element.entryRefValue;
      this.entryDisType = element.entryDisType;
      this.popupProgramId = element.popupProgramId;
      this.popupSize = element.popupSize;
      this.parameterId = element.parameterId;
      this.elementType = element.elementType;
      this.possibleEntryList = element.possibleEntryList;
      this.isNull = false;
    } else {
      this.isNull = true;
    }
  }

  getElementId() {
    return this.elementId;
  }

  setElementId(value) {
    this.elementId = value;
  }

  getElementCd() {
    return this.elementCd;
  }

  setElementCd(value) {
    this.elementCd = value;
  }

  getElementNm() {
    return this.elementNm;
  }

  setElementNm(value) {
    this.elementNm = value;
  }

  getDomain() {
    return this.domain;
  }

  setDomain(value) {
    this.domain = value;
  }

  getElementShortNm() {
    return this.elementShortNm;
  }

  setElementShortNm(value) {
    this.elementShortNm = value;
  }

  getDescription() {
    return this.description;
  }

  setDescription(value) {
    this.description = value;
  }

  getEntryType() {
    return this.entryType;
  }

  setEntryType(value) {
    this.entryType = value;
  }

  getEntryRefTable() {
    return this.entryRefTable;
  }

  setEntryRefTable(value) {
    this.entryRefTable = value;
  }

  getEntryRefWhere() {
    return this.entryRefWhere;
  }

  setEntryRefWhere(value) {
    this.entryRefWhere = value;
  }

  getEntryRefKey() {
    return this.entryRefKey;
  }

  setEntryRefKey(value) {
    this.entryRefKey = value;
  }

  getEntryRefValue() {
    return this.entryRefValue;
  }

  setEntryRefValue(value) {
    this.entryRefValue = value;
  }

  getEntryDisType() {
    return this.entryDisType;
  }

  setEntryDisType(value) {
    this.entryDisType = value;
  }

  getPopupProgramId() {
    return this.popupProgramId;
  }

  setPopupProgramId(value) {
    this.popupProgramId = value;
  }

  getPopupSize() {
    return this.popupSize;
  }

  setPopupSize(value) {
    this.popupSize = value;
  }

  getParameterId() {
    return this.parameterId;
  }

  setParameterId(value) {
    this.parameterId = value;
  }

  getElementType() {
    return this.elementType;
  }

  setElementType(value) {
    this.elementType = value;
  }

  getPossibleEntryList() {
    return this.possibleEntryList;
  }

  setPossibleEntryList(value) {
    this.possibleEntryList = value;
  }

  getIsNull() {
    return this.isNull;
  }

  toJSON() {
    return {
      elementId: this.elementId,
      elementCd: this.elementCd,
      elementNm: this.elementNm,
      domain: this.domain ? this.domain.toJSON() : null,
      elementShortNm: this.elementShortNm,
      description: this.description,
      entryType: this.entryType,
      entryRefTable: this.entryRefTable,
      entryRefWhere: this.entryRefWhere,
      entryRefKey: this.entryRefKey,
      entryRefValue: this.entryRefValue,
      entryDisType: this.entryDisType,
      popupProgramId: this.popupProgramId,
      popupSize: this.popupSize,
      parameterId: this.parameterId,
      elementType: this.elementType,
      possibleEntryList: this.possibleEntryList,
    };
  }
}

export default Element;

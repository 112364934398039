import Domain from "./Domain.entity";
import Element from "./Element.entity";
import EntityField from "./EntityField.entity";
import TrdTableField from "./TrdTableField.entity";

/**
 * Entity Field와 TrdTableField를 합친 필드
 */
class Field {
  physNm = null;
  logNm = null;
  dataType = null;
  columnNm = null;
  columnType = null;
  autoIncreamentYn = null;
  pkYn = null;
  requiredYn = null;
  uniqueYn = null;
  readonlyYn = null;
  insertDefault = null;
  updateDefault = null;
  insertableYn = null;
  updatableYn = null;
  refEntityId = null;
  refEntityFieldId = null;
  element = new Element();
  domain = new Domain();
  trdTableField = new TrdTableField();

  constructor({ entityField, trdTableField, element }) {
    const EF = new EntityField(entityField);
    const TTF = new TrdTableField(trdTableField);
    const Ele = new Element(element);

    this.physNm =
      EF.getPhysFieldNm() || TTF.getPhysicalNm() || Ele.getElementCd();
    this.logNm = EF.getLogFieldNm() || TTF.getLogicalNm() || Ele.getElementNm();
    this.dataType = EF.getUiDisplayType() || Ele.getDomain().getDataType();
    this.columnNm = EF.getColumnNm() || Ele.getElementNm();
    this.autoIncreamentYn =
      EF.getAutoIncreamentYn() || TTF.getAutoIncrementYn();
    this.pkYn = EF.getPkYn() || TTF.getPkYn();
    this.requiredYn = EF.getRequiredYn() || TTF.getNotNullYn();
    this.uniqueYn = EF.getUniqueYn() || TTF.getUniqueYn();
    this.readonlyYn = EF.getRequiredYn();
    this.insertDefault = EF.getInsertDefault() || TTF.getFieldDefaultValue();
    this.updateDefault = EF.getUpdateDefault() || TTF.getFieldDefaultValue();
    this.insertableYn = EF.getInsertableYn();
    this.updatableYn = EF.getUpdatableYn();
    this.element = Ele.getIsNull() ? TTF.getElement() : Ele;
    this.domain = Ele.getIsNull()
      ? TTF.getElement().getDomain()
      : Ele.getDomain();
    this.trdTableField = TTF;
  }

  getPhysNm() {
    return this.physNm;
  }

  setPhysNm(value) {
    this.physNm = value;
  }

  getLogNm() {
    return this.logNm;
  }

  setLogNm(value) {
    this.logNm = value;
  }

  getDataType() {
    return this.dataType;
  }

  setDataType(value) {
    this.dataType = value;
  }

  getColumnNm() {
    return this.columnNm;
  }

  setColumnNm(value) {
    this.columnNm = value;
  }

  getColumnType() {
    return this.columnType;
  }

  setColumnType(value) {
    this.columnType = value;
  }

  getAutoIncreamentYn() {
    return this.autoIncreamentYn;
  }

  setAutoIncreamentYn(value) {
    this.autoIncreamentYn = value;
  }

  getPkYn() {
    return this.pkYn;
  }

  setPkYn(value) {
    this.pkYn = value;
  }

  getRequiredYn() {
    return this.requiredYn;
  }

  setRequiredYn(value) {
    this.requiredYn = value;
  }

  getUniqueYn() {
    return this.uniqueYn;
  }

  setUniqueYn(value) {
    this.uniqueYn = value;
  }

  getReadonlyYn() {
    return this.readonlyYn;
  }

  setReadonlyYn(value) {
    this.readonlyYn = value;
  }

  getInsertDefault() {
    return this.insertDefault;
  }

  setInsertDefault(value) {
    this.insertDefault = value;
  }

  getUpdateDefault() {
    return this.updateDefault;
  }

  setUpdateDefault(value) {
    this.updateDefault = value;
  }

  getInsertableYn() {
    return this.insertableYn;
  }

  setInsertableYn(value) {
    this.insertableYn = value;
  }

  getUpdatableYn() {
    return this.updatableYn;
  }

  setUpdatableYn(value) {
    this.updatableYn = value;
  }

  getRefEntityId() {
    return this.refEntityId;
  }

  setRefEntityId(value) {
    this.refEntityId = value;
  }

  getRefEntityFieldId() {
    return this.refEntityFieldId;
  }

  setRefEntityFieldId(value) {
    this.refEntityFieldId = value;
  }

  toJSON() {
    return {
      physNm: this.physNm,
      logNm: this.logNm,
      dataType: this.dataType,
      columnNm: this.columnNm,
      columnType: this.columnType,
      autoIncreamentYn: this.autoIncreamentYn,
      pkYn: this.pkYn,
      requiredYn: this.requiredYn,
      uniqueYn: this.uniqueYn,
      readonlyYn: this.readonlyYn,
      insertDefault: this.insertDefault,
      updateDefault: this.updateDefault,
      insertableYn: this.insertableYn,
      updatableYn: this.updatableYn,
      refEntityId: this.refEntityId,
      refEntityFieldId: this.refEntityFieldId,
    };
  }

  getDomain() {
    return this.domain;
  }

  getElement() {
    return this.element;
  }

  getTrdTableField() {
    return this.trdTableField;
  }
}

export default Field;

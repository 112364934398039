import StringUtils from "components/common/utils/StringUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import ArrayUtils from "components/common/utils/ArrayUtils";
import * as Enums from "components/builder/BuilderEnum";
import produce from "immer";
import { GiConsoleController } from "react-icons/gi";

class JsonUtils {
  /**
   * parse from string to json
   * @param {String} string
   * @param {String} subPath
   * @returns
   */
  static parseJson = (string, subPath) => {
    let ret = {};
    if (StringUtils.isEmpty(string)) return ret;

    try {
      ret = JSON.parse(string);
      if (!StringUtils.isEmpty(subPath)) {
        ret = ret[subPath] || {};
      }
    } catch (e) {
      console.log("json parsing error for [" + string + "]: " + e);
    }
    return ret;
  };

  /**
   * default String
   * @param {*} json
   * @param {*} key
   * @param {*} replaceStr
   * @returns
   */
  static defaultString(json, key, replaceStr) {
    let defaultValue = StringUtils.isEmpty(replaceStr) ? "" : replaceStr;
    return ObjectUtils.isEmpty(json)
      ? defaultValue
      : StringUtils.defaultString(json[key], replaceStr);
  }

  /**
   * Node 검색
   * @param {*} data
   * @param {*} searchNodeKey 탐색할 node의 key
   * @param {*} searchNodeValue  탐색할 node의 value
   * @returns
   */
  static findNode = (data, searchNodeKey, searchNodeValue) => {
    let nodeValue = {};
    for (let k in data) {
      if (typeof data[k] === "object") {
        nodeValue = this.findNode(data[k], searchNodeKey, searchNodeValue);
        if (!ObjectUtils.isEmpty(nodeValue)) {
          return nodeValue;
        }
      } else {
        if (searchNodeKey === k && searchNodeValue === data[k]) {
          nodeValue = data || {};
          break;
        }
      }
    }
    return nodeValue;
  };

  /**
   * Node 검색
   * @param {*} data
   * @param {*} searchId  탐색할 node의 value
   * @returns
   */
  static findNodeById = (data, searchId) => {
    let nodeValue = {};
    for (let k in data) {
      if (typeof data[k] === "object" && k !== "propertyValue") {
        nodeValue = this.findNodeById(data[k], searchId);
        if (!ObjectUtils.isEmpty(nodeValue)) {
          return nodeValue;
        }
      } else if (k === "propertyValue") {
        if (data.type === Enums.ComponentType.GRID) {
          if (
            k === "propertyValue" &&
            data[k].gridOptions.gridId === searchId
          ) {
            nodeValue = data || {};
            break;
          }
        } else {
          if (k === "propertyValue" && data[k] && data[k].id === searchId) {
            nodeValue = data || {};
            break;
          }
        }
      }
    }
    return nodeValue;
  };

  /**
   * Type에 맞는 Node 검색
   * @param {*} data
   * @param {*} type
   * @returns
   */
  static findNodeByType = (data, type) => {
    let nodeValue = [];

    if (!data.child) {
      if (data.type && data.type === type) {
        nodeValue.push(data);
      }
      return nodeValue;
    }

    if (data.child) {
      if (data.type && data.type === type) {
        nodeValue.push(data);
      }
      // return nodeValue;
    }

    for (let i = 0; i < data.child.length; i++) {
      // if (data.type && data.type === type) {
      //   nodeValue.push(data);
      // }
      const newValue = this.findNodeByType(data.child[i], type);
      if (newValue.length > 0) {
        nodeValue = [...nodeValue, ...newValue];
      }
    }

    return nodeValue;
  };

  /**
   * objectType에 맞는 component 검색
   * @param {*} data
   * @param {*} objectType
   * @returns
   */
  static findComponentNodeByObject = (data, objectType) => {
    let nodeValue = [];

    if (!data.child) {
      if (data.type && data.type === "component") {
        if (data.viewerAttr.object === objectType) {
          nodeValue.push(data);
        }
      }
      return nodeValue;
    }

    if (data.child && data.child.length === 0) {
      if (data.type && data.type === "component") {
        if (data.viewerAttr.object === objectType) {
          nodeValue.push(data);
        }
      }
      return nodeValue;
    }

    for (let i = 0; i < data.child.length; i++) {
      if (data.type && data.type === "component") {
        if (data.viewerAttr.object === objectType) {
          nodeValue.push(data);
        }
      }
      const newValue = this.findComponentNodeByObject(
        data.child[i],
        objectType
      );
      if (newValue.length > 0) {
        nodeValue = [...nodeValue, ...newValue];
      }
    }

    return nodeValue;
  };

  /**
   * component 검색
   * @param {*} data
   * @param {*} objectType
   * @returns
   */
  static findComponentNode = (data) => {
    let nodeValue = [];

    if (!data.child) {
      if (data.type && data.type === "component") {
        nodeValue.push(data);
      }
      return nodeValue;
    }

    if (data.child && data.child.length === 0) {
      if (data.type && data.type === "component") {
        nodeValue.push(data);
      }
      return nodeValue;
    }

    for (let i = 0; i < data.child.length; i++) {
      if (data.type && data.type === "component") {
        nodeValue.push(data);
      }
      const newValue = this.findComponentNode(data.child[i]);
      if (newValue.length > 0) {
        nodeValue = [...nodeValue, ...newValue];
      }
    }

    return nodeValue;
  };

  /**
   * Event target Node를 추출한다.
   * @param {*} data
   * @param {*} types 탐색할 node의 type (grid, form)
   * @param {*} extTypes  탐색에서 제외해야할 Type (ex, SEARCH)
   * @param {*} results  탐색결과 array
   * @returns
   */
  static findEventTargetNods = (data, types, extTypes, results, path) => {
    results = results || [];
    for (let k in data) {
      if (typeof data[k] === "object") {
        let nodeValue = this.findEventTargetNods(
          data[k],
          types,
          extTypes,
          results,
          path
        );
        if (!ObjectUtils.isEmpty(nodeValue)) {
          results.push(nodeValue);
        }
      } else {
        //StringUtils.isEmpty(data["target"]) 추가 이유 : target 선택 시 "type"이 들어가기 때문에 조회 오류 발생
        if (
          k === "type" &&
          types.indexOf(data[k]) > -1 &&
          StringUtils.isEmpty(data["target"])
        ) {
          const propertyValue = data["propertyValue"] || {};
          let id = "";
          let text = "";
          let type = "";
          if (data[k] === Enums.ComponentType.GRID) {
            let gridOptions = propertyValue.gridOptions || {};
            id = gridOptions.gridId;
            text = StringUtils.defaultString(gridOptions.title, id);
          } else {
            id = StringUtils.defaultString(propertyValue.id);
            text = StringUtils.defaultString(propertyValue.name, id);
            type = StringUtils.defaultString(propertyValue.formType);
          }

          if (ArrayUtils.isEmpty(extTypes) || extTypes.indexOf(type) < 0) {
            path = (path || "") + data["compId"] + "/";
            let returnVal = {
              id: id,
              text:
                "[" +
                data[k] +
                "] " +
                StringUtils.defaultString(text, "(empty)"),
              compId: data["compId"],
              type: data[k],
              path: path,
            };

            if (data[k] === Enums.ComponentType.GRID) {
              return returnVal;
            } else {
              results.push(returnVal);
            }
          }
        }
      }
    }
  };

  /**
   *
   * @param {*} data
   * @param {*} searchNodeKey 탐색할 node의 key
   * @param {*} searchNodeValue  탐색할 node의 value (값이 없을 경우 searchNodeKey가 일치하는 props모두 제거한다.)
   * @returns
   */
  static removeNode = (data, searchNodeKey, searchNodeValue) => {
    let nodeValue = {};
    for (let k in data) {
      if (typeof data[k] === "object") {
        if (searchNodeKey === k && StringUtils.isEmpty(searchNodeValue)) {
          delete data[k];
        } else {
          nodeValue = this.removeNode(data[k], searchNodeKey, searchNodeValue);
          if (!ObjectUtils.isEmpty(nodeValue)) {
            if (!ObjectUtils.isEmpty(nodeValue._remove)) {
              delete data[k];
              return { ...nodeValue._remove };
            } else {
              //child가 모두 제거된경우 child prop를 삭제한다.
              if (ArrayUtils.isArray(data[k])) {
                data[k] = data[k].filter(
                  (element, i) => !StringUtils.isEmpty(element)
                );
              }
              return { ...nodeValue };
            }
          }
        }
      } else {
        if (
          searchNodeKey === k &&
          (searchNodeValue === data[k] || StringUtils.isEmpty(searchNodeValue))
        ) {
          nodeValue = { _remove: data };
          break;
        }
      }
    }
    return nodeValue;
  };

  /**
   *
   * @param {*} data
   * @param {*} searchNodeKey 탐색할 node의 key
   * @param {*} searchNodeValue  탐색할 node의 value
   * @param {*} overrideNodeName
   * @param {*} overrideValue
   * @returns
   */
  static overrideNode = (
    data,
    searchNodeKey,
    searchNodeValue,
    overrideNodeName,
    overrideValue
  ) => {
    let nodeValue = {};
    for (let k in data) {
      if (typeof data[k] === "object") {
        nodeValue = this.overrideNode(
          data[k],
          searchNodeKey,
          searchNodeValue,
          overrideNodeName,
          overrideValue
        );
        if (!ObjectUtils.isEmpty(nodeValue)) {
          return nodeValue;
        }
      } else {
        if (searchNodeKey === k && searchNodeValue === data[k]) {
          /*
          if (!ObjectUtils.isEmpty(data[overrideNodeName])) {
            data[overrideNodeName] = {
              ...data[overrideNodeName],
              ...overrideValue,
            };
          } else {
            data[overrideNodeName] = overrideValue;
          }
          */
          data[overrideNodeName] = overrideValue;
          nodeValue = data || {};
          break;
        }
      }
    }
    return nodeValue;
  };

  /**
   * style 변경
   *
   * @param {*} data
   * @param {*} searchNodeKey 탐색할 node의 key
   * @param {*} searchNodeValue  탐색할 node의 value
   * @returns
   */
  static overrideNodeStyle = (
    data,
    searchNodeKey,
    searchNodeValue,
    overrideNodeName,
    overrideValue
  ) => {
    let nodeValue = {};
    for (let k in data) {
      if (typeof data[k] === "object") {
        nodeValue = this.overrideNodeStyle(
          data[k],
          searchNodeKey,
          searchNodeValue,
          overrideNodeName,
          overrideValue
        );
        if (!ObjectUtils.isEmpty(nodeValue)) {
          return nodeValue;
        }
      } else {
        if (searchNodeKey === k && searchNodeValue === data[k]) {
          if (ObjectUtils.isEmpty(data["style"])) {
            data["style"] = {};
          }

          data["style"][overrideNodeName] = overrideValue;
          nodeValue = data || {};
          break;
        }
      }
    }
    return nodeValue;
  };

  /**
   * 검색된 node의 parent node를 return한다.
   * @param {Map} data
   * @param {String} searchNodeKey
   * @param {String} searchNodeValue
   * @param {String} parentNodeKey
   * @param {String} parentNodeValue
   * @returns
   */
  static _findParentNode = (
    data,
    searchNodeKey,
    searchNodeValue,
    parentNodeKey,
    parentNodeValue
  ) => {
    let nodeValue = {};
    for (let k in data) {
      if (typeof data[k] === "object") {
        nodeValue = this._findParentNode(
          data[k],
          searchNodeKey,
          searchNodeValue,
          parentNodeKey,
          parentNodeValue
        );
        if (!ObjectUtils.isEmpty(nodeValue)) {
          if (
            !ObjectUtils.isEmpty(nodeValue._child) &&
            (StringUtils.isEmpty(parentNodeKey) ||
              (!StringUtils.isEmpty(data[parentNodeKey]) &&
                StringUtils.isEmpty(parentNodeValue)) ||
              data[parentNodeKey] === parentNodeValue)
          ) {
            return data;
          } else {
            return nodeValue;
          }
        }
      } else {
        if (searchNodeKey === k && searchNodeValue === data[k]) {
          nodeValue = { _child: true };
          break;
        }
      }
    }
    return nodeValue;
  };
  /**
   * 검색된 node의 parent node를 return한다.
   * @param {Map} data
   * @param {String} searchNodeKey
   * @param {String} searchNodeValue
   * @param {String} parentNodeKey
   * @param {*} parentNodeValue
   * @returns
   */
  static findParentNode = (
    data,
    searchNodeKey,
    searchNodeValue,
    parentNodeKey,
    parentNodeValue
  ) => {
    const node = JsonUtils._findParentNode(
      data,
      searchNodeKey,
      searchNodeValue,
      parentNodeKey,
      parentNodeValue
    );
    return ObjectUtils.isEmpty(node) || node._child === true ? {} : node;
  };

  /**
   * 상위 Node의 특정값을 가져온다
   * @param {*} data
   * @param {*} searchNodeKey
   * @param {*} searchNodeValue
   * @param {*} parentNodeKey
   * @param {*} parentNodeValue
   * @returns
   */
  static findParentNodeValue = (
    data,
    searchNodeKey,
    searchNodeValue,
    parentNodeKey,
    parentNodeValue
  ) => {
    const node = JsonUtils.findParentNode(
      data,
      searchNodeKey,
      searchNodeValue,
      parentNodeKey,
      parentNodeValue
    );
    return ObjectUtils.isEmpty(node) ? "" : node[parentNodeKey];
  };

  /**
   * Data model을 조회한다.
   * @param {Map} data
   * @param {String} searchNodeKey
   * @param {String} searchNodeValue
   * @param {String} selfIncluded
   * @returns
   */
  static _findDataModel = (
    data,
    searchNodeKey,
    searchNodeValue,
    selfIncluded
  ) => {
    let nodeValue = {};
    for (let k in data) {
      if (typeof data[k] === "object") {
        nodeValue = this._findDataModel(
          data[k],
          searchNodeKey,
          searchNodeValue,
          selfIncluded
        );
        if (!ObjectUtils.isEmpty(nodeValue)) {
          if (
            !ObjectUtils.isEmpty(nodeValue._child) &&
            !ObjectUtils.isEmpty(data["propertyValue"]) &&
            !StringUtils.isEmpty(data["propertyValue"]["dataModelId"])
          ) {
            return data["propertyValue"]["dataModelId"];
          } else {
            return nodeValue;
          }
        }
      } else {
        if (searchNodeKey === k && searchNodeValue === data[k]) {
          if (
            selfIncluded === true && //자기자신 포함
            !ObjectUtils.isEmpty(data["propertyValue"]) &&
            !StringUtils.isEmpty(data["propertyValue"]["dataModelId"])
          ) {
            nodeValue = data["propertyValue"]["dataModelId"]; //자신이 dataModel을 가지고 있을 경우...
          } else if (
            selfIncluded === true && //자기자신 포함
            !StringUtils.isEmpty(data["dataModelId"])
          ) {
            nodeValue = data["dataModelId"]; //자신이 dataModel을 가지고 있을 경우...
          } else {
            nodeValue = { _child: true };
          }
          break;
        }
      }
    }
    return nodeValue;
  };

  /**
   * 상위 Node의 Data model을 가져온다.
   * @param {*} data
   * @param {*} compId
   * @param {Boolean} selfIncluded
   * @returns
   */
  static findDataModel = (data, compId, selfIncluded) => {
    let dataModelId = JsonUtils._findDataModel(
      data,
      "compId",
      compId,
      selfIncluded
    );
    return typeof dataModelId === "object" ? "" : dataModelId;
  };

  /**
   * 상위 Node의 Data model을 가져온다.
   * @param {*} data
   * @param {*} gridId
   * @param {Boolean} selfIncluded
   * @returns
   */
  static findGridDataModel = (data, gridId, selfIncluded) => {
    let dataModelId = JsonUtils._findDataModel(
      data,
      "gridId",
      gridId,
      selfIncluded
    );
    return typeof dataModelId === "object" ? "" : dataModelId;
  };

  /**
   * Node가 복제로 생생된 경우 component 고유key (compId) 를 재 생성한다.
   * @param {*} data
   */
  static updateCompId = (data) => {
    for (let k in data) {
      if (typeof data[k] === "object") {
        this.updateCompId(data[k]);
      } else {
        if ("compId" === k) {
          data[k] = StringUtils.getUuid();
        }
      }
    }
  };

  /**
   * Node가 복제로 생생된 경우 component 고유key (compId) 를 재 생성한다.
   * 기존 아이디와 새로운 아이디를 맵 Key:value로 하여 리턴한다.
   * @param {*} data
   */
  static updateCompIdAndGetPrevData = (data, prevCompIdObject = {}) => {
    for (let k in data) {
      if (typeof data[k] === "object") {
        this.updateCompIdAndGetPrevData(data[k], prevCompIdObject);
      } else {
        if ("compId" === k) {
          const newCompId = StringUtils.getUuid();
          prevCompIdObject[data[k]] = newCompId;
          data[k] = newCompId;
        }
      }
    }
    return [data, prevCompIdObject];
  };

  /**
   * Grid가 복제로 생생된 경우 Grid 고유key (gridId) 를 재 생성한다.
   * @param {*} data
   * @param {*} newGridId
   */
  static updateGridId = (data, newGridId) => {
    for (let k in data) {
      if (typeof data[k] === "object") {
        this.updateGridId(data[k], newGridId);
      } else {
        if ("gridId" === k) {
          data[k] = newGridId;
        }
      }
    }
  };

  /**
   * data cleanup
   * @param {*} data
   */
  static cleanup = (data) => {
    const newData = produce(data, (draft) => {
      for (let k in draft) {
        if (typeof draft[k] === "object") {
          this.cleanup(draft[k]);
        } else {
          if (StringUtils.isEmpty(draft[k])) {
            delete draft[k];
          }
        }
      }
    });
    // for (let k in data) {
    //   if (typeof data[k] === "object") {
    //     this.cleanup(data[k]);
    //   } else {
    //     if (StringUtils.isEmpty(data[k])) {
    //       delete data[k];
    //     }
    //   }
    // }

    data = newData;
  };

  /**
   * 폼의 form/* 컴포넌트들을 리턴한다.
   * @param {*} form
   * @param {[String]} viewerAttrTagName viewerAttr의 tagName의 value값을 배열로 받는다. 해당 배열 내의 포함된 태그명의 컴포넌트만 리턴한다.
   * @return {Array}
   */
  static getFormChildInput = (form, viewerAttrTagName) => {
    if (!form) return [];
    if (viewerAttrTagName)
      viewerAttrTagName = viewerAttrTagName.map((name) => name.toUpperCase());
    const FormInputChildList = [];
    const findChilNode = (Object) => {
      for (const node in Object) {
        if (
          Object[node] &&
          typeof Object[node] === "object" &&
          Object[node].editorAttr &&
          Object[node].editorAttr.componentClass
        ) {
          if (Object[node].editorAttr.componentClass.split("/")[0] === "form") {
            if (
              ArrayUtils.isArray(viewerAttrTagName) &&
              viewerAttrTagName.length > 0
            ) {
              if (
                viewerAttrTagName.includes(
                  String(Object[node].viewerAttr.object).toUpperCase()
                )
              ) {
                FormInputChildList.push(Object[node]);
              } else {
                findChilNode(Object[node]);
              }
            } else {
              FormInputChildList.push(Object[node]);
            }
          } else {
            findChilNode(Object[node]);
          }
        } else if (typeof Object[node] === "object") {
          findChilNode(Object[node]);
        }
      }
    };
    findChilNode(form);

    return FormInputChildList;
  };

  /**
   * compId를 사용하여 가장 가까이에 있는 Form (부모 Form) 을 리턴한다.
   * @param {*} data
   * @param {*} compId
   */
  static findParentsFormByCompId = (data, compId) => {
    // return console.log(data);

    let closestForm = null;

    const find = (currentObj) => {
      if (typeof currentObj !== "object" || currentObj === null) return;

      // 현재 객체에 compId와 일치하는 값이 있는지 확인합니다.
      //일치하면 바로 리턴
      if (currentObj.compId === compId) {
        return closestForm;
      }

      //'form'인 경우, closeestForm 적용
      if (currentObj.type === "form") {
        closestForm = currentObj;
      }

      // 객체의 각 키에 대해 순회.
      for (let key in currentObj) {
        if (currentObj.hasOwnProperty(key)) {
          const result = find(currentObj[key]);
          // compId가 일치하는 객체를 찾으면 반환합니다.
          if (result) return result;
        }
      }

      return null;
    };
    return find(data);
  };

  /**
   * 자녀의 CompId를 이용해서 부모의 노드를 확인함
   * @param {*} output.page 아웃풋 페이지 데이터
   * @param {*} targetCompId 찾고자하는 자식의 CompId
   * @returns {*} parentsNode
   */
  static findParentNodeByOnlyCompId(page, targetCompId) {
    let idList = [];
    const find = (_list, ids) => {
      _list.map((c) => {
        let newList = [...ids];
        if (c.compId === targetCompId) {
          newList.push(c.compId);
          idList = newList;
          return c;
        } else if (c.child?.length > 0) {
          newList.push(c.compId);
          return find(c.child, newList);
        } else if (
          c.type === Enums.ComponentType.GRID &&
          c.propertyValue.gridOptions.columns.length > 0
        ) {
          newList.push(c.compId);
          return find(c.propertyValue.gridOptions.columns, newList);
        }
      });
    };
    find(page.child, [page.compId]);
    let parentNode = null;
    if (idList.length > 2) {
      parentNode = this.findNode(page, "compId", idList.at(-2));
    }
    return parentNode;
  }

  /**
   * Header 또는 Cell의 부모가 되는 Grid를 선택한다.
   * @param {*} output.page 아웃풋 페이지 데이터
   * @param {*} targetCompId 찾고자하는 자식의 CompId
   * @returns {*} parentsNode
   */
  static findParentGridByOnlyCompId(page, targetCompId) {
    let idList = [];
    const find = (_list, ids) => {
      _list.map((c) => {
        let newList = [...ids];
        if (c.compId === targetCompId) {
          newList.push(c.compId);
          idList = newList;
          return c;
        } else if (c.child?.length > 0) {
          newList.push(c.compId);
          return find(c.child, newList);
        }
      });
    };
    find(page.child, [page.compId]);
    let parentNode = null;
    if (idList.length > 2) {
      parentNode = this.findNode(page, "compId", idList.at(-2));
    }
    return parentNode;
  }

  /**
   * data 내에서 키값과 일치하는 값을 배열로 하여 리턴한다.
   *
   * @param {*} data JSON 데이터
   * @param {*} searchNodeKey 찾고자 하는 키값
   * @returns {Array}
   */
  static findNodeValues(data, searchNodeKey) {
    const NodeValues = [];

    const _findNodeValues = (_data) => {
      for (let k in _data) {
        if (searchNodeKey === k) {
          NodeValues.push(_data[k]);
        }

        if (typeof _data[k] === "object") {
          _findNodeValues(_data[k]);
        }
      }
    };
    _findNodeValues(data);
    return NodeValues;
  }
}
export default JsonUtils;

import React, { useState } from "react";
import {
  FaWpforms,
  FaUserCog,
  FaChartBar,
  FaTools,
  FaDatabase,
  FaDev,
  FaDrawPolygon,
  FaMobileAlt,
  FaDochub,
  FaDocker,
} from "react-icons/fa";
import * as Enums from "components/builder/BuilderEnum";
import { useNavigate } from "react-router-dom";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import { AiOutlineFunction, AiOutlinePartition } from "react-icons/ai";
import ProgramMenu from "./ui/ProgramMenu";
import ContextMenu from "./contextMenu/ContextMenu";
import { Tooltip } from "@mui/material";
import { BiErrorCircle } from "react-icons/bi";
import { TbReport } from "react-icons/tb";

const MenuList = [
  {
    key: "Editor",
    navigate: Enums.BuilderPath.EDITOR,
    Icon: FaWpforms,
    contextMenu: true,
    tooltip: "UI Builder",
  },
  // {
  //   key: "MOBILE",
  //   navigate: Enums.BuilderPath.MOBILE,
  //   Icon: FaMobileAlt,
  //   contextMenu: true,
  //   tooltip: "Mobile UI 빌더",
  // },
  {
    key: "ENTITY",
    Icon: FaDatabase,
    navigate: Enums.BuilderPath.ENTITY,
    contextMenu: true,
    tooltip: "DataModel Builder",
  },
  {
    key: "Workflow",
    Icon: AiOutlinePartition,
    navigate: Enums.BuilderPath.WORKFLOW,
    contextMenu: true,
    tooltip: "Workflow Builder",
  },

  {
    key: "User",
    Icon: FaUserCog,
    navigate: Enums.BuilderPath.USER.MAIN,
    tooltip: "User Management",
  },
  {
    key: "TechnicalDesign",
    Icon: TbReport,
    navigate:
      Enums.BuilderPath.PROGRAM_DESIGN.MAIN +
      "/" +
      Enums.BuilderPath.PROGRAM_DESIGN.LIST,
    contextMenu: true,
    tooltip: "Technical Design",
  },
  {
    key: "Setting",
    Icon: FaTools,
    navigate: Enums.BuilderPath.SETTING,
    tooltip: "Settings",
  },
  {
    key: "AdminSetting",
    Icon: FaDev,
    admin: true,
    navigate:
      Enums.BuilderPath.ADMIN.MAIN + "/" + Enums.BuilderPath.ADMIN.SETTING,
    tooltip: "Manager Settings",
  },
];

class BuilderSidebar extends React.Component {
  constructor(props) {
    super(props);

    //this.doLogout = this.doLogout.bind(this);

    this.state = {
      selectedMenu: {},
      contextMenuOpen: false,
    };
  }

  doLogout() {
    //this.props.dispatch(logoutUser());
  }
  onSelectMenu(menu) {
    const newState = {
      ...this.state,
      selectedMenu: menu,
    };
    if (
      !StringUtils.isEmpty(this.state.selectedMenu) &&
      StringUtils.equalsIgnoreCase(menu.key, this.state.selectedMenu.key)
    ) {
      newState.contextMenuOpen = !newState.contextMenuOpen;

      if (!newState.contextMenuOpen) {
        newState.selectedMenu = {};
      }
    } else if (
      !StringUtils.equalsIgnoreCase(menu.key, this.state.selectedMenu.key) &&
      !newState.contextMenuOpen
    ) {
      newState.contextMenuOpen = !newState.contextMenuOpen;
    }
    this.setState(newState);
  }

  renderContextMenu() {
    if (!ObjectUtils.isEmpty(this.state.selectedMenu)) {
      return (
        <ProgramMenu
          isOpen={this.state.contextMenuOpen}
          menu={this.state.selectedMenu}
        />
      );
    } else {
      this.setState({
        ...this.state,
        contextMenuOpen: false,
      });
      return <></>;
    }
  }

  render() {
    return (
      <>
        <div className="main-sidebar">
          <div className="sidebar">
            <ul className="sidebar-menu">
              {MenuList.map((menu) => {
                if (
                  menu.admin &&
                  !StringUtils.equalsIgnoreCase(User.getAuthType(), "S")
                )
                  return <></>;
                else {
                  return (
                    <SideMenuContents
                      key={menu.key}
                      menu={menu}
                      selectedMenu={this.state.selectedMenu}
                      contextMenuHandler={(flag) =>
                        this.setState({ contextMenuOpen: flag })
                      }
                      onClick={() => this.onSelectMenu(menu)}
                    />
                  );
                }
              })}
              <li
                role="button"
                style={{ position: "fixed", bottom: 0, width: "50px" }}
                onClick={(e) => {
                  window.open(
                    "https://dev.bizentro.net/plugins/tracker/?tracker=8&func=new-artifact"
                  );
                }}
              >
                <Tooltip title={"Error Report"} placement="right">
                  <span>
                    <BiErrorCircle size={22} />
                  </span>
                </Tooltip>
              </li>
            </ul>
          </div>
        </div>
        <ContextMenu
          isOpen={this.state.contextMenuOpen}
          menu={this.state.selectedMenu}
          contextMenuHandler={(flag) => {
            if (!flag) {
              this.setState({ contextMenuOpen: flag, selectedMenu: {} });
            } else {
              this.setState({ contextMenuOpen: flag });
            }
          }}
        />
      </>
    );
  }
}

export default BuilderSidebar;

const SideMenuContents = ({
  menu,
  selectedMenu,
  onClick,
  contextMenuHandler,
}) => {
  const category = window.location.pathname.split("/")[1];
  const navigate = useNavigate();
  const onClickMenu = () => {
    if (onClick) onClick();
    if (!menu.contextMenu) {
      navigate(menu.navigate);
      contextMenuHandler(false);
    }
  };

  return (
    <li
      className={
        StringUtils.equalsIgnoreCase(menu.key, selectedMenu.key) ? "active" : ""
      }
      role="button"
      onClick={onClickMenu}
    >
      <Tooltip title={menu.tooltip} placement="right">
        <span>
          <menu.Icon size="22" />
        </span>
      </Tooltip>
    </li>
  );
};

import WijmoGrid from "components/common/element/WijmoGrid";
import ArrayUtils from "components/common/utils/ArrayUtils";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import React, { useEffect, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import CodeService from "services/common/CodeService";
import TechnicalDesignService from "services/technicaldesign/TechnicalDesignService";

const TechnicalDesignSelectionLayout = () => {
  const [designColumnList, setDesignColumnList] = useState([]);
  const [displayButton, setDisplayButton] = useState([]);
  const [displayComponent, setDisplayComponent] = useState([]);
  const [displayTypeList, setDisplayTypeList] = useState([]);
  const [displayDtlTypeList, setDisplayDtlTypeList] = useState([]);
  const [displayHandleTypeList, setDisplayHandleTypeList] = useState([]);
  const userLang = User.getLanguage();

  useEffect(() => {
    const designColumnData = {
      designMstId: "1",
      layoutCd: "S",
    };
    TechnicalDesignService.getLayoutColumnBtn(designColumnData, (res) => {
      getMultiLangData(res.data);
    });
    CodeService.getCodeCombo({ codeMstCd: "TD005" }, (res) => {
      setDisplayTypeList(res.data);
    });
    CodeService.getCodeCombo({ codeMstCd: "TD006" }, (res) => {
      setDisplayDtlTypeList(res.data);
    });
    CodeService.getCodeCombo({ codeMstCd: "TD007" }, (res) => {
      setDisplayHandleTypeList(res.data);
    });
  }, []);

  /**
   * langData를 list에 매핑해주는 함수
   * @param {*} data
   * @returns
   */
  const getMultiLangData = (data) => {
    if (!ArrayUtils.isEmpty(data)) {
      const tmpList = [...data];
      let displayTmpList = [...displayComponent];
      let btnTmpList = [...displayButton];
      for (let item of tmpList) {
        const itemLang = item.designLayoutColumnLang.find((item) =>
          StringUtils.equalsIgnoreCase(item.langCd, userLang)
        );
        item.columnDesc = itemLang.columnDesc;
        item.displayDtlType = itemLang.displayDtlType;
        item.displayDtlDesc = itemLang.displayDtlDesc;
        item.columnLogicalNm = itemLang.columnLogicalNm;
        if (item.displayType === "button") {
          btnTmpList = [item, ...btnTmpList];
          setDisplayButton(btnTmpList);
        } else {
          displayTmpList = [item, ...displayTmpList];
          setDisplayComponent(displayTmpList);
        }
      }
      setDesignColumnList(tmpList);
    } else {
      return "";
    }
  };

  const cellEditEndedEvt = (s, e) => {
    debugger;
    // s.activeEditor.value
  };

  const columns = [
    {
      field: "columnLogicalNm",
      headerName: "Column Name",
      width: 200,
    },
    {
      field: "columnPhysicalNm",
      headerName: "Column ID",
      width: 150,
    },
    { field: "columnDesc", headerName: "Description", width: "2*" },

    {
      field: "displayType",
      headerName: "Display Type",
      width: 150,
      renderCell: (params) => {
        return displayTypeList.find((item) => item.id === params.displayType)
          ?.text;
      },
    },
    {
      field: "displayDtlType",
      headerName: "Display Dtl Type",
      width: 150,
      renderCell: (params) => {
        return displayDtlTypeList.find(
          (item) => item.id === params.displayDtlType
        )?.text;
      },
    },
    {
      field: "displayDtlDesc",
      headerName: "Display Description",
      width: "*",
    },
    {
      field: "displayHandleType",
      headerName: "Handle Type",
      width: 150,
      renderCell: (params) => {
        return displayHandleTypeList.find(
          (item) => item.id === params.displayHandleType
        )?.text;
      },
    },
    {
      field: "defaultValue",
      headerName: "Default",
      width: 130,
    },
    {
      field: "characterLength",
      headerName: "Length",
      width: 130,
    },
    {
      field: "remarks",
      headerName: "Remarks",
      width: "*",
    },
  ];

  return (
    <React.Fragment>
      <WijmoGrid
        columns={columns}
        rows={displayButton}
        isReadOnly={false}
        cellEditEnding={cellEditEndedEvt}
      />
      <WijmoGrid
        columns={columns}
        rows={displayComponent}
        isReadOnly={false}
        cellEditEnding={cellEditEndedEvt}
      />
    </React.Fragment>
  );
};
export default TechnicalDesignSelectionLayout;

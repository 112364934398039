import { Enums } from "components/builder/BuilderEnum";
import UITemplateHelper from "components/builder/ui/editor/helper/UITemplateHelper";
import GridLayoutTemplate from "components/builder/ui/uiComponents/template/GridLayoutTemplate";
import USelectbox from "components/common/element/USelectbox";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import ArrayUtils from "components/common/utils/ArrayUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import Field from "entity/Field.entity";
import produce from "immer";
import React from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { FaMinus } from "react-icons/fa";
import DataModelService from "services/datamodel/DataModelService";
import ElementService from "services/trd/ElementService";
import TrdService from "services/trd/TrdService";
import WorkflowService from "services/workflow/WorkflowService";

/**
 * Editor에서 그리드를 더블클릭 하는 경우 나타는 수정 팝업
 */
class GridUpdatePopup extends GridLayoutTemplate {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: {
        serviceUid: this.props.serviceUid || null,
        dataModelId: this.props.dataModelId || "",
        headerTitle:
          !StringUtils.isEmpty(this.props.propertyValue.title) || false,
        displayMode: StringUtils.defaultString(
          this.props.propertyValue.displayMode,
          "E"
        ),
        dataModelEntityId: this.props.propertyValue.dataModelEntityId || "",
        entityVariable: this.props.entityVariable || "",
        isDataStudioElement:
          this.props.propertyValue.isDataStudioElement || false,
        dataModelInfo: null,
        containButton:
          !ObjectUtils.isEmpty(
            this.props.propertyValue.additionalOptions.toolbarOptions.buttons
          ) ||
          !ObjectUtils.isEmpty(
            this.props.propertyValue.additionalOptions.toolbarOptions
              .customButton
          ),
      },
      fieldCol: [
        {
          field: "name",
          headerName: "Header ID",
          width: 115,
          headerAlign: "center",
        },
        {
          field: "title",
          headerName: "Title",
          width: 110,
          headerAlign: "center",
        },
        {
          field: "dataType",
          headerName: "Type",
          width: "*",
          headerAlign: "center",
        },
      ],
      gridCol: [
        {
          field: "remove",
          headerName: "삭제",
          width: 50,
          sortable: false,
          headerAlign: "center",
          align: "center",
          renderCell: (params) => {
            return (
              <Button
                size="sm"
                onClick={(e) => this.onDelete(e, params)}
                className="btn btn-outline-secondary btn-sm btn-remove"
                style={{
                  padding: "0px 3px 0px 3px",
                }}
              >
                <FaMinus />
              </Button>
            );
          },
        },
        {
          field: "name",
          headerName: "Header ID",
          width: 115,
          sortable: false,
          headerAlign: "center",
        },
        {
          field: "title",
          headerName: "Title",
          width: 110,
          sortable: false,
          headerAlign: "center",
        },
        {
          field: "dataType",
          headerName: "Type",
          width: "*",
          sortable: false,
          headerAlign: "center",
        },
        // { field: "compId", headerName: "compId", hide: true },
      ],
      checkedField: [],
      fieldList: [],
      gridCells: [],
    };
    this.onClickConfirm = this.onClickConfirm.bind(this);
  }

  async componentDidMount() {
    //gridOptions 데이터 이다.
    let props = { ...this.props.propertyValue };
    let state = { ...this.state };
    const { columns, ...gridInputValue } = props;
    //초기 설정
    state.inputValue = {
      ...state.inputValue,
      ...gridInputValue,
    };
    //필드 설정
    //dataModel만 설정된 경우

    if (props.isDataStudioElement && props.dataModelEntityId) {
      state.fieldList = await this.getfieldListWithElement(
        props.dataModelEntityId
      );
    } else if (props.dataModelEntityId) {
      state.fieldList = await this.getfieldList(props.dataModelEntityId);
    } else if (state.inputValue.entityVariable) {
      state.fieldList = await this.getWorkflowFieldList(state);
      state.inputValue.serviceUid = this.props.serviceUid;
      state.inputValue.entityVariable = this.props.entityVariable;
      delete state.inputValue.dataModelEntityId;
    }
    //gridCell적용
    state.gridCells = columns;
    //state 적용
    this.setState(state);
  }

  /**
   * DataModel Field와 Element 정보를 가져옴
   * @param {*} entityId
   * @returns {Promise} fieldList
   */
  getfieldListWithElement = (entityId) => {
    const tableMstId = this.props.propertyValue.tableMstId;
    let newGrid = UITemplateHelper.getGridTemplate(this.templateComponents);
    let gridColumOption = { ...newGrid.propertyValue.gridColumns };
    let _this = this;

    return new Promise((resolve, reject) => {
      DataModelService.getDataModelEntityFieldList(
        { entityId },
        async (result) => {
          if (ArrayUtils.isEmpty(result.data)) {
            Message.alert(
              "선택하신 Data Entity에 Column 정보가 존재 하지 않습니다.",
              Enums.MessageType.ERROR
            );
            resolve([]);
          } else {
            let gridCells = [];
            //Trd Table 정보 호출
            let trdTable = null;
            if (tableMstId) {
              trdTable = await TrdService.getTableInfo({
                tableMstId,
              });
            }
            /* grid column  구성 */
            result.data.map((column, index) => {
              let gridCell = {
                ...gridColumOption,
              };
              let trdTableField = null;
              if (trdTable.data) {
                //trdTable 있는 경우 field 정보 호출
                const fieldList = trdTable.data.trdTableField;
                trdTableField = fieldList.find((field) =>
                  StringUtils.equalsIgnoreCase(
                    field.element.elementCd,
                    column.physFieldNm
                  )
                );
              }
              const field = new Field({
                entityField: column,
                trdTableField: trdTableField,
              });
              gridCell.compId = StringUtils.getUuid();
              gridCell.gridId = _this.state.inputValue.gridId;
              gridCell.name = field.getPhysNm();
              gridCell.data = field.getPhysNm();
              gridCell.title = field.getLogNm();
              gridCell.editType = field.getReadonlyYn() === "Y" ? "E" : "R";
              gridCell.required = field.getRequiredYn() === "Y" ? true : false;
              gridCell.sortOrder = index + 1;

              /**
               * Z_CODE_MST Code Z0011
               * DataType 규칙
               * 1. Domain을 사용하고 number 타입인 경우 numeric
               * 2. Domain을 사용하고 number 타입 + 포맷 금액일 경우 numeric(0)
               * 3. Domain을 사용하고 number 타입 + 포맷 수량일 경우 numeric(1)
               * 4. Domain을 사용하고 number 타입 + 포맷 단가일 경우 numeric(2)
               * 5. Domain을 사용하고 number 타입 + 포맷 환율일 경우 numeric(3)
               *   - 위의 포맷의 경우 런타임에서 처리한다. gridOption에 elementId를 같이 넣어 둔다.
               * 6. possibleEntry가 있는 경우 Select 콤보(일반)
               * 7. Date 타입인 경우 datetime
               * 8. 나머지 text
               * */
              gridCell.dataType = "text";
              this.convertDataTypeToDisplayType(gridCell, field);
              //elementId 넣어두기
              gridCell.elementId = field.getElement().getElementId();
              if (field.trdTableField && field.trdTableField.refTableId) {
                gridCell.refTableId = field.trdTableField.refTableId;
                gridCell.refFieldId = field.trdTableField.refFieldId;
              }
              gridCell.isDataStudioElement = true;
              gridCells.push(gridCell);
            });
            resolve(gridCells);
          }
        }
      );
    });
  };

  /**
   * Workflow EndProcess 필드 호출
   * GridLayoutTempalate 에서는 ElementComponentTab에서 WorkflowEntityFieldList를 받아서 적용하지만,
   * GridUpdatePopup의 경우 없기 때문에 자체적으로 정보를 호출하여 노출함.
   * @param {*} propsState
   * @returns {Promise}
   */
  getWorkflowFieldList = (propsState) => {
    /**
     * 1. 서비스 검색
     * 2. 서비스에서 EndProcess 검색
     * 3. EndProcess의 ReturnObject 중 일치하는 것 탐색
     * 4. 일치하는 obj의 entityFieldList 추출
     * 5. Field 정보에 Element Id만 추려서 Element 정보 호출
     * 6. entity Field와 Element 조합
     * 7. grid Cell 생성
     */
    let newGrid = UITemplateHelper.getGridTemplate(this.templateComponents);
    let gridColumOption = { ...newGrid.propertyValue.gridColumns };
    return new Promise((resolve, reject) => {
      //서비스 검색
      WorkflowService.getService(
        { serviceUid: propsState.inputValue.serviceUid },
        async (res) => {
          const workflow = res.data;
          const service = JSON.parse(workflow.serviceContent);
          //End Process 검색
          const endProcess = service.service.child.process?.find(
            (p) => p.processType === Enums.WorkflowProcessType.END_PROCESS
          );
          //return Object 중 파라미터에 맞는 Entity 검색
          if (service && endProcess.propertyValue.returnObject?.length > 0) {
            const returnEntity = endProcess.propertyValue.returnObject.find(
              (entity) =>
                entity.entityNm === propsState.inputValue.entityVariable
            );
            if (returnEntity) {
              const { entityFieldList } = returnEntity;
              if (ArrayUtils.isEmpty(entityFieldList)) return false;
              //field List에서 element Id만 뽑아서
              //Element 정보 목록 가져옴
              const elementIds = entityFieldList.map(
                (field) => field.elementId
              );
              const elementListResult =
                await ElementService.getElementListByElementIds({ elementIds });

              if (ArrayUtils.isEmpty(elementListResult.data)) return false;
              //Element Set 생성
              const ElementSet = {};
              elementListResult.data.forEach((element, index) => {
                ElementSet[element.elementId] = element;
              });
              //맞는 Element를 찾아서 entityField 안에 통으로 주입
              const gridCells = entityFieldList.map((entityField, index) => {
                let gridCell = {
                  ...gridColumOption,
                };
                const field = new Field({
                  element: ElementSet[entityField.elementId],
                });
                gridCell.compId = StringUtils.getUuid();
                gridCell.gridId = propsState.inputValue.gridId;
                gridCell.name = entityField.fieldId;
                gridCell.data = entityField.fieldId;
                gridCell.title = field.element?.elementNm || field.fieldId;
                gridCell.dataType = "text";
                this.convertDataTypeToDisplayType(gridCell, field);
                gridCell.editType =
                  entityField.option.updatableYn === "Y" ? "E" : "R";
                gridCell.required =
                  entityField.option.requiredYn === "Y" ? true : false;
                gridCell.sortOrder = index + 1;
                gridCell.elementId = field.getElement().getElementId();
                if (field.trdTableField && field.trdTableField.refTableId) {
                  gridCell.refTableId = field.trdTableField.refTableId;
                  gridCell.refFieldId = field.trdTableField.refFieldId;
                }
                return gridCell;
              });
              resolve(gridCells);
            }
          }
        }
      );
    });
  };

  /**
   * 확인 버튼 이벤트
   * @param {*} e
   */
  onClickConfirm = (e) => {
    const { headerTitle, containButton, ...gridOptions } =
      this.state.inputValue;

    if (StringUtils.isEmpty(gridOptions.gridId)) {
      return Message.alert("Grid ID는 필수 입니다.", Enums.MessageType.ERROR);
    }
    if (ArrayUtils.isEmpty(this.state.gridCells)) {
      return Message.alert("선택된 필드가 없습니다.", Enums.MessageType.ERROR);
    }
    const returnOptions = produce(gridOptions, (draft) => {
      if (!headerTitle) {
        draft.title = "";
      }
      if (!containButton) {
        draft.additionalOptions.toolbarOptions.buttons = [];
        if (draft.additionalOptions.toolbarOptions.customButton)
          draft.additionalOptions.toolbarOptions.customButton = [];
      }

      let gridCells = [...this.state.gridCells];
      const columns = gridCells.map((gc) => {
        const obj = { ...gc, gridId: gridOptions.gridId };
        return obj;
      });
      draft.columns = columns;
    });

    if (this.props.callback) {
      this.props.callback(returnOptions);
    }
  };

  /**
   * Footer render 함수
   * @returns
   */
  renderFooter = () => {
    return (
      <React.Fragment>
        <Modal.Footer.Button onClick={this.onClickConfirm}>
          확인
        </Modal.Footer.Button>
      </React.Fragment>
    );
  };

  /**
   * Title, button 등이 포함된 Grid layout을 생성
   * @returns
   */
  renderLayoutTemplatePop = () => {
    return (
      <Modal>
        <Modal.Header title="Grid Layout 설정" />
        <Modal.Body>
          {this.renderSelectDataModel()}
          <div className="row p-10">
            <div className="col2">
              <Form.Label>Grid 유형</Form.Label>
              <InputGroup>
                <USelectbox
                  type="common"
                  id="displayMode"
                  items={[
                    { id: "D", text: "조회용 Grid" },
                    { id: "E", text: "입력용 Grid" },
                  ]}
                  options={{
                    matchId: "id",
                    matchNm: "text",
                  }}
                  defaultValue={StringUtils.defaultString(
                    this.state.inputValue.displayMode,
                    "E"
                  )}
                  onChange={(e) => this.onChangeDisplayMode(e)}
                />
              </InputGroup>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {this.renderFooter(this.props.confirmCallback)}
        </Modal.Footer>
      </Modal>
    );
  };

  render() {
    return this.renderLayoutTemplatePop();
  }
}

export default GridUpdatePopup;

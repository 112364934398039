import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import SunEditor from "suneditor-react";
import { styled } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import TechnicalDesignService from "services/technicaldesign/TechnicalDesignService";
import Message from "components/common/Message";
import { Enums } from "components/builder/BuilderEnum";
import { useEffect, useState } from "react";
import ObjectUtils from "components/common/utils/ObjectUtils";
import ArrayUtils from "components/common/utils/ArrayUtils";
import produce from "immer";
import { setDesign } from "../reducer/TechnicalDesignAction";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import CommonUtils from "components/common/utils/CommonUtils";

export const ScrollDiv = styled.div`
  margin-top: 30px;
  margin-left: 200px;
  margin-right: 200px;
  margin-bottom: 50px;
  padding-bottom: 50px;
  max-height: calc(100vh - 81px);
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #ccc;
  }
`;

const TechnicalDesignOverview = () => {
  const technicalDesign = useSelector((state) => state.technicalDesign);
  const workspace = useSelector((state) => state.workspace);

  const [designMst, setDesignMst] = useState({});
  const dispatch = useDispatch();

  const priorityOptions = ["A", "B", "C"];
  const difficultyOptions = ["H", "M", "L"];
  const frequencyOptions = [
    "Anytime",
    "Daily",
    "Weekly",
    "Monthly",
    "BIWeekly",
  ];

  useEffect(() => {
    setDesignMst(technicalDesign.design);
  }, [technicalDesign]);

  /**
   * multilang text 가져오기
   * @param {*} key
   * @returns
   */
  const getMultilangText = (key) => {
    if (!ArrayUtils.isEmpty(designMst.designMultilang)) {
      return designMst.designMultilang.filter(
        (item) => item.multilangCd === key
      )[0]?.multilangText;
    } else {
      return "";
    }
  };

  /**
   * designMst 변경
   * @param {*} key
   * @param {*} value
   */
  const onChangeDesignMst = (key, value) => {
    if (ObjectUtils.isObject(designMst)) {
      const newDesignMst = produce(designMst, (draft) => {
        draft[key] = value;
      });
      setDesignMst(newDesignMst);
      dispatch(setDesign(newDesignMst));
    }
  };

  /**
   * designMultilang 변경
   * @param {*} key
   * @param {*} value
   */
  const onChangeDesignMultilang = (key, value) => {
    if (ObjectUtils.isObject(designMst)) {
      let newDesignMultilang = [];

      if (!ArrayUtils.isEmpty(designMst.designMultilang)) {
        newDesignMultilang = produce(designMst.designMultilang, (draft) => {
          draft.filter((item) => {
            if (item.multilangCd === key) {
              item.multilangText = value;
            }
            return item;
          });
        });
      } else {
        const newData = {
          multilangCd: key,
          multilangText: value,
        };
        newDesignMultilang.push(newData);
      }
      onChangeDesignMst("designMultilang", newDesignMultilang);
    }
  };

  return (
    <ScrollDiv>
      <Row>
        <Col>
          <Card style={{ backgroundColor: "rgb(237 242 250)" }}>
            <Card.Body>
              <Card.Text style={{ textAlign: "center" }}>
                Project Specification Overview
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={2}>
          <Form.Label>Project Name</Form.Label>
        </Col>
        <Col xs={10}>
          <Form.Control
            value={workspace.appNm}
            disabled
            //onChange={(e) => onChangeFormData("projectNm", e.target.value)}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={2}>
          <Form.Label>Module</Form.Label>
        </Col>
        <Col xs={4}>
          <Form.Control
            value={workspace.moduleNm}
            disabled
            //onChange={(e) => onChangeFormData("moduleNm", e.target.value)}
          />
        </Col>
        <Col xs={2}>
          <Form.Label>Sub Module</Form.Label>
        </Col>
        <Col xs={4}>
          <Form.Control
            defaultValue={getMultilangText("sub_module_nm")}
            onBlur={(e) =>
              onChangeDesignMultilang("sub_module_nm", e.target.value)
            }
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={2}>
          <Form.Label>Program ID</Form.Label>
        </Col>
        <Col xs={4}>
          <Form.Control
            defaultValue={designMst.programId}
            onBlur={(e) => onChangeDesignMst("programId", e.target.value)}
          />
        </Col>
        <Col xs={2}>
          <Form.Label>Process ID</Form.Label>
        </Col>
        <Col xs={4}>
          <Form.Control
            defaultValue={designMst.processId}
            onBlur={(e) => onChangeDesignMst("processId", e.target.value)}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={2}>
          <Form.Label>Program Name</Form.Label>
        </Col>
        <Col xs={10}>
          <Form.Control
            defaultValue={getMultilangText("program_nm")}
            onBlur={(e) =>
              onChangeDesignMultilang("program_nm", e.target.value)
            }
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={2}>
          <Form.Label>Program Overview</Form.Label>
        </Col>
        <Col xs={10}>
          <Form.Control
            as="textarea"
            defaultValue={getMultilangText("program_desc")}
            onBlur={(e) =>
              onChangeDesignMultilang("program_desc", e.target.value)
            }
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={2}>
          <Form.Label>Requester</Form.Label>
        </Col>
        <Col xs={4}>
          <Form.Control
            defaultValue={designMst.requesterId}
            disabled
            //onBlur={(e) => onChangeDesignMst("requesterId", e.target.value)}
          />
        </Col>
        <Col xs={2}>
          <Form.Label>Request Date</Form.Label>
        </Col>
        <Col xs={4}>
          <Form.Control
            disabled
            defaultValue={CommonUtils.getDate(designMst.requestDate)}
          />
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disabled
              value={designMst.requestDate}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider> */}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={2}>
          <Form.Label>Expected Development Period</Form.Label>
        </Col>
        <Col xs={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={designMst.estimatedDate}
              renderInput={(params) => <TextField {...params} size="small" />}
              onChange={(value) => onChangeDesignMst("estimatedDate", value.$d)}
            />
          </LocalizationProvider>
        </Col>
        <Col xs={2}>
          <Form.Label>Desired Completion Date</Form.Label>
        </Col>
        <Col xs={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={designMst.expectedDate}
              renderInput={(params) => <TextField {...params} size="small" />}
              onChange={(value) => onChangeDesignMst("expectedDate", value.$d)}
            />
          </LocalizationProvider>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={2}>
          <Form.Label>Developer</Form.Label>
        </Col>
        <Col xs={4}>
          <Form.Control
            defaultValue={designMst.developer}
            onBlur={(e) => onChangeDesignMst("developer", e.target.value)}
          />
        </Col>
        <Col xs={2}>
          <Form.Label>Development Completion Date</Form.Label>
        </Col>
        <Col xs={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={designMst.completionDate}
              renderInput={(params) => <TextField {...params} size="small" />}
              onChange={(value) =>
                onChangeDesignMst("completionDate", value.$d)
              }
            />
          </LocalizationProvider>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={2}>
          <Form.Label>Priority(A/B/C)</Form.Label>
        </Col>
        <Col xs={4}>
          <Form.Select
            value={designMst.priority}
            onChange={(e) => onChangeDesignMst("priority", e.target.value)}
          >
            {priorityOptions.map((priority) => {
              return (
                <option key={priority} value={priority}>
                  {priority}
                </option>
              );
            })}
          </Form.Select>
        </Col>
        <Col xs={2}>
          <Form.Label>Difficulty level(H/M/L)</Form.Label>
        </Col>
        <Col xs={4}>
          <Form.Select
            value={designMst.difficultyLevel}
            onChange={(e) =>
              onChangeDesignMst("difficultyLevel", e.target.value)
            }
          >
            {difficultyOptions.map((difficulty) => {
              return (
                <option key={difficulty} value={difficulty}>
                  {difficulty}
                </option>
              );
            })}
          </Form.Select>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={2}>
          <Form.Label>Program Type</Form.Label>
        </Col>
        <Col xs={4}>
          <Form.Control
            defaultValue={designMst.dataHandlingType}
            onBlur={(e) =>
              onChangeDesignMst("dataHandlingType", e.target.value)
            }
          />
        </Col>
        <Col xs={2}>
          <Form.Label>Reuse PGM-ID</Form.Label>
        </Col>
        <Col xs={4}>
          <Form.Control
            defaultValue={designMst.refProgramId}
            onBlur={(e) => onChangeDesignMst("refProgramId", e.target.value)}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={2}>
          <Form.Label>Frequency Of Performance</Form.Label>
        </Col>
        <Col xs={4}>
          <Form.Select
            value={designMst.execFrequency}
            onChange={(e) => onChangeDesignMst("execFrequency", e.target.value)}
          >
            {frequencyOptions.map((frequency) => {
              return (
                <option key={frequency} value={frequency}>
                  {frequency}
                </option>
              );
            })}
          </Form.Select>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Card style={{ backgroundColor: "rgb(237 242 250)" }}>
            <Card.Body>
              <Card.Text style={{ textAlign: "center" }}>
                <Form.Label>Other characteristics</Form.Label>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <SunEditor
            lang={"ko"}
            onBlur={(value) => {
              onChangeDesignMultilang("remarks", value);
            }}
            setContents={getMultilangText("remarks")}
            setDefaultStyle="font-family: 'Noto Sans KR', sans-serif;  min-height : 500px; max-height : 55vh"
            setOptions={{
              font: [
                "Noto Sans KR",
                "Arial",
                "Comic Sans MS",
                "Courier New",
                "Impact",
                "Georgia",
                "tahoma",
                "Trebuchet MS",
                "Verdana",
              ],
              fontSize: [
                8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48,
                72,
              ],
              buttonList: [
                ["font", "fontSize", "formatBlock"],
                [
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "fontColor",
                  "hiliteColor",
                ],
              ],
            }}
          />
        </Col>
      </Row>
    </ScrollDiv>
  );
};
export default TechnicalDesignOverview;

class EntityField {
  entityFieldId = null;
  entityId = null;
  physFieldNm = null;
  logFieldNm = null;
  columnNm = null;
  columnType = null;
  autoIncreamentYn = null;
  pkYn = null;
  requiredYn = null;
  uniqueYn = null;
  readonlyYn = null;
  uiDisplayType = null;
  formula = null;
  fieldOption = null;
  alias = null;
  insertDefault = null;
  updateDefault = null;
  virtualYn = null;
  insertableYn = null;
  updatableYn = null;
  refEntityId = null;
  refEntityFieldId = null;
  insertOption = null;
  updateOption = null;
  autoNumberingType = null;
  isNull = true;

  constructor(entityField) {
    if (entityField) {
      this.entityFieldId = entityField.entityFieldId;
      this.entityId = entityField.entityId;
      this.physFieldNm = entityField.physFieldNm;
      this.logFieldNm = entityField.logFieldNm;
      this.columnNm = entityField.columnNm;
      this.columnType = entityField.columnType;
      this.autoIncreamentYn = entityField.autoIncreamentYn;
      this.pkYn = entityField.pkYn;
      this.requiredYn = entityField.requiredYn;
      this.uniqueYn = entityField.uniqueYn;
      this.readonlyYn = entityField.readonlyYn;
      this.uiDisplayType = entityField.uiDisplayType;
      this.formula = entityField.formula;
      this.fieldOption = entityField.fieldOption;
      this.alias = entityField.alias;
      this.insertDefault = entityField.insertDefault;
      this.updateDefault = entityField.updateDefault;
      this.virtualYn = entityField.virtualYn;
      this.insertableYn = entityField.insertableYn;
      this.updatableYn = entityField.updatableYn;
      this.refEntityId = entityField.refEntityId;
      this.refEntityFieldId = entityField.refEntityFieldId;
      this.insertOption = entityField.insertOption;
      this.updateOption = entityField.updateOption;
      this.autoNumberingType = entityField.autoNumberingType;
      this.isNull = false;
    } else {
      this.isNull = true;
    }
  }

  getEntityFieldId() {
    return this.entityFieldId;
  }

  setEntityFieldId(value) {
    this.entityFieldId = value;
  }

  getEntityId() {
    return this.entityId;
  }

  setEntityId(value) {
    this.entityId = value;
  }

  getPhysFieldNm() {
    return this.physFieldNm;
  }

  setPhysFieldNm(value) {
    this.physFieldNm = value;
  }

  getLogFieldNm() {
    return this.logFieldNm;
  }

  setLogFieldNm(value) {
    this.logFieldNm = value;
  }

  getColumnNm() {
    return this.columnNm;
  }

  setColumnNm(value) {
    this.columnNm = value;
  }

  getColumnType() {
    return this.columnType;
  }

  setColumnType(value) {
    this.columnType = value;
  }

  getAutoIncreamentYn() {
    return this.autoIncreamentYn;
  }

  setAutoIncreamentYn(value) {
    this.autoIncreamentYn = value;
  }

  getPkYn() {
    return this.pkYn;
  }

  setPkYn(value) {
    this.pkYn = value;
  }

  getRequiredYn() {
    return this.requiredYn;
  }

  setRequiredYn(value) {
    this.requiredYn = value;
  }

  getUniqueYn() {
    return this.uniqueYn;
  }

  setUniqueYn(value) {
    this.uniqueYn = value;
  }

  getReadonlyYn() {
    return this.readonlyYn;
  }

  setReadonlyYn(value) {
    this.readonlyYn = value;
  }

  getUiDisplayType() {
    return this.uiDisplayType;
  }

  setUiDisplayType(value) {
    this.uiDisplayType = value;
  }

  getFormula() {
    return this.formula;
  }

  setFormula(value) {
    this.formula = value;
  }

  getFieldOption() {
    return this.fieldOption;
  }

  setFieldOption(value) {
    this.fieldOption = value;
  }

  getAlias() {
    return this.alias;
  }

  setAlias(value) {
    this.alias = value;
  }

  getInsertDefault() {
    return this.insertDefault;
  }

  setInsertDefault(value) {
    this.insertDefault = value;
  }

  getUpdateDefault() {
    return this.updateDefault;
  }

  setUpdateDefault(value) {
    this.updateDefault = value;
  }

  getVirtualYn() {
    return this.virtualYn;
  }

  setVirtualYn(value) {
    this.virtualYn = value;
  }

  getInsertableYn() {
    return this.insertableYn;
  }

  setInsertableYn(value) {
    this.insertableYn = value;
  }

  getUpdatableYn() {
    return this.updatableYn;
  }

  setUpdatableYn(value) {
    this.updatableYn = value;
  }

  getRefEntityId() {
    return this.refEntityId;
  }

  setRefEntityId(value) {
    this.refEntityId = value;
  }

  getRefEntityFieldId() {
    return this.refEntityFieldId;
  }

  setRefEntityFieldId(value) {
    this.refEntityFieldId = value;
  }

  getInsertOption() {
    return this.insertOption;
  }

  setInsertOption(value) {
    this.insertOption = value;
  }

  getUpdateOption() {
    return this.updateOption;
  }

  setUpdateOption(value) {
    this.updateOption = value;
  }

  getAutoNumberingType() {
    return this.autoNumberingType;
  }

  setAutoNumberingType(value) {
    this.autoNumberingType = value;
  }

  toJSON() {
    return {
      entityFieldId: this.entityFieldId,
      entityId: this.entityId,
      physFieldNm: this.physFieldNm,
      logFieldNm: this.logFieldNm,
      columnNm: this.columnNm,
      columnType: this.columnType,
      autoIncreamentYn: this.autoIncreamentYn,
      pkYn: this.pkYn,
      requiredYn: this.requiredYn,
      uniqueYn: this.uniqueYn,
      readonlyYn: this.readonlyYn,
      uiDisplayType: this.uiDisplayType,
      formula: this.formula,
      fieldOption: this.fieldOption,
      alias: this.alias,
      insertDefault: this.insertDefault,
      updateDefault: this.updateDefault,
      virtualYn: this.virtualYn,
      insertableYn: this.insertableYn,
      updatableYn: this.updatableYn,
      refEntityId: this.refEntityId,
      refEntityFieldId: this.refEntityFieldId,
      insertOption: this.insertOption,
      updateOption: this.updateOption,
      autoNumberingType: this.autoNumberingType,
    };
  }
}

export default EntityField;

import Popup from "components/common/Popup";
import MuiConfig from "components/common/config/MuiConfig";
import WijmoGrid from "components/common/element/WijmoGrid";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import StringUtils from "components/common/utils/StringUtils";
import { useEffect, useState } from "react";
import ComponentService from "services/datamodel/DataModelService";

const column = [
  {
    field: "reservedWord",
    headerName: "Reserved Words",
    align: "left",
    width: 150,
  },
  {
    field: "reservedWordNm",
    headerName: "Name",
    width: 130,
  },
  {
    field: "description",
    headerName: "Description",
    width: 350,
  },
  {
    field: "autoNumbering",
    headerName: "Auto Numbering",
    width: 150,
    renderCell: (params) => {
      if (params.autoNumberingType === "S") {
        return "Standard Numbering";
      } else if (params.autoNumberingType === "U") {
        return "User Custom Numbering";
      }
    },
  },
];

/**
 * 기본입력값 팝업
 * @param {{onChooseDefaultValue:String,id:String,dateYn:Boolean,autoNumbering:Boolean}}
 * @param {funtion} onChooseDefaultValue : 기본값 선택 이벤트 더블클릭, 클릭 후 선택 포함 : return {target : {id, value}}
 * @param {String} id : 기본값이 들어갈 인풋 아이디
 * @param {Boolean} dateYn : 날짜 여부 true : 날짜 ({currentTime})만 노출 , false : 날짜외 전부 노출 , 기본값 false
 * @param {Boolean} autoNumbering : 채번 여부 default false
 * @returns
 */
function DefaultInputPopup({
  onChooseDefaultValue,
  id,
  dateYn = false,
  autoNumbering = false,
  ...props
}) {
  const [defaultValueList, setDefaultValueList] = useState([]);
  const [selectedDV, setSelectedDV] = useState({
    target: {
      id,
      value: "",
    },
  });

  useEffect(() => {
    ComponentService.getReservedWordList({}, (res) => {
      let filtered = [];
      //날짜 포함 여부
      if (dateYn) {
        filtered = res.data.filter(
          (item) => item.reservedWord.indexOf("currentTime") > -1
        );
      } else {
        filtered = res.data.filter(
          (item) => item.reservedWord.indexOf("currentTime") === -1
        );
      }
      //채번 포함여부
      if (!autoNumbering) {
        filtered = filtered.filter((item) =>
          StringUtils.isEmpty(item.autoNumberingType)
        );
      }
      setDefaultValueList(filtered);
    });
  }, []);

  const onClickOk = (e) => {
    if (onChooseDefaultValue) {
      onChooseDefaultValue(selectedDV);
    }
    e.preventDefault();
    Popup.close();
  };

  //   const onRowDoubleClick = (data, event) => {
  //     if (onChooseDefaultValue) {
  //       onChooseDefaultValue(data.row.reservedWord);
  //     }
  //   };
  const onRowClick = (data, event) => {
    if (selectedDV.target.value === data.reservedWord) {
      //더블 클릭 느낌으로 대체
      if (onChooseDefaultValue) {
        onChooseDefaultValue(selectedDV);
        Popup.close();
      }
    } else {
      setSelectedDV({
        target: {
          ...selectedDV.target,
          value: data.reservedWord,
          autoNumberingType: data.autoNumberingType,
        },
      });
    }
  };

  return (
    <Modal>
      <Modal.Header title={"Select Default Value"} />
      <Modal.Body>
        <UModalTemplate style={{ height: "600px" }}>
          <WijmoGrid
            columns={column}
            getRowId={(row) => row.reservedWordId}
            rows={defaultValueList}
            {...MuiConfig.grid.options}
            pageSize={20}
            //   onRowDoubleClick={onRowDoubleClick}
            onRowClick={onRowClick}
            rowsPerPageOptions={[20]}
            style={{ height: `calc(60vh + 30px)` }}
          />
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onClickOk}>Select</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DefaultInputPopup;

import Api from "components/common/Api";
import JsonUtils from "components/common/utils/JsonUtils";
/**
 * UI - builder 저장 및 조회
 * **/
class TechnicalDesignService {
  static requestMapping = "/technicalDesign";

  /**
   * Technical Design 목록 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getTechnicalDesignList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/getTechnicalDesignList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 단일 Technical Design 조회
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getDesignMst(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/getDesignMst",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**

   * Design Mst 저장
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static saveDesignMst(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/saveDesignMst",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   *  Design Layout column list 가져오기
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getLayoutColumnBtn(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/getLayoutColumnBtn",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }
}
export default TechnicalDesignService;

import ArrayUtils from "components/common/utils/ArrayUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import UserUtils from "components/common/utils/UserUtils";
import produce from "immer";
import { useEffect, useRef, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import SunEditor from "suneditor-react";
import { setDesign } from "../reducer/TechnicalDesignAction";
import CommonUtils from "components/common/utils/CommonUtils";

export const ScrollDiv = styled.div`
  margin-top: 30px;
  margin-left: 200px;
  margin-right: 200px;
  margin-bottom: 50px;
  padding-bottom: 50px;
  max-height: calc(100vh - 81px);
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #ccc;
  }
`;

const TechnicalSpecification = ({ findMultilang, ...props }) => {
  const [formData, setFormData] = useState({});

  const workspace = useSelector((state) => state.workspace);
  const technicalDesign = useSelector((state) => state.technicalDesign);

  const design = useRef({});

  const dispatch = useDispatch();

  useEffect(() => {
    setDesignMultilangList();
    design.current = technicalDesign.design;
  }, [technicalDesign]);
  /**
   * Design Multilang list 을 form data에 저장
   */
  const setDesignMultilangList = () => {
    if (
      !ObjectUtils.isEmpty(technicalDesign.design) &&
      !ArrayUtils.isEmpty(technicalDesign.design.designMultilang)
    ) {
      const design = technicalDesign.design;
      const designMultilangList = technicalDesign.design.designMultilang;

      setFormData({
        moduleNm: workspace.moduleNm,
        subModuleNm: findMultilang("sub_module_nm"),
        programId: design.programId,
        programNm: findMultilang("program_nm"),
        programPurpose: findMultilang("program_purpose"),
        tdDetails: findMultilang("td_details"),
        updtUserId: design.updtUserId,
        updtDt: design.updtDt,
      });
    }
  };

  /**
   * onblur시 e.target.id 를 기준을
   * @param {*} e
   */
  const onBlurFormData = (e) => {
    const id = e.target.id;
    const data = e.target.value;
    setFormData(
      produce(formData, (draft) => {
        draft[id] = data;
      })
    );
    if (!ObjectUtils.isEmpty(design.current)) {
      const designMultilang = design.current.designMultilang.map((item) => {
        if (item.multilangCd === id) {
          item.multilangText = data;
        }
        return item;
      });
      if (!designMultilang.find((item) => item.multilangCd === id)) {
        designMultilang.push({ multilangCd: id, multilangText: data });
      }
      design.current = produce(design.current, (draft) => {
        if (id === "programId") {
          draft[id] = data;
        }
        if (!ArrayUtils.isEmpty(designMultilang)) {
          draft.designMultilang = designMultilang;
        }
      });
      dispatch(setDesign(design.current));
    }
  };
  return (
    <ScrollDiv>
      {/* 기본 내용 */}
      <Row>
        <Col>
          <Card style={{ backgroundColor: "rgb(237 242 250)" }}>
            <Card.Body>
              <Card.Text style={{ textAlign: "center" }}>
                Technical Specification
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Form className="mt-3 px-2">
        <Row>
          <Col xs={2}>
            <Form.Label>Module</Form.Label>
          </Col>
          <Col xs={4}>
            <Form.Control
              id="module_nm"
              defaultValue={formData.moduleNm}
              onBlur={onBlurFormData}
            />
          </Col>
          <Col xs={2}>
            <Form.Label>Sub Module</Form.Label>
          </Col>
          <Col xs={4}>
            <Form.Control
              id="subModule_nm"
              defaultValue={formData.subModuleNm}
              onBlur={onBlurFormData}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={2}>
            <Form.Label>Program ID</Form.Label>
          </Col>
          <Col xs={4}>
            <Form.Control
              id="programId"
              defaultValue={formData.programId}
              onBlur={onBlurFormData}
            />
          </Col>
          <Col xs={2}>
            <Form.Label>Editor</Form.Label>
          </Col>
          <Col xs={4}>
            {/* userId랑 z_user_mast_rec 테이블 데이터를 조인해서 가져오고 user_nm으로 매핑해야함 */}
            <Form.Control
              id="updtUserId"
              disabled
              defaultValue={formData.updtUserId}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={2}>
            {/* multilang 데이터를 매핑 */}
            <Form.Label>Program Name</Form.Label>
          </Col>
          <Col xs={4}>
            <Form.Control
              id="program_nm"
              defaultValue={formData.programNm}
              onBlur={onBlurFormData}
            />
          </Col>
          <Col xs={2}>
            {/* updtDt */}
            <Form.Label>Edit Date</Form.Label>
          </Col>
          <Col xs={4}>
            <Form.Control
              id="updtDt"
              disabled
              defaultValue={CommonUtils.getDate(formData.updtDt)}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={2}>
            <Form.Label>Program Purpose</Form.Label>
          </Col>
          <Col xs={10}>
            <Form.Control
              id="program_purpose"
              as={"textarea"}
              rows={5}
              defaultValue={formData.programPurpose}
              onBlur={onBlurFormData}
            />
          </Col>
        </Row>
      </Form>
      {/* 상세 기능 */}
      <Row className="mt-3">
        <Col>
          <Card style={{ backgroundColor: "rgb(237 242 250)" }}>
            <Card.Body>
              <Card.Text style={{ textAlign: "center" }}>
                Detail Spec.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <SunEditor
            id={"td_details"}
            lang={UserUtils.getLanguage().toLowerCase()}
            setContents={formData.tdDetails}
            setDefaultStyle="font-family: 'Noto Sans KR', sans-serif;  min-height : 500px; max-height : 55vh"
            setOptions={{
              font: [
                "Noto Sans KR",
                "Arial",
                "Comic Sans MS",
                "Courier New",
                "Impact",
                "Georgia",
                "tahoma",
                "Trebuchet MS",
                "Verdana",
              ],
              fontSize: [
                8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48,
                72,
              ],
              buttonList: [
                ["font", "fontSize", "formatBlock"],
                [
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "fontColor",
                  "hiliteColor",
                ],
              ],
            }}
          />
        </Col>
      </Row>
    </ScrollDiv>
  );
};

export default TechnicalSpecification;

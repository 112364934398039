import { Tooltip } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import Popup from "components/common/Popup";
import StringUtils from "components/common/utils/StringUtils";
import { memo, useContext, useEffect, useRef, useState } from "react";
import { AiOutlineClose, AiOutlineEdit } from "react-icons/ai";
import { MdError } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { NodeResizer, useEdges, useNodes } from "reactflow";
import WorkflowReduxHelper from "../editor/helper/WorkflowReduxHelper";
import useWorkflowDropEvent, {
  FLOW_DROP_TYPE,
} from "../editor/render/useWorkflowDropEvent";
import JsonUtils from "components/common/utils/JsonUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import IteratorPopup from "page/popup/workflow/IteratorPopup";
import Message from "components/common/Message";
import LoopControlKeyword from "page/popup/workflow/process/LoopControlKeyword";
import { ImLoop2 } from "react-icons/im";
import { WorkflowContext } from "page/workflow";
import { findAndChange } from "../editor/render/WorkflowRenderUtils";

/**
 * 이터레이터 노드
 * @param {data:Object,id:String,selected:Boolean}
 */
export const IteratorNode = memo(
  ({
    data: { process, theme, comment, isBundling },
    id,
    selected,
    findReferCompId,
    parentNodeId,
    isChild,
    parentNode,
    addHandler,
    onAddBundle,
    onDeleteFromBundle,
    setBreakpoint,
    removeBreakpoint,
    isTracing,
    isBreakPoint,
    inCommunication,
    breakpointType,
    breakpoints,
    debugProcess,
    addBundleControlButton,
    onClickInvalidButton,
    ...args
  }) => {
    const dispatch = useDispatch();
    const workflow = useSelector((state) => state.workflow);
    const [isDragOver, setIsDragOver] = useState(false);
    const [isComment, setIsComment] = useState(false);
    const iteratorRef = useRef();
    const {
      onDropIterator,
      onDropProcess,
      onDropService,
      onDropCondition,
      onDropCode,
    } = useWorkflowDropEvent(FLOW_DROP_TYPE.ITERATOR);
    const [isInvalid, setIsInvalid] = useState(false);
    const nodes = useNodes();
    const edges = useEdges();
    const {
      bundle: { bundlingMode },
    } = useContext(WorkflowContext); //번들링 관련

    useEffect(() => {
      //이터레이터가 주석일때 자식도 주석인지 확인
      let parentComment = false;
      if (parentNodeId) {
        const _p = nodes.find((n) => n.id === parentNodeId);
        parentComment = _p.data.comment ? true : false;
      }
      if (comment || parentComment) {
        const children = document.getElementsByClassName(`${id}_child`);
        for (const child of children) {
          child.style.opacity = 0.3;
        }
        setIsComment(true);
      } else {
        const children = document.getElementsByClassName(`${id}_child`);
        for (const child of children) {
          child.style.opacity = 1;
        }
        setIsComment(false);
      }
    }, [comment]);

    useEffect(() => {
      /**
       * 참조중인 노드가 삭제되거나 compID를 찾을 수 없는 경우 isInvalid를 활성화 한다.
       */
      const referenceCompId = process.propertyValue.editorAttr?.referenceCompId;
      if (!StringUtils.isEmpty(referenceCompId)) {
        const refNode = JsonUtils.findNode(
          workflow.output.service,
          "compId",
          referenceCompId
        );
        if (ObjectUtils.isEmpty(refNode)) {
          setIsInvalid(true);
        }
      } else {
        setIsInvalid(false);
      }
    }, [nodes]);

    /**
     * 프로세스 삭제
     * @param {*} e
     */
    const onDeleteProcess = (e) => {
      stopEvent(e);
      //주석에 있는 프로세스도 확인
      const commentChild = workflow.serviceComment.process.filter(
        (s) => s.parentNode === process.compId
      );
      const childIds = commentChild.map((c) => c.compId);
      WorkflowReduxHelper.deleteProcess(
        dispatch,
        [process.compId, ...childIds],
        workflow
      );
    };

    /**
     * 프로세스 상세 보기 및 수정
     */
    const onDoubleClick = () => {
      const callbackFnc = (data) => {
        const nodeInfo = {
          ...process,
          propertyValue: {
            ...data,
          },
        };

        let WF = JSON.parse(JSON.stringify(workflow));

        // if (
        //   process.propertyValue.iteratorVariable !== data.iteratorVariable ||
        //   process.propertyValue.iteratorNm !== data.iteratorNm
        // ) {
        //   //iterator variable 정보가 달라지면 예하 노드에도 적용한다. option 값은 처리하지 아니한다.
        //   WF = findAndChange(
        //     WF,
        //     { referenceCompId: process.compId },
        //     {
        //       entityNm: data.iteratorNm,
        //       entityVariable: data.iteratorVariable,
        //     }
        //   );
        // }

        Popup.close();
        WorkflowReduxHelper.updateNodes(dispatch, [nodeInfo], WF);
      };
      Popup.open(
        <IteratorPopup
          callbackFnc={callbackFnc}
          workflow={workflow.output}
          processType={process.processType}
          processInfo={process.propertyValue}
          nodes={nodes}
          edges={edges}
          parentNodeId={parentNodeId}
          compId={id}
          iterator={!ObjectUtils.isEmpty(parentNode) ? parentNode : null}
        />,
        {
          style: { content: { width: "50%" } },
        }
      );
    };

    /**
     * Iterator 안에 프로세스 드랍
     * @param {*} event
     * @returns
     */
    const onDrop = (event) => {
      if (isComment) {
        event.preventDefault();
        event.stopPropagation();
        return Message.alert(
          "You cannot place a process inside a comment.",
          Enums.MessageType.ERROR
        );
      }

      const reactFlowBounds = iteratorRef.current.getBoundingClientRect();
      const position = {
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      };

      const _NodeData = event.dataTransfer.getData("application/reactflow");
      if (!_NodeData) return false; //데이터가 없는 경우
      const NodeData = JSON.parse(_NodeData);
      const { type } = NodeData;

      if (
        !StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.MEMO, type) &&
        event
      ) {
        //메모를 드랍할때는 WorkflowBuilder의 onDropMemo에 이벤트버블링이 되도록 함
        event.preventDefault();
        event.stopPropagation();
      }

      if (StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.PROCESS, type)) {
        onDropProcess(position, { iterator: process, nodes, edges });
      } else if (
        StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.SERVICE, type)
      ) {
        onDropService(position, { iterator: process, nodes, edges });
      } else if (
        StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.ITERATOR, type)
      ) {
        //이터레이터가 2중첩 이상인지 확인
        const grandParentNode = nodes.find((n) => n.id === parentNodeId);
        if (grandParentNode && nodes.find((n) => n.id === grandParentNode.id)) {
          Message.alert(
            "Iterator can be nested up to a maximum of 2 levels.",
            Enums.MessageType.WARN
          );
        } else {
          onDropIterator(position, { iterator: process, nodes, edges });
        }
      } else if (
        StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.CONDITION, type)
      ) {
        onDropCondition(position, { iterator: process, nodes, edges });
      } else if (
        StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.CODE, type)
      ) {
        onDropCode(position, { iterator: process, nodes, edges });
      }
      onDragLeave();
    };

    /**
     * 드래그가 Iterator 안에 들어간 경우
     * 내부에 속안 컴포넌트들의 스타일을 강제적으로 변환함
     * @param {*} e
     */
    const onDragEnter = (e) => {
      if (e) stopEvent(e);
      if (isComment) return false;
      setIsDragOver(true);
      const children = document.getElementsByClassName(`${id}_child`);
      for (const child of children) {
        child.style.opacity = 0.1;
      }
    };
    /**
     * 드래그가 빠져나간 경우
     * @param {*} e
     */
    const onDragLeave = (e) => {
      if (e) stopEvent(e);
      if (isComment) return false;
      setIsDragOver(false);
      const children = document.getElementsByClassName(`${id}_child`);
      for (const child of children) {
        child.style.opacity = 1;
      }
    };

    /**
     * 지시자 버튼 클릭
     * debugger, break 설정
     * @param {*} e
     */
    const onClickIndicator = (event) => {
      const callbackFnc = (processInfo) => {
        //지시자는 각 이터레이터에서 x : 80, Y :80 포지션에 추가한다.
        const position = {
          x: 80,
          y: 80,
        };
        const nodeInfo = {
          position,
          compId: StringUtils.getUuid(),
          type: "process",
          processType: Enums.WorkflowProcessType.LOOP_CONTROL_KEYWORD,
          propertyValue: {
            ...processInfo,
          },
        };

        nodeInfo.parentNode = process.compId;
        //이터레이터 추가
        WorkflowReduxHelper.addProcessInIterator(
          dispatch,
          nodeInfo,
          process,
          workflow
        );
        Popup.close();
      };

      Popup.open(<LoopControlKeyword callbackFnc={callbackFnc} />, {
        style: { content: { width: "800px" } },
      });
    };

    return (
      <>
        <NodeResizer
          color="dodgeblue"
          isVisible={selected}
          minWidth={200}
          minHeight={150}
          handleStyle={{
            width: "15px",
            height: "15px",
            borderRadius: "100%",
            zIndex: 1,
          }}
        />

        <div
          className={`iterator-wrapper node-group ${
            isDragOver ? "over" : ""
          } ${theme} ${isChild ? `${parentNodeId}_child` : ""} ${
            selected ? "selected" : ""
          } ${comment && ` comment`} ${
            bundlingMode && isBundling ? " bundling" : ""
          }`}
          onDoubleClick={onDoubleClick}
          onDrop={onDrop}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDragEnd={onDragLeave}
          ref={iteratorRef}
        >
          {isComment && (
            <div className="node-comment" onDoubleClick={onDoubleClick}>
              Comment
            </div>
          )}
          {isDragOver ? (
            <>Add Process to the Iterator</>
          ) : (
            <>
              <div className="header">
                <div className="title">
                  <span>
                    <ImLoop2 size={18} />
                  </span>
                  <span>
                    {" "}
                    {process.propertyValue.processNm}
                    <Tooltip title="Edit">
                      <button
                        style={{ color: "limegreen" }}
                        onClick={onDoubleClick}
                      >
                        <AiOutlineEdit size={20} />
                      </button>
                    </Tooltip>
                  </span>
                </div>

                {addBundleControlButton(
                  <div>
                    {isInvalid && (
                      <Tooltip
                        placement="top"
                        title={
                          <span style={{ fontSize: "12px" }}>
                            Reference Target is not Found <br />
                            <span style={{ fontWeight: "bold" }}>
                              Please reassign the Input Entity or Output Entity.
                              <br />
                            </span>
                          </span>
                        }
                      >
                        <button
                          className="blink"
                          onClick={onClickInvalidButton}
                        >
                          <MdError size={25} color="tomato" />
                        </button>
                      </Tooltip>
                    )}
                    <button className="indicator" onClick={onClickIndicator}>
                      + Loop Control
                    </button>
                    <button onClick={onDeleteProcess}>
                      <AiOutlineClose size={20} />
                    </button>
                  </div>
                )}
              </div>
              <div className="option">
                Loop Condition : {process.propertyValue.option}
              </div>
            </>
          )}
          {addHandler()}
        </div>
      </>
    );
  }
);

import StringUtils from "components/common/utils/StringUtils";
import React, { Suspense, lazy, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import WorkflowReduxHelper from "../helper/WorkflowReduxHelper";
import Popup from "components/common/Popup";
import { AppContext } from "components/common/AppContextProvider";
import { ModalWidth } from "../../WorkflowBuilder";
import ProcessPopup from "page/popup/workflow/ProcessPopup";
import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import WorkflowListPopup from "page/popup/workflow/WorkflowListPopup";
import IteratorPopup from "page/popup/workflow/IteratorPopup";
import CodePopup from "page/popup/workflow/CodePopup";
import ServiceModal from "page/popup/workflow/ServicePopup";
import { getServiceInOutputEntity } from "./WorkflowRenderUtils";
import produce from "immer";
import ConditionPopup from "page/popup/workflow/ConditionPopup";
import ObjectUtils from "components/common/utils/ObjectUtils";

export const FLOW_DROP_TYPE = {
  REACT_FLOW: "reactFlow",
  ITERATOR: "Iterator",
  BUNDLE: "bundle",
};

/**
 * Workflow에서 드랍 이벤트를 HOOK으로 변경하였습니다.
 * 드랍이벤트가 Builder에 넣을때, Iterator에 넣을때가 각각 다르기 떄문에 아래와 같이
 * 별도 HOOK으로 만들어저 저장
 * @param {*} type
 * @returns
 */
function useWorkflowDropEvent(type = FLOW_DROP_TYPE.REACT_FLOW) {
  const dispatch = useDispatch();
  const workflow = useSelector((state) => state.workflow);
  const workspace = useSelector((state) => state.workspace);
  const {
    connection: { openPopup: openConnectionPopup },
    code: { getCodeList },
  } = useContext(AppContext);

  /**
   * 프로세스 드랍
   * @param position
   * @param {{iterator:Object,nodes:[],edges:[]}} 이터레이터 및 기타 컨피그
   */
  const onDropProcess = useCallback(
    (position, { iterator, nodes, edges, bundle }) => {
      const options = {
        style: {
          content: {
            width: "50%",
          },
        },
      };

      const onSelectProcess = (body) => {
        const callbackFnc = (callbackData) => {
          //노드에 추가
          const nodeInfo = {
            position,
            compId: StringUtils.getUuid(),
            type: "process",
            processType: body.processId,
            propertyValue: {
              ...callbackData,
            },
          };
          if (StringUtils.equalsIgnoreCase(type, FLOW_DROP_TYPE.REACT_FLOW)) {
            let WF = JSON.parse(JSON.stringify(workflow));
            if (!ObjectUtils.isEmpty(bundle)) {
              //번들에 추가
              const bundleIndex = WF.output.bundle.findIndex(
                (b) => b.compId === bundle.compId
              );
              if (bundleIndex > -1) {
                WF.output.bundle[bundleIndex].propertyValue.nodeList.push({
                  bundleCompId: nodeInfo.compId,
                });
              }
            }
            WorkflowReduxHelper.addProcess(dispatch, nodeInfo, WF);
          } else if (
            StringUtils.equalsIgnoreCase(type, FLOW_DROP_TYPE.ITERATOR)
          ) {
            nodeInfo.parentNode = iterator.compId;
            //이터레이터 추가
            WorkflowReduxHelper.addProcessInIterator(
              dispatch,
              nodeInfo,
              iterator,
              workflow
            );
          }

          Popup.close();
        };

        Popup.close();
        const ProcessDetailPopup = lazy(() =>
          import("page/popup/workflow/process/" + body.processId)
        );

        const popupWidth = ModalWidth[body.processId] || ModalWidth.other;

        Popup.open(
          <Suspense fallback={<div></div>}>
            <ProcessDetailPopup
              workspace={workspace}
              connectionPopupOpen={openConnectionPopup}
              callbackFnc={callbackFnc}
              operatorList={getCodeList("Z0026")}
              workflow={workflow.output}
              processType={body.processId}
              iterator={iterator}
              nodes={nodes}
              edges={edges}
            />
          </Suspense>,
          {
            keyDownEvent: false,
            style: {
              content: {
                width: popupWidth,
                left: `calc( 50% - (${popupWidth}) / 2)`,
                margin: "0",
              },
            },
          }
        );
      };

      Popup.open(<ProcessPopup callbackFnc={onSelectProcess} />, options);
    },
    [workflow]
  );

  /**
   * 서비스 드랍
   * @param {*} position
   * @param {{iterator:Object,nodes:[],edges:[]}} 이터레이터 및 기타 컨피그
   */
  const onDropService = (position, { iterator, nodes, edges, bundle }) => {
    /**
     * 선택된 서비스의 데이터가 ProcessInfo로 들어오면 팝업 오픈
     * @param {*} processInfo
     */
    const OpenServiceNodeModal = (processInfo) => {
      const callbackFnc = (data) => {
        if (
          workflow.serviceInfo.serviceUid &&
          data.serviceUid === workflow.serviceInfo.serviceUid
        ) {
          return Message.alert("Same Service", Enums.MessageType.WARN);
        }

        const nodeInfo = {
          compId: StringUtils.getUuid(),
          position,
          type: Enums.WorkflowNodeType.SERVICE,
          processType: Enums.WorkflowNodeType.SERVICE,
          propertyValue: {
            ...data,
          },
        };
        Popup.close();
        if (iterator) {
          nodeInfo.parentNode = iterator.compId;
          WorkflowReduxHelper.addProcessInIterator(
            dispatch,
            nodeInfo,
            iterator,
            workflow
          );
        } else {
          let WF = JSON.parse(JSON.stringify(workflow));
          if (!ObjectUtils.isEmpty(bundle)) {
            //번들에 추가
            const bundleIndex = WF.output.bundle.findIndex(
              (b) => b.compId === bundle.compId
            );
            if (bundleIndex > -1) {
              WF.output.bundle[bundleIndex].propertyValue.nodeList.push({
                bundleCompId: nodeInfo.compId,
              });
            }
          }
          WorkflowReduxHelper.addProcess(dispatch, nodeInfo, WF);
        }
      };

      Popup.open(
        <ServiceModal
          callbackFnc={callbackFnc}
          workspace={workspace}
          operatorList={getCodeList("Z0026")}
          workflow={workflow}
          processType={"service"}
          iterator={iterator}
          nodes={nodes}
          edges={edges}
          processInfo={processInfo}
        />,
        {
          style: {
            content: {
              width: "55%",
            },
          },
        }
      );
    };

    /**
     * 서비스 선택후 서비스 데이터를 정리하여 호출
     * @param {*} service
     * @returns
     */
    const serviceCallbackFnc = (service) => {
      if (service.serviceUid === workflow.serviceInfo.serviceUid)
        return Message.alert(
          "Cannot select the same service.",
          Enums.MessageType.WARN
        );
      Popup.close();
      OpenServiceNodeModal(service);
    };

    Popup.open(
      <WorkflowListPopup
        callbackFnc={serviceCallbackFnc}
        workspace={workspace}
      />,
      {
        style: {
          content: {
            width: "1200px",
          },
        },
      }
    );
  };

  /**
   * Iterator 드랍
   * @param {*} position
   * @param {{iterator:Object,nodes:[],edges:[]}} 이터레이터 및 기타 컨피그
   */
  const onDropIterator = (position, { iterator, nodes, edges, bundle }) => {
    const callbackFnc = (data) => {
      const nodeInfo = {
        position,
        compId: StringUtils.getUuid(),
        type: Enums.WorkflowNodeType.ITERATOR,
        processType: Enums.WorkflowNodeType.ITERATOR,
        style: { width: 760, height: 473 },
        child: {
          process: [],
          connector: [],
        },
        propertyValue: {
          ...data,
        },
      };
      Popup.close();
      if (iterator) {
        //이터레이터 추가
        nodeInfo.style.width = iterator.style.width / 2;
        nodeInfo.style.height = iterator.style.height / 2;
        nodeInfo.parentNode = iterator.compId;
        WorkflowReduxHelper.addProcessInIterator(
          dispatch,
          nodeInfo,
          iterator,
          workflow
        );
      } else {
        let WF = JSON.parse(JSON.stringify(workflow));
        if (!ObjectUtils.isEmpty(bundle)) {
          //번들에 추가
          const bundleIndex = WF.output.bundle.findIndex(
            (b) => b.compId === bundle.compId
          );
          if (bundleIndex > -1) {
            WF.output.bundle[bundleIndex].propertyValue.nodeList.push({
              bundleCompId: nodeInfo.compId,
            });
          }
        }
        WorkflowReduxHelper.addProcess(dispatch, nodeInfo, WF);
      }
    };
    Popup.open(
      <IteratorPopup
        callbackFnc={callbackFnc}
        workflow={workflow.output}
        processType={Enums.WorkflowNodeType.ITERATOR}
        nodes={nodes}
        edges={edges}
        iterator={iterator}
      />,
      {
        style: { content: { width: "900px" } },
      }
    );
  };

  /**
   * Condition 드랍
   * @param {*} position
   * @param {{iterator:Object,nodes:[],edges:[]}} 이터레이터 및 기타 컨피그
   */
  const onDropCondition = (position, { iterator, nodes, edges, bundle }) => {
    const callbackFnc = (callbackData) => {
      //노드에 추가
      const nodeInfo = {
        position,
        compId: StringUtils.getUuid(),
        type: Enums.WorkflowNodeType.CONDITION,
        processType: Enums.WorkflowProcessType.CONDITION,
        propertyValue: {
          ...callbackData,
        },
      };
      if (StringUtils.equalsIgnoreCase(type, FLOW_DROP_TYPE.REACT_FLOW)) {
        let WF = JSON.parse(JSON.stringify(workflow));
        if (!ObjectUtils.isEmpty(bundle)) {
          //번들에 추가
          const bundleIndex = WF.output.bundle.findIndex(
            (b) => b.compId === bundle.compId
          );
          if (bundleIndex > -1) {
            WF.output.bundle[bundleIndex].propertyValue.nodeList.push({
              bundleCompId: nodeInfo.compId,
            });
          }
        }
        WorkflowReduxHelper.addProcess(dispatch, nodeInfo, WF);
      } else if (StringUtils.equalsIgnoreCase(type, FLOW_DROP_TYPE.ITERATOR)) {
        nodeInfo.parentNode = iterator.compId;
        //이터레이터 추가
        WorkflowReduxHelper.addProcessInIterator(
          dispatch,
          nodeInfo,
          iterator,
          workflow
        );
      }

      Popup.close();
    };

    Popup.open(
      <ConditionPopup
        callbackFnc={callbackFnc}
        workflow={workflow.output}
        processType={Enums.WorkflowProcessType.CONDITION}
        nodes={nodes}
        edges={edges}
        iterator={iterator}
      />,
      {
        style: { content: { width: "900px" } },
      }
    );
  };

  /**
   * 코드 프로세스 드랍
   * @param {*} position
   * @param {{iterator:Object,nodes:[],edges:[]}} 이터레이터 및 기타 컨피그
   */
  const onDropCode = (position, { iterator, nodes, edges, bundle }) => {
    const callbackFnc = (callbackData) => {
      //노드에 추가
      const nodeInfo = {
        position,
        compId: StringUtils.getUuid(),
        type: Enums.WorkflowNodeType.CODE,
        processType: Enums.WorkflowNodeType.CODE,
        propertyValue: {
          ...callbackData,
        },
      };
      if (StringUtils.equalsIgnoreCase(type, FLOW_DROP_TYPE.REACT_FLOW)) {
        let WF = JSON.parse(JSON.stringify(workflow));
        if (!ObjectUtils.isEmpty(bundle)) {
          //번들에 추가
          const bundleIndex = WF.output.bundle.findIndex(
            (b) => b.compId === bundle.compId
          );
          if (bundleIndex > -1) {
            WF.output.bundle[bundleIndex].propertyValue.nodeList.push({
              bundleCompId: nodeInfo.compId,
            });
          }
        }
        WorkflowReduxHelper.addProcess(dispatch, nodeInfo, WF);
      } else if (StringUtils.equalsIgnoreCase(type, FLOW_DROP_TYPE.ITERATOR)) {
        nodeInfo.parentNode = iterator.compId;
        //이터레이터 추가
        WorkflowReduxHelper.addProcessInIterator(
          dispatch,
          nodeInfo,
          iterator,
          workflow
        );
      }

      Popup.close();
    };
    Popup.open(
      <CodePopup
        callbackFnc={callbackFnc}
        workflow={workflow.output}
        processType={Enums.WorkflowNodeType.CODE}
        nodes={nodes}
        edges={edges}
        iterator={iterator}
      />,
      {
        keyDownEvent: false,
        style: { content: { width: "900px" } },
      }
    );
  };

  return {
    onDropService,
    onDropProcess,
    onDropIterator,
    onDropCondition,
    onDropCode,
  };
}

export default useWorkflowDropEvent;

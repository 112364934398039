/*!
 * Builder enums for react v.17
 *
 * Builder에서 사용하는 enumeration은 여기에 정의 해주세요.
 *
 *   Author: Bizentro
 *   Date: 2021-04
 */

//import { MOBILE } from "blockly/core/utils/useragent";
import JsonUtils from "components/common/utils/JsonUtils";
import StringUtils from "components/common/utils/StringUtils";
import { BsDatabaseFillDown } from "react-icons/bs";
import { FaBuffer, FaServer, FaTools } from "react-icons/fa";

export const BuilderType = {
  UI: "U",
  MOBILE: "M",
};

//Program 유형
export const ProgramType = {
  MENU_PROGRAM: "M",
  TAB_PROGRAM: "T",
  POPUP_PROGRAM: "P",
  DASHBOARD_PROGRAM: "D",
  LOGIN_PROGRAM: "L",
};

//Mobile Program 유형
export const MobileProgramType = {
  MENU_PROGRAM: "M",
  POPUP_PROGRAM: "P",
  DASHBOARD_PROGRAM: "D",
  LOGIN_PROGRAM: "L",
  TOP_NAV: "T",
};

//App 유형
export const AppType = {
  UNIERP: "E",
  LEGACY: "L",
  TEST: "T",
};

//Form type
export const FormType = {
  SEARCH: "R",
  SAVE: "S",
};
//UI Editor component 유형
export const ComponentType = {
  SIDEBAR_ITEM: "sidebarItem",
  SERVICE: "service",
  ROW: "row",
  COLUMN: "column",
  COMPONENT: "component",
  CONTAINER: "container",
  FORM: "form",
  GRID: "grid",
  GRID_COLUMN: "grid.column",
  GRID_HEADER: "grid.header",
  GRID_CELL: "grid.cell",
  TAB_CONTAINER: "tabContainer",
  TAB: "tab",
  TREE: "tree",
  STEP_CONTAINER: "stepContainer",
  STEP: "step",
  LAYOUT: "layout",
  TEMPLATE: "template",
  PAGE: "page",
  FOOTER: "footer",
  SPACE: "space",
  WIDGET_CONTAINER: "widgetContainer",
  WIDGET_TEMPLATE: "widgetTemplate",
  LOGIN_TEMPLATE: "loginTemplate",
  FILTER: "filter",
};

//Entity Editor component 유형
export const EntityComponentType = {
  DATA_MODEL: "dataModel",
  TABLE: "table",
  ENTITY: "entity",
  ENTITY_FIELD: "entityField",
};

//[Editor] Template에 사용되는 Component 목록
export const TemplateList = [
  { type: ComponentType.ROW },
  { type: ComponentType.COLUMN },
  { type: ComponentType.COMPONENT, class: "form/Input" },
  { type: ComponentType.COMPONENT, class: "form/Checkbox" },
  { type: ComponentType.COMPONENT, class: "form/Button" },
  { type: ComponentType.COMPONENT, class: "form/Text" },
  { type: ComponentType.COMPONENT, class: "form/Image" },
  { type: ComponentType.COMPONENT, class: "form/Select" },
  { type: ComponentType.COMPONENT, class: "misc/Hyperlink" },
  { type: ComponentType.COMPONENT, class: "misc/Heading" },
  { type: ComponentType.COMPONENT, class: "misc/HtmlTag" },
  { type: ComponentType.COMPONENT, class: "widget/chart/AMChart3Component" },
  { type: ComponentType.FORM },
  { type: ComponentType.GRID },
  { type: ComponentType.TAB_CONTAINER },
  { type: ComponentType.STEP_CONTAINER },
  { type: ComponentType.WIDGET_CONTAINER },
  { type: ComponentType.LOGIN_TEMPLATE },
  { type: ComponentType.PAGE },
  { type: ComponentType.CONTAINER, class: "layout/Container" },
  { type: ComponentType.CONTAINER, class: "layout/Block" },
  { type: ComponentType.CONTAINER, class: "layout/InputGroup" },
];

//Component(Layout)별 Drop 허용 Component 정의
export const ComponentDropAccept = {
  [ComponentType.PAGE]: [
    ComponentType.SIDEBAR_ITEM,

    ComponentType.COMPONENT,
    ComponentType.ROW,
    ComponentType.COLUMN,
    ComponentType.CONTAINER,
    ComponentType.FORM,
    ComponentType.GRID,
    ComponentType.TAB_CONTAINER,
    ComponentType.STEP_CONTAINER,
    ComponentType.WIDGET_CONTAINER,
    ComponentType.TREE,
  ],
  [ComponentType.FOOTER]: [
    ComponentType.SIDEBAR_ITEM,

    ComponentType.COMPONENT,
    ComponentType.ROW,
    ComponentType.COLUMN,
    ComponentType.CONTAINER,
    ComponentType.TREE,
  ],
  [ComponentType.FILTER]: [
    ComponentType.SIDEBAR_ITEM,

    ComponentType.COMPONENT,
    ComponentType.ROW,
    ComponentType.COLUMN,
    ComponentType.CONTAINER,
    ComponentType.TREE,
  ],
  [ComponentType.ROW]: [
    ComponentType.SIDEBAR_ITEM,

    ComponentType.COMPONENT,
    ComponentType.COLUMN,
    ComponentType.CONTAINER,
    ComponentType.FORM,
    ComponentType.GRID,
    ComponentType.TAB_CONTAINER,
    ComponentType.STEP_CONTAINER,
    ComponentType.WIDGET_CONTAINER,
    ComponentType.TREE,
  ],
  [ComponentType.COLUMN]: [
    ComponentType.SIDEBAR_ITEM,

    ComponentType.COMPONENT,
    ComponentType.ROW,
    ComponentType.CONTAINER,
    ComponentType.FORM,
    ComponentType.GRID,
    ComponentType.TAB_CONTAINER,
    ComponentType.STEP_CONTAINER,
    ComponentType.WIDGET_CONTAINER,
    ComponentType.TREE,
  ],
  [ComponentType.CONTAINER]: [
    ComponentType.SIDEBAR_ITEM,

    ComponentType.COMPONENT,
    ComponentType.CONTAINER,
    ComponentType.ROW,
    ComponentType.FORM,
    ComponentType.GRID,
    ComponentType.TAB_CONTAINER,
    ComponentType.STEP_CONTAINER,
    ComponentType.WIDGET_CONTAINER,
    ComponentType.TREE,
  ],
  [ComponentType.FORM]: [
    ComponentType.SIDEBAR_ITEM,

    ComponentType.COMPONENT,
    ComponentType.ROW,
    ComponentType.CONTAINER,
    ComponentType.GRID,
    ComponentType.TAB_CONTAINER,
    ComponentType.STEP_CONTAINER,
    ComponentType.WIDGET_CONTAINER,
    ComponentType.TREE,
  ],
  [ComponentType.GRID]: [ComponentType.GRID_COLUMN],
  [EntityComponentType.DATA_MODEL]: [
    EntityComponentType.TABLE,
    EntityComponentType.ENTITY,
  ],
};

/**
 * 동적으로 Dnd Accepkey를 생성하는 함수
 * Data Studio isDataStudioElement true 인경우 acceptType에 indentifier 추가
 * @param {*} acceptKey
 * @param {*} dynamicAcceptKey
 * @returns
 */
export const getComponentDropAccept = (
  acceptKey,
  { propertyValue, location, compId, output }
) => {
  let accept = ComponentDropAccept[acceptKey];

  if (StringUtils.equalsIgnoreCase(location, ComponentType.FORM)) {
    if (propertyValue && propertyValue.isDataStudioElement) {
      if (propertyValue.identifier) {
        const { identifier } = propertyValue;
        accept = [...accept, String(identifier)];
      }
    }
  } else {
    //로케이션이 폼이 아닐때 , Form의 identifier를 찾아줘야 한다.
    const parentsForm = JsonUtils.findParentsFormByCompId(output, compId);
    //부모 FORM이 있으면 AcceptType에 identifier 추가해주기
    if (parentsForm) {
      const { propertyValue } = parentsForm;
      if (propertyValue && propertyValue.isDataStudioElement) {
        if (propertyValue.identifier) {
          const { identifier } = propertyValue;
          accept = [...accept, String(identifier)];
        }
      }
    }
  }

  return accept;
};

export const EditMode = {
  PREVIEW: "preview",
  EDITOR: "editor",
};

export const BuilderPath = {
  GRAPH: "/graph",
  SETTING: "/setting",
  ADMIN_SETTING: "/admin/setting",
  LOGIN: "/user/Login",
  USER: {
    MAIN: "/user",
    LIST: "list/:appId",
    DETAIL: "detail/:appId/:userMastRecId",
    // USER_MAIN: "main",
    MY_INFO: "myInfo",
  },

  UI: {
    MAIN: "/ui",
    PREVIEW: "preview",
    EDITOR: "editor",
    LIST: "list",
    HISTORY: "history",
    HISTORY_DETAIL: "history/:programId/:historyMstId",
    HISTORY_COMPARE: "history/:programId/:historyMstId/compare",
  },
  MOBILE: {
    MAIN: "/mobile",
    LAYOUT_SETTING: "LayoutSetting",
    LOGIN_SETTING: "LoginSetting",
    MAIN_BOARD_SETTING: "MainBoardSetting",
    PREVIEW: "preview",
    EDITOR: "editor",
    LIST: "list",
    HISTORY: "history",
    HISTORY_DETAIL: "history/:programId/:historyMstId",
    HISTORY_COMPARE: "history/:programId/:historyMstId/compare",
  },
  ENTITY: {
    MAIN: "/entity",
    LIST: "list",
    HISTORY: "history",
    HISTORY_DETAIL: "history/:dataModelId/:historyMstId",
    CREATE: "create",
    EDITOR: "editor",
    CHOICE: "choice",
    PATCH: "patch",
  },
  WORKFLOW: {
    MAIN: "/workflow",
    BUILDER: "builder",
    LIST: "list",
    HISTORY: "history",
    HISTORY_DETAIL: "history/:serviceId/:serviceUid/:historyMstId",
  },
  EVENT_HANDLER: {
    MAIN: "/eventhandler",
    BUILDER: "builder",
    LIST: "list",
  },
  ERD: {
    MAIN: "/erd",
    BUILDER: "builder",
    LIST: "list",
  },
  PROGRAM_DESIGN: {
    MAIN: "/technicalDesign",
    BUILDER: "builder",
    LIST: "list",
  },
  ADMIN: {
    MAIN: "/admin",
    SETTING: "setting",
  },
};

export const MessageType = {
  INFO: "info",
  ERROR: "error",
  SUCCESS: "success",
  WARN: "warning",
};

export const ConfirmLabel = {
  YES: "Yes",
  CONFIRM: "Confirm",
  NO: "No",
  CANCEL: "Cancel",
};

export const Style = {
  FOOTER_HEIGHT: 40,
  HEADER_HEIGHT: 50,
  EDITOR_TAB_HEIGHT: 31,
  LABEL_DEFAULT: 3,
};

/**
 * Workflow  서비스 유형
 */
const WorkflowProcessType = {
  CALL_STORED_PROCEDURE: "CallStoredProcedure",
  DATA_AGGREGATION: "DataAggregation",
  DELETE_ENTITY: "DeleteEntity",
  ENTITY_DEFINITION: "EntityDefinition",
  ENTITY_VALIDATION: "EntityValidation",
  GET_CONFIG: "GetConfig",
  GET_MINOR: "GetMinor",
  MESSAGE: "Message",
  NIFI_CONNECTOR: "NIFIConnector",
  PUT_ENTITY: "PutEntity",
  READ_FILE: "ReadFile",
  SAVE_ENTITY: "SaveEntity",
  SELECT_ENTITY: "SelectEntity",
  SELECT_ENTITY_BY_QUERY: "SelectEntityByQuery",
  SEND_MAIL: "SendMail",
  SQL_QUERY_EXECUTION: "SQLQueryExecution",
  UNIERP_CONNECTOR: "UNIERPConnector",
  REST_API_CONNECTOR: "RestAPIConnector",
  STRING_TO_JSON: "StringToJson",
  WRITE_FILE: "WriteFile",
  WRITE_LOG: "WriteLog",
  SERVICE: "service",
  LOOP_CONTROL_KEYWORD: "LoopControlKeyword",
  CONDITION: "Condition",
  START_PROCESS: "StartProcess",
  END_PROCESS: "EndProcess",
};

/**
 * Workflow Node 유형
 */
const WorkflowNodeType = {
  PROCESS: "process",
  SERVICE: "service",
  ITERATOR: "iterator",
  CONDITION: "condition",
  BUNDLE: "bundle",
  CODE: "code",
  CONNECTOR: "connector",
  NO_CONNECTOR: "noConnect",
  MEMO: "serviceMemo",
  PROCESS_EDGE: "processEdge",
};

export const ConfigurationDefine = {
  SHORT_KEY_SET: "ShortKeySet",
  GENERAL: "General",
  CONNECTION: "Connection",
  WORKSPACE: "preferWorkspaceList",
  AUTO_PATH: "AutoPath",
};

export const ShortKeyDefine = {
  NEW_TEMP: "newTemplate",
  STAND_TEMP: "standardTemplate",
  SHARE_TEMP: "sharedTemplate",
  LOAD: "load",
  PREVIEW: "preview",
  DOWNLOAD: "download",
  NEW_CON: "newConnection",
  SAVE: "save",
  SAVEAS: "saveAs",
  DELETE: "delete",
  DOCUMENT: "document",
  LOGOUT: "logout",
  MY_INFO: "myInfo",
  SETTING: "setting",
  UNDO: "undo",
  REDO: "redo",
  CUT: "cut",
  COPY: "copy",
  PASTE: "paste",
  DEPLOY: "deploy",
  SEARCH: "search",
};

export const Numbering = {
  STANDARD: "S",
  USER_DEF: "U",
};
export const LocalStorageName = {
  WORKSPACE_HISTORY: "workspaceHistory",
  EDITOR_THEME: "DMEditorTheme",
  AUTO_LOGIN_ID: "autoLoginID",
  PROGRAM_HISTORY: "programHistory",
  DATAMODEL_HISTORY: "datamodelHistory",
  WORKFLOW_HISTORY: "workflowHistory",
  WORKFLOW_AUTOSAVE: "workflowAutosave",
  WORKFLOW_AUTO_DEPLOY: "workflowAutoDeploy",
  PROCEDURE_WARN: "ProcedureWarn",
  EVENT_HISTORY: "eventHistory",
  EVENT_AUTO_SAVE: "eventAutoSave",
  ERD_HISTORY: "erdHistory",
  STORAGE_INIIALIZE: "storageInitialize",
  AUTOMATION: "automation",
  WORKSPACE: "workspace",
};

//Entity Editor component 유형
export const WidgetType = {
  FORM: "form",
  TEXT: "text",
  NUMERIC_METRIC: "numeric",
  LIST: "list",
  CHART: "chart",
};

export const DefaultWhoColumns = [
  "CO_CD",
  "TENANT_ID",
  "UPDT_USER_ID",
  "INSRT_USER_ID",
];

export const DateWhoColumns = ["INSRT_DT", "UPDT_DT"];

export const WhoColumns = [
  ...DefaultWhoColumns,
  ...DateWhoColumns,
  "PGM_ID",
  "SP_NM",
  "IP_ADDR",
  "CLIENT_ID",
];

//Format 유형
export const FormatType = {
  NUMERIC: "NUMERIC",
  CURRENCY: "CURRENCY",
};

//이벤트 빌더 카테고리 아이콘
export const EventCategoryIconEnums = {
  2: FaBuffer, //UI component
  3: FaTools, //Utils
  4: BsDatabaseFillDown, //Data 조회
  5: FaServer, //System
};

//이벤트 핸들러 Type에 쓰이는 이벤트 코드
export const EventHandlerEventType = {
  beforeMsgCd: "before", //전처리
  BEFORE_SUBMIT: "before",
  beforeSubmit: "before",
  beforeChange: "before",
  beforeComboCall: "before",
  afterMsgCd: "after", //후처리
  AFTER_SUBMIT: "after",
  afterSubmit: "after",
  afterChange: "after",
  afterComboCall: "after",
  afterDataLoad: "load", //그리드 조회 후 실행
  onLoad: "load", //페이지 로드
  AFTER_DATA_LOAD: "load",
  USR_EVENT_FUNCTION: "userFnc", // 사용자 정의 함수
};

export const ErdType = {
  MEMO: "memo",
  TABLE: "table",
  CONNECTOR: "connector",
  AREA_TEMPLATE: "areaTemplate",
};

/**
 * 연결 유형
 */
export const ConnectionType = {
  PROXY: "proxy",
  DIRECT: "direct",
};

export const apiPrefix = {
  PROGRAM: "/program",
  DATA_MODEL: "/datamodel",
  EVENT_HANDLER: "/eventhandler",
  WORKFLOW: "/workflow",
};

//Data Type 분류
export const CaseType = {
  String: "string",
  Number: "number",
  Decimal: "decimal",
  Date: "date",
};

//Element Data Type 분류
const definitionCase = {
  [CaseType.String]: ["char", "nChar", "varChar"],
  [CaseType.Number]: ["integer", "smallInt", "bigInt"],
  [CaseType.Decimal]: ["real", "float", "double", "decimal"],
  [CaseType.Date]: ["date", "time", "timeStamp"],
};

/**
 * Case 유형 리턴 함수
 * @param {*} dataType
 * @returns
 */
export const getDataCase = (dataType) => {
  if (!dataType) return null;
  let currentDataCase;
  Object.keys(definitionCase).forEach((key) => {
    definitionCase[key].forEach((element) => {
      if (StringUtils.equalsIgnoreCase(dataType, element)) {
        currentDataCase = key;
        return;
      }
    });
  });
  return currentDataCase;
};

export const Enums = {
  BuilderType,
  AppType,
  BuilderType,
  ProgramType,
  MobileProgramType,
  FormType,
  ComponentType,
  EntityComponentType,
  TemplateList,
  ComponentDropAccept,
  EditMode,
  BuilderPath,
  MessageType,
  ConfirmLabel,
  LocalStorageName,
  Style,
  ConfigurationDefine,
  ShortKeyDefine,
  Numbering,
  WidgetType,
  WorkflowNodeType,
  WorkflowProcessType,
  WhoColumns,
  EventCategoryIconEnums,
  EventHandlerEventType,
  ErdType,
  ConnectionType,
  apiPrefix,
  CaseType,
  getDataCase,
};

export const EventGlobalVariables = [
  {
    argsVariable: "$pageObject",
    argsName: "Page Global Object",
    argsType: "Object",
    argsDesc:
      "The Global Variables used on this page are composed of key and value (Object) pairs. " +
      "<br> <span class='code-example'>$pageObject Example : </span>" +
      "<br>    <code>$PageObject['checkedRows'] = SAVEGrid.getCheckedRows();" +
      "<br>$PageObject['userFnc'] = function(code, type) {" +
      "<br>    ...." +
      "<br>}" +
      '<br>$PageObject[\'userFnc\'].call(this,"A001","FType");</code>',
    argsVariableFlag: "G",
  },
  {
    argsVariable: "variable",
    argsName: "Basic Info of the Current User",
    argsType: "Object",
    argsDesc:
      "You can check the basic information of the current user." +
      "<br> <span class='code-example'>variable Example : </span>" +
      '<br><code>  SaveForm.val("userName") = variable.userName; </code> ' +
      "<ol class='example-list'>" +
      "<li>coCd : User Company Code</li>" +
      "<li>coNm : User Company Name</li>" +
      "<li>dateFormat : Date Format</li>" +
      "<li>monthFormat : Month Format</li>" +
      "<li>currency : Default Currency Code</li>" +
      "<li>userId : User ID</li>" +
      "<li>userId : User ID</li>" +
      "<li>userName : User Name</li>" +
      "</ul>" +
      "",
    argsVariableFlag: "G",
  },
];

import React, { useEffect, useState } from "react";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";

import DataModelService from "services/datamodel/DataModelService";
import CodeService from "services/common/CodeService";
import { TextField as MInput } from "@mui/material";
import { StringUtils } from "components/common/utils/CommonUtils";

import { Button } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import ProgramService from "services/ui/ProgramService";
import WijmoGrid from "components/common/element/WijmoGrid";
import { AiOutlineClose } from "react-icons/ai";

const DataModelPopup = (props) => {
  const [gridData, setGridData] = useState([]);

  const [inputs, setInputs] = useState({
    ...props.workspace.Info,
    description: "",
    dataModelNm: "",
    entity: "",
    dataModelId: null,
  });

  const onChange = (event) => {
    const fieldId = StringUtils.defaultString(
      event.target.name,
      event.target.id
    );
    setInputs({
      ...inputs,
      [fieldId]: event.target.value,
    });
  };

  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      search();
    }
  };

  const search = () => {
    /* Grid data */
    DataModelService.getDataModelList(inputs, (res) => {
      const list = ProgramService.filterByTenantIdAndCoCd(
        res.data,
        props.workspace.Info.tenantId,
        props.workspace.Info.coCd,
        "dataModelNm"
      );
      setGridData(list);
    });
  };

  useEffect(() => {
    /* Module Combo 조회*/
    let comboParams = { codeMstCd: "Z0000" };
    CodeService.getCodeCombo(comboParams, (res) => {
      search();
    });
  }, []);

  const columns = [
    { field: "tenantId", headerName: "Tenant ID", width: 110 },
    { field: "coCd", headerName: "Company Code", width: 100 },
    { field: "dataModelId", headerName: "Data Model UID", width: 100 },
    { field: "dataModelNm", headerName: "Data Model Name", width: "*" },
    { field: "description", headerName: "Data Model Description", width: "*" },
    { field: "dataModelTypeNm", headerName: "Data Model Type", width: 150 },
  ];

  return (
    <Modal>
      <Modal.Header {...props} />
      <Modal.Body>
        <div className="row mb-3">
          <div className="col-2">
            <MInput
              id="dataModelId"
              label="Data Model UID"
              type="text"
              fullWidth
              size="small"
              color="primary"
              placeholder="Data UID"
              value={inputs.dataModelId}
              onChange={onChange}
              onKeyPress={onKeyPress}
            />
          </div>
          <div className="col-3">
            <MInput
              id="dataModelNm"
              label="Data Model Name"
              type="text"
              fullWidth
              size="small"
              color="primary"
              placeholder="Data Model Name"
              value={inputs.dataModelNm}
              onChange={onChange}
              onKeyPress={onKeyPress}
            />
          </div>
          {/* <div className="col-3">
            <MInput
              id="entity"
              label="엔티티"
              type="text"
              fullWidth
              size="small"
              color="primary"
              placeholder="엔티티"
              value={inputs.entity}
              onChange={onChange}
              onKeyPress={onKeyPress}
            />
          </div> */}
          <div className="col-3">
            <MInput
              id="description"
              label="Data Model Description"
              type="text"
              fullWidth
              size="small"
              color="primary"
              placeholder="Data Model Description"
              value={inputs.description}
              onChange={onChange}
              onKeyPress={onKeyPress}
            />
          </div>
          <div className="col-2">
            <Button variant="primary" onClick={search}>
              Search <BsSearch size="14" />
            </Button>
          </div>
          <div className="col-2">
            <Button
              variant="outline-danger"
              onClick={(e) => {
                if (props.callbackFnc) {
                  props.callbackFnc.call(this, {
                    dataModelId: "",
                    dataModelNm: "",
                  });
                  Popup.close();
                }
              }}
            >
              Cancel Selection <AiOutlineClose size="14" />
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <WijmoGrid
              style={{ height: "650px" }}
              getRowId={(row) => row.dataModelId}
              rows={gridData}
              columns={columns}
              onRowDoubleClick={(data, event) => {
                if (props.callbackFnc) props.callbackFnc.call(this, data);
                event.defaultMuiPrevented = true;
                Popup.close();
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer {...props} />
    </Modal>
  );
};

export default DataModelPopup;

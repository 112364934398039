import ActionType from "components/builder/BuilderActionType";

/**
 * 필요한 Action 알아서 만드시오
 */

/**
 * Design 초기화
 * @param {*} breakpoint
 * @returns
 */
export const setDesign = (design) => {
  return {
    type: ActionType.PROGRAM_DESIGN.SET_DESIGN,
    payload: design,
  };
};

/**
 * Design Layout Column 초기화
 * @param {*} breakpoint
 * @returns
 */
export const setDesignLayoutColumn = (designLayoutColumn) => {
  return {
    type: ActionType.PROGRAM_DESIGN.SET_DESIGN_LAYOUT_COLUMN,
    payload: designLayoutColumn,
  };
};

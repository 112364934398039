import React, { createContext, memo } from "react";
// import "css/builder/TechnicalDesign.css";

import { useState } from "react";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import StringUtils from "components/common/utils/StringUtils";
import { Enums } from "components/builder/BuilderEnum";
import BuilderHeaderComponent from "components/builder/BuilderHeaderComponent";
import TechnicalDesignHeader from "components/technicaldesign/component/TechnicalDesignHeader";

const TechnicalDesign = memo(({ ...props }) => {
  const [currentPath, setCurrentPath] = useState();
  const location = useLocation();

  useEffect(() => {
    const CurrentPath = location.pathname.split("/")[2];
    setCurrentPath(CurrentPath);
  }, [location]);

  return (
    <>
      {StringUtils.equalsIgnoreCase(
        currentPath,
        Enums.BuilderPath.PROGRAM_DESIGN
      ) ? (
        <>TEST</>
      ) : (
        <TechnicalDesignHeader {...props.header} />
      )}

      <Outlet />
    </>
  );
});

export default TechnicalDesign;

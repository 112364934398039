import { TextField } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import BuilderHeaderComponent from "components/builder/BuilderHeaderComponent";
import { AppContext } from "components/common/AppContextProvider";
import WijmoGrid from "components/common/element/WijmoGrid";
import CommonUtils from "components/common/utils/CommonUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import PageTemplate from "page/common/PageTemplate";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";

/**
 * 프로그램 목록
 * @param {*} param0
 * @returns
 */
function TechnicalDesignList({ ...props }) {
  const workspace = useSelector((state) => state.workspace);

  const dispatch = useDispatch();

  const {
    workspace: { openPopup },
    component,
  } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {}, []);

  const breadcrum = [
    {
      name: "Technical Design List",
      url: Enums.BuilderPath.PROGRAM_DESIGN.LIST,
      active: true,
    },
  ];

  const columns = [
    {
      field: "tenantId",
      headerName: "Tenant ID",
      width: 120,
    },
    {
      field: "coCd",
      headerName: "Company Code",
      width: 120,
    },
    {
      field: "description",
      headerName: "Technical Design Description",
      width: "*",
    },
    {
      field: "updtUserId",
      headerName: "Update User",
      width: 130,
    },
    {
      field: "updtDt",
      headerName: "Update Date",
      width: 150,
      renderCell: (params) =>
        `${CommonUtils.getDate(params.updtDt, "datetime")}`,
    },
  ];

  return (
    <>
      <BuilderHeaderComponent {...props.header} />
      <PageTemplate breadcrum={breadcrum}>
        <PageTemplate.Box boxClass="mb-0">
          {ObjectUtils.isEmpty(workspace) ? (
            <div className="workspace-empty-alert">
              <div className="alert-msg">
                Workspace configuration is required.
              </div>
              <Button onClick={() => openPopup()}>Open Popup</Button>
            </div>
          ) : (
            <>
              <Form>
                <Row className="pb-3 pt-3">
                  <Col xs={2}>
                    <TextField
                      size={"small"}
                      label="Service UID"
                      placeholder="Enter Service UID..."
                      fullWidth={true}
                      // onChange={(e) => setServiceUid(e.currentTarget.value)}
                      // onKeyDown={onKeyDown}
                      type={"number"}
                    />
                  </Col>
                  <Col xs={2}>
                    <TextField
                      size={"small"}
                      label="Service ID"
                      placeholder="Enter Service ID..."
                      fullWidth={true}
                      // onChange={(e) => setServiceId(e.currentTarget.value)}
                      // onKeyDown={onKeyDown}
                    />
                  </Col>
                  <Col xs={2}>
                    <TextField
                      size="small"
                      label="Service Name"
                      placeholder="Service Name"
                      // onChange={(e) => setServiceName(e.currentTarget.value)}
                      // onKeyDown={onKeyDown}
                      fullWidth
                    />
                  </Col>
                  <Col xs={2}>
                    <TextField
                      size="small"
                      label="Update User ID"
                      placeholder="Update User ID"
                      // onChange={(e) => setWorkerId(e.currentTarget.value)}
                      // onKeyDown={onKeyDown}
                      fullWidth
                    />
                  </Col>
                  <Col xs={1}>
                    <Button
                      variant="primary"
                      // onClick={onSearch}
                    >
                      Search <BsSearch size="14" />
                    </Button>
                  </Col>
                </Row>
              </Form>
              <Row style={{ height: "calc(100% - 70px)" }}>
                <Col xs={12}>
                  <WijmoGrid
                    isLoading={isLoading}
                    columns={columns}
                    rows={[]}
                    // onRowDoubleClick={(params) => onSelectProgram(params)}
                    getRowId={(row) => row.programUid}
                    style={{ height: "70vh" }}
                  />
                </Col>
              </Row>
            </>
          )}
        </PageTemplate.Box>
      </PageTemplate>
    </>
  );
}

export default TechnicalDesignList;

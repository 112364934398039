import ArrayUtils from "components/common/utils/ArrayUtils";
import StringUtils from "components/common/utils/StringUtils";
import React, { memo, useEffect, useRef, useState } from "react";
import Modal from "components/common/modal/UModal";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import Message from "components/common/Message";
import * as Enums from "components/builder/BuilderEnum";
import ComponentService from "services/datamodel/DataModelService";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import { FaTrash } from "react-icons/fa";
import { MdEdit, MdLibraryAdd, MdOutlineAdd } from "react-icons/md";
import Popup from "components/common/Popup";
import {
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import styled from "styled-components";
import CodeService from "services/common/CodeService";
import USelectbox from "components/common/element/USelectbox";
import SaveProcedureWarnPopup from "../workflow/SaveProcedureWarnPopup";
import User from "components/common/utils/UserUtils";

const TnxWork = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * 사용자 트랜잭션 등록 팝업
 * 데이터 모델 목록에서 사용
 */
const UserTransactionPopup = memo(({ dataModel, updateRowData }) => {
  const { dataModelNm, dataModelUsrTnxs, dataModelId } = dataModel;
  const [usrTnxs, setUsrTnxs] = useState([]);
  const [argumentTypes, setArgumentTypes] = useState([]);
  const [apiTargetSysCds, setApiTargetSysCds] = useState([]);

  useEffect(() => {
    if (ArrayUtils.isEmpty(dataModelUsrTnxs)) {
      setUsrTnxs([generateTmpTnx()]);
    } else {
      const uuidList = dataModelUsrTnxs.map((_tnx) => {
        const obj = { ..._tnx };
        obj.key = StringUtils.getUuid();
        obj.tnxHandleOption = JSON.parse(obj.tnxHandleOption);
        obj.createdYn = "N";
        obj.deletedYn = "N";
        obj.updatedYn = "N";
        return obj;
      });
      setUsrTnxs(uuidList);
    }
    // procedure Argument Types
    CodeService.getCodeCombo({ codeMstCd: "Z0036" }, (res) => {
      setArgumentTypes(res.data);
    });
    // procedure Argument Types
    CodeService.getCodeCombo({ codeMstCd: "Z0039" }, (res) => {
      setApiTargetSysCds(res.data);
    });
  }, []);
  const generateTmpTnx = () => {
    return {
      key: StringUtils.getUuid(),
      tnxHandleType: "U",
      usrTnxTypeNm: "",
      usrApiUrl: "",
      apiTargetSysCd: "LOCAL",
      tnxHandleOption: {
        arguments: [
          { mode: "IN", name: "tenantId", type: "STRING" },
          {
            mode: "IN",
            name: "coCd",
            type: "STRING",
          },
        ],
        procedureName: "",
      },
      createdYn: "Y",
      deletedYn: "N",
      updatedYn: "N",
    };
  };

  const onAddNewTransaction = () => {
    const newList = [...usrTnxs, generateTmpTnx()];
    setUsrTnxs(newList);
  };

  const onDelete = (idx) => {
    let newList = [...usrTnxs];
    if (StringUtils.equalsIgnoreCase(newList[idx].createdYn, "Y")) {
      newList.splice(idx, 1);
    } else {
      if (StringUtils.equalsIgnoreCase(newList[idx].deletedYn, "Y")) {
        newList[idx].deletedYn = "N";
      } else {
        newList[idx].deletedYn = "Y";
      }
    }
    setUsrTnxs(newList);
  };

  const onSubmit = (e) => {
    if (e) e.preventDefault();
    const tnxList = JSON.parse(JSON.stringify(usrTnxs));
    for (const tnx of tnxList) {
      const { usrTnxTypeNm, usrApiUrl, tnxHandleOption, tnxHandleType } = tnx;
      if (StringUtils.isEmpty(usrTnxTypeNm)) {
        return Message.alert(
          "There is none value Input.",
          Enums.MessageType.ERROR
        );
      }
      if (
        usrTnxs.filter((t) =>
          StringUtils.equalsIgnoreType(t.usrTnxTypeNm, usrTnxTypeNm)
        ).length > 1
      ) {
        return Message.alert(
          "Duplicate transaction names exist.",
          Enums.MessageType.ERROR
        );
      }
      if (StringUtils.equalsIgnoreCase(tnxHandleType, "U")) {
        if (StringUtils.isEmpty(usrApiUrl)) {
          return Message.alert(
            "There is Empty API URL.",
            Enums.MessageType.ERROR
          );
        }
        tnx.tnxHandleOption = null;
      } else if (StringUtils.equalsIgnoreCase(tnxHandleType, "P")) {
        if (StringUtils.isEmpty(tnxHandleOption.procedureName)) {
          return Message.alert(
            "Please enter Procedure name",
            Enums.MessageType.ERROR
          );
        }
        tnx.usrApiUrl = null;
        tnx.tnxHandleOption = JSON.stringify(tnx.tnxHandleOption);
      }
    }
    const saveTnx = () => {
      const body = {
        dataModelId,
        usrTnxs: tnxList,
      };
      ComponentService.saveUsrTnx(body, (res) => {
        const { data } = res;
        if (data) {
          Message.alert("Save Successfully.", Enums.MessageType.SUCCESS);
          updateRowData({ ...dataModel, dataModelUsrTnxs: data });
          Popup.close();
        }
      });
    };

    if (tnxList.filter((tnx) => tnx.tnxHandleType === "P").length > 0) {
      openProcedureWarnPopup(saveTnx);
    } else saveTnx();
  };

  const openProcedureWarnPopup = (cb) => {
    const _cb = () => {
      Popup.close();
      if (cb) {
        cb();
      }
    };
    const _openPopup = () => {
      Popup.open(<SaveProcedureWarnPopup callback={_cb} />, {
        style: {
          content: {
            width: "350px",
          },
        },
      });
    };
    _openPopup();
  };

  return (
    <Modal>
      <Modal.Header title={`${dataModelNm} User Transaction Setting`} />
      <Modal.Body>
        <UmodalTemplate>
          <Row>
            <Col xs={10} />
            <Col xs={2}>
              <Button variant="outline-primary" onClick={onAddNewTransaction}>
                Add Trasaction
              </Button>
            </Col>
          </Row>
          <Form as={Row}>
            <Col xs={12}>State</Col>
          </Form>
          <Grid container spacing={2}>
            {usrTnxs.map((item, idx) => {
              return (
                <TnxInputList
                  key={item.key}
                  userTransaction={item}
                  onDelete={() => onDelete(idx)}
                  idx={idx}
                  usrTnxs={usrTnxs}
                  setUsrTnxs={setUsrTnxs}
                  argumentTypes={argumentTypes}
                  apiTargetSysCds={apiTargetSysCds}
                  openProcedureWarnPopup={openProcedureWarnPopup}
                />
              );
            })}
          </Grid>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onSubmit}>Save</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
});

export default UserTransactionPopup;

const TnxInputList = memo(
  ({
    userTransaction,
    argumentTypes,
    onDelete,
    usrTnxs,
    setUsrTnxs,
    idx,
    apiTargetSysCds,
    openProcedureWarnPopup,
  }) => {
    const [tnxHandleType, setTnxHandleType] = useState(
      userTransaction?.tnxHandleType || "U"
    );
    const [usrTnxTypeNm, setUsrTnxTypeNm] = useState(
      userTransaction?.usrTnxTypeNm || ""
    );
    const [usrApiUrl, setUsrApiUrl] = useState(
      userTransaction?.usrApiUrl || ""
    );
    const [tnxHandleOption, setTnxHandleOption] = useState(
      userTransaction?.tnxHandleOption || {
        arguments: [
          {
            mode: "IN",
            name: "tenantId",
            type: "STRING",
          },
          {
            mode: "IN",
            name: "coCd",
            type: "STRING",
          },
        ],
        procedureName: "",
      }
    );

    const onChange = (e) => {
      const newUsrTnxs = [...usrTnxs];
      newUsrTnxs[idx][e.target.id || e.target.name] = e.target.value;
      if (newUsrTnxs[idx].createdYn === "N") {
        newUsrTnxs[idx].updatedYn = "Y";
      }
      setUsrTnxs(newUsrTnxs);
    };

    //행삭제
    const onDeleteRow = () => {
      if (onDelete) onDelete(idx);
    };
    const onHandleOptionChange = (e) => {
      const newOption = {
        ...tnxHandleOption,
        procedureName: e.currentTarget.value,
      };
      setTnxHandleOption(newOption);
      let id = "tnxHandleOption";
      onChange({
        target: {
          id,
          value: newOption,
        },
      });
    };

    const onConfirm = (newArgs) => {
      const newOption = {
        ...tnxHandleOption,
        arguments: [...newArgs],
      };
      setTnxHandleOption(newOption);
      let id = "tnxHandleOption";
      onChange({
        target: {
          id,
          value: newOption,
        },
      });
    };

    const onOpenArgsPopup = (e) => {
      const options = {
        effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
        style: {
          content: {
            width: "460px", //popup의 크기를 50% (default 60%)
          },
        },
      };
      Popup.open(
        <TnxProcedureArgsPopup
          _arguments={tnxHandleOption.arguments}
          onConfirm={onConfirm}
          argumentTypes={argumentTypes}
        />,
        options
      );
    };

    return (
      <>
        <Grid item xs={1}>
          {userTransaction.createdYn === "Y" ? (
            <Tooltip title="To be Added">
              <TnxWork>
                <MdOutlineAdd size={"16"} color="blue" />
              </TnxWork>
            </Tooltip>
          ) : userTransaction.deletedYn === "Y" ? (
            <Tooltip title="To be Deleted">
              <TnxWork>
                <FaTrash size={"16"} color="tomato" />
              </TnxWork>
            </Tooltip>
          ) : (
            userTransaction.updatedYn === "Y" && (
              <Tooltip title="To be Modified">
                <TnxWork>
                  <MdEdit size={"16"} color="darkgreen" />
                </TnxWork>
              </Tooltip>
            )
          )}
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="apiTypeLabel">Type</InputLabel>
            <Select
              size="small"
              label="Type"
              labelId="apiTypeLabel"
              value={tnxHandleType}
              name="tnxHandleType"
              onChange={(e) => {
                onChange(e);
                setTnxHandleType(e.target.value);
                if (e.target.value === "P") {
                  openProcedureWarnPopup(() => {});
                }
              }}
            >
              <MenuItem value={"U"}>API URL</MenuItem>
              <MenuItem value={"P"}>Procedure</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {StringUtils.equalsIgnoreCase(tnxHandleType, "U") ? (
          <>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <TextField
                  size="small"
                  label={"Transaction Name"}
                  value={usrTnxTypeNm}
                  id={"usrTnxTypeNm"}
                  onChange={(e) => {
                    setUsrTnxTypeNm(e.currentTarget.value);
                    onChange(e);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="apiTargetSysCd">Target System</InputLabel>
                <Select
                  size="small"
                  label="Target System"
                  labelId="apiTargetSysCd"
                  value={userTransaction.apiTargetSysCd}
                  name="apiTargetSysCd"
                  onChange={(e) => {
                    onChange(e);
                  }}
                >
                  {apiTargetSysCds.map((item) => {
                    return (
                      <MenuItem value={item.id} key={item.id}>
                        {item.text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  size="small"
                  label={"API URL"}
                  value={usrApiUrl}
                  id={"usrApiUrl"}
                  onChange={(e) => {
                    setUsrApiUrl(e.currentTarget.value);
                    onChange(e);
                  }}
                />
              </FormControl>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={2}>
              <FormControl>
                <TextField
                  size="small"
                  value={usrTnxTypeNm}
                  id={"usrTnxTypeNm"}
                  onChange={(e) => {
                    onChange(e);
                    setUsrTnxTypeNm(e.currentTarget.value);
                  }}
                  label="Transaction Name"
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <TextField
                  size="small"
                  value={tnxHandleOption.procedureName}
                  id="procedureName"
                  onChange={(e) => onHandleOptionChange(e)}
                  label="Procedure Name"
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={4}
              // style={{ display: "flex" }}
            >
              <FormControl fullWidth>
                <div style={{ display: "flex" }}>
                  <TextField
                    value={tnxHandleOption.arguments
                      .map((arg) => arg.name)
                      .join(",")}
                    id="arguments"
                    readonly={true}
                    label="Procedure Arguments"
                    size="small"
                    fullWidth
                  />
                  <Tooltip title="Add Argument">
                    <Button onClick={onOpenArgsPopup}>
                      <MdLibraryAdd size="16" />
                    </Button>
                  </Tooltip>
                </div>
              </FormControl>
            </Grid>
          </>
        )}
        <Grid
          item
          xs={1}
          style={{ display: "flex", gap: "3px", justifyContent: "center" }}
        >
          <Button
            variant={
              userTransaction.deletedYn === "N" ? "danger" : "outline-danger"
            }
            onClick={onDeleteRow}
          >
            <FaTrash size="16" />
          </Button>
        </Grid>
      </>
    );
  }
);

const TnxProcedureArgsPopup = ({
  _arguments,
  argumentTypes,
  onConfirm,
  ...props
}) => {
  const [args, setArgs] = useState(_arguments);

  const onClickOk = (e) => {
    e.preventDefault();
    //비어있는 인수 삭제
    let list = args.filter((arg) => !StringUtils.isEmpty(arg.name));
    //중복된 인수 확인
    for (const arg of list) {
      const count = list.filter((contents) =>
        StringUtils.equalsIgnoreType(contents.name, arg.name)
      ).length;
      if (count > 1) {
        return Message.alert(
          "There are duplicate Arguments.",
          Enums.MessageType.ERROR
        );
      }
    }
    Popup.close();
    if (onConfirm) onConfirm(list);
  };

  const addArgs = (e) => {
    e.preventDefault();
    setArgs([
      ...args,
      {
        mode: "IN",
        name: "",
        type: "STRING",
      },
    ]);
  };
  const onChangeArgs = (e, idx) => {
    let newArgs = [...args];
    if (StringUtils.equalsIgnoreCase(e.target.id, "arg_mode")) {
      newArgs[idx].mode = e.currentTarget.value;
    } else if (StringUtils.equalsIgnoreCase(e.target.id, "arg_name")) {
      newArgs[idx].name = e.currentTarget.value;
    } else if (StringUtils.equalsIgnoreCase(e.target.id, "arg_type")) {
      newArgs[idx].type = e.currentTarget.value;
    }
    setArgs(newArgs);
  };

  const onDeleteArgs = (e, idx) => {
    e.preventDefault();
    let newArgs = [...args];
    newArgs.splice(idx, 1);
    setArgs(newArgs);
  };

  return (
    <Modal>
      <Modal.Header title="Add Arguments Procedure" />
      <Modal.Body>
        <Row className="mb-3">
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={addArgs}>Add</Button>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col style={{ display: "flex", justifyContent: "center" }} xs={4}>
            Mode
          </Col>
          <Col style={{ display: "flex", justifyContent: "center" }} xs={3}>
            Type
          </Col>
          <Col style={{ display: "flex", justifyContent: "center" }}>Name</Col>
        </Row>
        {args.map((arg, idx) => {
          let disable = false;
          if (
            StringUtils.equalsIgnoreCase(arg.name, "tenantId") ||
            StringUtils.equalsIgnoreCase(arg.name, "cocd")
          ) {
            disable = true;
          }

          return (
            <Row key={idx} className="mb-3">
              <Col xs={4}>
                <Form.Select
                  id={"arg_mode"}
                  value={arg.mode}
                  disabled={disable}
                  onChange={(e) => onChangeArgs(e, idx)}
                >
                  <option value={"IN"}>IN</option>
                  <option value={"OUT"}>OUT</option>
                  <option value={"INOUT"}>INOUT</option>
                  <option value={"REF_CURSOR"}>REF_CURSOR</option>
                </Form.Select>
              </Col>
              <Col xs={3}>
                <Form.Select
                  id="arg_type"
                  value={arg.type}
                  disabled={disable}
                  onChange={(e) => onChangeArgs(e, idx)}
                >
                  {argumentTypes.map((type) => {
                    return (
                      <option key={type.id} value={type.id}>
                        {type.text}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
              <Col>
                <InputGroup>
                  <Form.Control
                    value={arg.name}
                    id={"arg_name"}
                    disabled={disable}
                    onChange={(e) => onChangeArgs(e, idx)}
                  />
                  <Button
                    variant="danger"
                    disabled={disable}
                    onClick={(e) => onDeleteArgs(e, idx)}
                  >
                    <FaTrash size="16" />
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onClickOk}>Confirm</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
};

import { Tooltip } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import Message from "components/common/Message";
import JsonUtils from "components/common/utils/JsonUtils";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";
import { WorkflowContext } from "page/workflow";
import { memo, useCallback, useContext, useRef, useState } from "react";
import { AiOutlineClose, AiOutlineEdit } from "react-icons/ai";
import { FaRegObjectGroup } from "react-icons/fa";
import { FiMaximize, FiMinimize } from "react-icons/fi";
import { GiResize } from "react-icons/gi";
import { MdOutlineAccountTree } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { NodeResizer, useEdges, useNodes } from "reactflow";
import WorkflowReduxHelper from "../editor/helper/WorkflowReduxHelper";
import useWorkflowDropEvent from "../editor/render/useWorkflowDropEvent";
import { updateBundle } from "../reducer/WorkflowAction";

/**
 * 노드 묶은 타입
 */
export const BundleNode = memo(
  ({
    data: { process, isBundling },
    id,
    selected,
    parentNodeId,
    isChild,
    parentNode,
    addHandler,
    onAddBundle,
    onDeleteFromBundle,
    setBreakpoint,
    removeBreakpoint,
    isTracing,
    isBreakPoint,
    commentCheckMethod,
    addBundleControlButton,
    ...props
  }) => {
    const nodes = useNodes();
    const edges = useEdges();
    const dispatch = useDispatch();
    const workflow = useSelector((state) => state.workflow);
    const {
      bundle: {
        setBundlingMode,
        selectedBundle,
        setSelectedBundle,
        setBundleNodeList,
        setOpenBundleDetailPopup,
      },
    } = useContext(WorkflowContext);
    const [isDragOver, setIsDragOver] = useState(false);
    const {
      onDropCode,
      onDropCondition,
      onDropIterator,
      onDropProcess,
      onDropService,
    } = useWorkflowDropEvent();
    const groupRef = useRef();

    const positionChangedNodeList = useRef({}); // 디버깅시 번들이 열릴때 위치를 바꿔줄 노드 목록 -> 원위치를 기억시킴

    /**
     * 번들 삭제
     */
    const onDeleteBundle = useCallback(
      (e) => {
        stopEvent(e);
        /**
         * 1. 번들의 포지션을 가져온다.
         * 2. 내부 프로세스들의 포지션에 번들의 포지션 (x,y) 만큼 더해서 업데이트 한다.
         */
        const updatedNodeList = [];
        const bundlePosition = process.position;

        process.propertyValue.nodeList.forEach((node) => {
          const bundleChildNode = JsonUtils.findNode(
            workflow.output,
            "compId",
            node.bundleCompId
          );
          const positionUpdatedData = produce(bundleChildNode, (draft) => {
            draft.position = {
              x: draft.position.x + bundlePosition.x,
              y: draft.position.y + bundlePosition.y,
            };
          });
          updatedNodeList.push(positionUpdatedData);
        });

        WorkflowReduxHelper.deleteBundle(
          dispatch,
          workflow,
          process,
          updatedNodeList
        );
      },
      [workflow, process]
    );

    /**
     * 번들이 확장 될때 외부의 노드 포지션을 옮기는 로직
     * @returns
     */
    const repositionExternalBundleNodes = () => {
      const WF = JSON.parse(JSON.stringify(workflow));
      const bundleMinX = process.position.x + 323;
      const bundleMinY = process.position.y + 111;
      const bundleWidth = process.style.width;
      const bundleHeight = process.style.height;

      //나머지 노드들 번들 위치 만큼 크게 해주고 작게 해주고
      //번들보다 오른쪽 및 아래쪽에 있는 모든 노드 구하기
      const targetNodes = nodes.filter(
        (node) =>
          (node.position.x > bundleMinX || node.position.y > bundleMinY) &&
          !node.parentNode
      );
      targetNodes.forEach((node) => {
        if (positionChangedNodeList.current[node.id]) {
          return false;
        }
        const nodeDef = JsonUtils.findNode(WF, "compId", node.id);
        if (
          node.position.x > bundleMinX &&
          node.position.y < bundleMinY + bundleHeight
        ) {
          nodeDef.position.x += bundleWidth;
        } else {
          nodeDef.position.y += bundleHeight;
        }

        positionChangedNodeList.current[node.id] = node;
      });
      return WF;
    };

    /**
     * 번들에 의해 바뀐 포지션을 초기화하는 로직
     * @returns
     */
    const resetExternalNodePositions = () => {
      const WF = JSON.parse(JSON.stringify(workflow));
      // 위치 바뀐 노드들 제위치로 옮겨줌
      Object.keys(positionChangedNodeList.current).forEach((key) => {
        const node = positionChangedNodeList.current[key];
        const nodeDef = JsonUtils.findNode(WF, "compId", node.id);
        nodeDef.position = node.position;
      });
      positionChangedNodeList.current = [];

      return WF;
    };

    //최소화 시키는 로직
    const onClickMini = (e) => {
      stopEvent(e);
      /**
       * 번들내 시작노드와 마지막 노드는 프로세스이다.
       * 1. 번들내 시작노드 구하기
       * 2. 번들내 마지막 노드 구하기
       * 3. 시작노드의 이전의 커넥터 ID를 번들의 프로퍼티 정보에 connectFrom에 넣는다.
       * 4. 마지막 노드의 커넥터 ID를 가져와서 동일하게 connectTo에 넣는다.
       */

      //번들내 시작노드 구하기
      const nodeList = nodes.filter(
        (node) =>
          process.propertyValue.nodeList.findIndex(
            (n) => n.bundleCompId === node.id
          ) > -1
      );
      //번들 내 프로세스 목록
      const processListInBundle = nodeList.filter(
        (n) => n.type !== Enums.WorkflowNodeType.CONNECTOR
      );
      // 번들 내 커넥터 목록
      const connectorListInBundle = nodeList.filter(
        (n) => n.type === Enums.WorkflowNodeType.CONNECTOR
      );
      //번들 내부에 노드를 향하지만, 번들 내부에 없는 커넥터들을 구해야함
      const connectorFrom = [];
      const connectorTo = [];

      nodes.forEach((node) => {
        //커넥터 이지만 번들에 없는 커넥터 중 번들 내부를 향하는 노드를 검색함
        /**
         * 전체 커넥터에서 탐색 이유
         * 1. 무조건 번들의 시작으로만 커넥터가 들어오지 않음.
         * 2. 번들 내외부를 재귀적으로 in/out 하는 경우 번들 중간 또는 마지막으로 다시 돌아올 수 도있기때문
         */
        if (
          node.type === Enums.WorkflowNodeType.CONNECTOR &&
          !connectorListInBundle.find((cb) => cb.id === node.id)
        ) {
          //번들 내부로 향하는 커넥터
          const isInFromConnectorInBundle = processListInBundle.find(
            (process) => process.id === node.data.connector.processTo
          );
          if (isInFromConnectorInBundle) {
            connectorFrom.push(node.id);
          }
          //번들에서 외부로 향하는 커넥터
          const isInToConnectorInBundle = processListInBundle.find(
            (process) => process.id === node.data.connector.processFrom
          );
          if (isInToConnectorInBundle) {
            connectorTo.push(node.id);
          }
        }
      });
      //리덕스 업데이트

      const WF = resetExternalNodePositions();
      const bundleIndex = WF.output.bundle.findIndex(
        (b) => b.compId === process.compId
      );
      WF.output.bundle[bundleIndex].propertyValue.connectorFrom = connectorFrom;
      WF.output.bundle[bundleIndex].propertyValue.connectorTo = connectorTo;
      WF.output.bundle[bundleIndex].propertyValue.expand = false;
      WorkflowReduxHelper.updateWorkflow(dispatch, WF, workflow);
    };

    //최대화 시키는 로직
    const onClickExpand = (e) => {
      stopEvent(e);
      //번들 사이즈 조정
      /**
       * 좌상단 ,우상단,
       * 좌하단, 우하단 노드 구해서 각각의 최대값 + 100 으로 재조정
       * 헤더가 있기 때문에 y는 +30을 더한다.
       */

      const WF = repositionExternalBundleNodes();

      const bundleIndex = WF.output.bundle.findIndex(
        (b) => b.compId === process.compId
      );
      WF.output.bundle[bundleIndex].propertyValue.expand = true;

      WorkflowReduxHelper.updateWorkflow(dispatch, WF, workflow);
    };

    /**
     * 번들을 내부 노드 위치와 크기에 따라 재조정함
     */
    const getBundleSize = () => {
      const tempWF = JSON.parse(JSON.stringify(workflow));
      const bundleNodeList = process.propertyValue.nodeList.map((child) => {
        const childNode = JsonUtils.findNode(
          tempWF,
          "compId",
          child.bundleCompId
        );
        return childNode;
      });
      const standard = bundleNodeList[0].position;
      let minX = standard.x;
      let minY = standard.y;
      let maxX = standard.x;
      let maxY = standard.y;

      bundleNodeList.forEach((node) => {
        if (node.position.x < minX) {
          minX = node.position.x;
        }
        if (node.position.y < minY) {
          minY = node.position.y;
        }
        const nodeWidth = node.style ? node.style.width : 323;
        const nodeHeight = node.style ? node.style.height : 170;
        if (node.position.x + nodeWidth > maxX) {
          maxX = node.position.x + nodeWidth;
        }
        if (node.position.y + nodeHeight > maxY) {
          maxY = node.position.y + nodeHeight;
        }
      });

      const bundleWidth = maxX - minX + 200;
      const bundleHeight = maxY - minY + 200 + 30;

      return { bundleWidth, bundleHeight };
    };

    /**
     * 번들 사이즈 재조정
     */
    const onRescaleBundleSize = () => {
      const { bundleHeight, bundleWidth } = getBundleSize();
      const newBundleInfo = produce(process, (draft) => {
        draft.style.width = bundleWidth;
        draft.style.height = bundleHeight;
      });

      dispatch(updateBundle(newBundleInfo));
    };

    //번들 정보 수정
    const onEditBundleInfo = (e) => {
      stopEvent(e);
      setBundlingMode(true);
      setSelectedBundle(process);
      const bundleNodeList = {};
      process.propertyValue.nodeList.forEach((node) => {
        bundleNodeList[node.bundleCompId] = true;
      });
      setBundleNodeList(bundleNodeList);
    };

    /**
     * 별도의 팝업으로 번들 내부 확인하는 함수
     * @param {*} e
     */
    const onOpenBundleDetailPopup = (e) => {
      stopEvent(e);
      setSelectedBundle(process);
      setOpenBundleDetailPopup(true);
    };

    /**
     * 공통버튼 그리는 함수
     * @returns
     */
    const renderCommonButton = () => {
      return (
        <>
          <Tooltip title="Edit" placement="top">
            <button style={{ color: "limegreen" }} onClick={onEditBundleInfo}>
              <AiOutlineEdit size={20} />
            </button>
          </Tooltip>
          <button onClick={onDeleteBundle}>
            <AiOutlineClose size={20} />
          </button>
        </>
      );
    };

    /**
     * 드래그가 Iterator 안에 들어간 경우
     * 내부에 속안 컴포넌트들의 스타일을 강제적으로 변환함
     * @param {*} e
     */
    const onDragEnter = (e) => {
      if (e) stopEvent(e);
      setIsDragOver(true);
      const children = document.getElementsByClassName(`${id}_child`);
      for (const child of children) {
        child.style.opacity = 0.3;
      }
    };
    /**
     * 드래그가 빠져나간 경우
     * @param {*} e
     */
    const onDragLeave = (e) => {
      if (e) stopEvent(e);
      setIsDragOver(false);
      const children = document.getElementsByClassName(`${id}_child`);
      for (const child of children) {
        child.style.opacity = 1;
      }
    };

    const onDrop = (event) => {
      onDragLeave();

      const reactFlowBounds = groupRef.current.getBoundingClientRect();
      const position = {
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      };

      const _NodeData = event.dataTransfer.getData("application/reactflow");
      if (!_NodeData) return false; //데이터가 없는 경우
      const NodeData = JSON.parse(_NodeData);
      const { type } = NodeData;

      if (
        !StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.MEMO, type) &&
        event
      ) {
        //메모를 드랍할때는 WorkflowBuilder의 onDropMemo에 이벤트버블링이 되도록 함
        event.preventDefault();
        event.stopPropagation();
      }
      if (StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.PROCESS, type)) {
        onDropProcess(position, {
          nodes,
          edges,
          bundle: process,
        });
      } else if (
        StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.SERVICE, type)
      ) {
        onDropService(position, {
          nodes,
          edges,
          bundle: process,
        });
      } else if (
        StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.ITERATOR, type)
      ) {
        //이터레이터가 2중첩 이상인지 확인
        const grandParentNode = nodes.find((n) => n.id === parentNodeId);
        if (grandParentNode && nodes.find((n) => n.id === grandParentNode.id)) {
          Message.alert(
            "Iterator can be nested up to a maximum of 2 levels.",
            Enums.MessageType.WARN
          );
        } else {
          onDropIterator(position, {
            nodes,
            edges,
            bundle: process,
          });
        }
      } else if (
        StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.CONDITION, type)
      ) {
        onDropCondition(position, {
          nodes,
          edges,
          bundle: process,
        });
      } else if (
        StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.CODE, type)
      ) {
        onDropCode(position, {
          nodes,
          edges,
          bundle: process,
        });
      }
    };

    if (process.propertyValue.expand) {
      return (
        <>
          <NodeResizer
            color="dodgeblue"
            isVisible={selected}
            minWidth={200}
            minHeight={150}
            handleStyle={{
              width: "15px",
              height: "15px",
              borderRadius: "100%",
              zIndex: 1,
            }}
          />
          <div
            className={`workflow-bundle-node-wrapper node-group
            ${selected ? " selected" : ""} 
            ${isDragOver ? " over" : ""}
            `}
            style={{
              borderColor: process.propertyValue.groupColor,
            }}
            onDoubleClick={onEditBundleInfo}
            onDrop={onDrop}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragEnd={onDragLeave}
            ref={groupRef}
          >
            {isDragOver ? (
              <>Add a process to the group.</>
            ) : (
              <div
                className="header"
                style={{ background: process.propertyValue.groupColor }}
              >
                <div
                  className="title"
                  style={{ color: process.propertyValue.textColor }}
                >
                  <span>
                    <FaRegObjectGroup size={22} />
                  </span>
                  <span className="name"> {process.propertyValue.groupNm}</span>
                </div>

                <div>
                  <Tooltip title="Resize">
                    <button onClick={onRescaleBundleSize}>
                      <GiResize size={20} />
                    </button>
                  </Tooltip>

                  <Tooltip title="Fold">
                    <button onClick={onClickMini}>
                      <FiMinimize size={20} />
                    </button>
                  </Tooltip>
                  {renderCommonButton()}
                </div>
              </div>
            )}
          </div>
        </>
      );
    } else {
      const color = process.propertyValue.groupColor;
      const textColor = process.propertyValue.textColor;
      return (
        <div
          className={`workflow-node bundle ${isChild ? `${id}_child` : ""} 
            ${isBundling ? " bundling" : ""}`}
          onDoubleClick={onOpenBundleDetailPopup}
        >
          <div
            className={`workflow-process-node-wrapper bundle ${
              selected ? " selected " : ""
            }
             ${process.processType} 
            `}
            style={{ borderColor: color, color: textColor }}
          >
            <div
              className="header"
              style={{
                background: color,
                borderColor: color,
              }}
            >
              <div className="title"></div>
              <div className="control-button" style={{ color: textColor }}>
                <Tooltip title="Open in a Popup" placement="top">
                  <button onClick={onOpenBundleDetailPopup}>
                    <MdOutlineAccountTree size={20} />
                  </button>
                </Tooltip>
                <Tooltip title="Expand">
                  <button onClick={onClickExpand}>
                    <FiMaximize size={20} />
                  </button>
                </Tooltip>
                {renderCommonButton()}
              </div>
            </div>
            <div
              className="workflow-process-node"
              style={{ background: color }}
            >
              {process.propertyValue.groupNm}
            </div>
          </div>
          {addHandler()}
        </div>
      );
    }
  }
);

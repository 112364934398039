import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import ConnectionService from "services/common/ConnectionService";
import * as StompJs from "@stomp/stompjs";
import RunWorkflowPopup from "page/popup/workflow/RunWorkflowPopup";
import * as Enums from "components/builder/BuilderEnum";
import * as SockJS from "sockjs-client";
import {
  setWFBreakpointType,
  setWFErrType,
  setWFInCommunication,
  setWFIsDebugging,
  setWFProcess,
  setWFTrace,
} from "../../reducer/WorkflowDebugAction";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import JsonUtils from "components/common/utils/JsonUtils";
import User from "components/common/utils/UserUtils";
import produce from "immer";
import WorkflowService from "services/workflow/WorkflowService";

/**
 * 1. Workflow Builder에 있던 Debug 로직의 클래스 화
 * 2. singleton으로 만들어서 팝업이나 다른 기능창에서도 동일한 디버깅 정보를 볼 수 있도록 함
 * 3. trace 및 variable 정보를 유지하여야함.
 * 4. 화면의 state에 적용하는 함수는 클래스 객체에 따로 주입하여야함
 *
 * @author Kiyoung.park
 * @date 2024-09-19
 *
 */
class WorkflowDebugger {
  // setState 관련
  // 초기화 시 적용되어야 하는 부분
  setDebugExpressionMode = () => {};
  setDebugExprenssion = () => {};
  setLog = () => {};
  traceTracker = () => new Promise();
  setConsoleLogAutoLoad = () => {};
  setDebugVariables = () => {};
  connectionPopupOpen = () => {};
  // debugger 실행 전에 적용되어야 하는 부분
  workflow = null;
  workflowBreakpoints = [];
  connectionInfo = {};
  workspace = {};
  inCommunication = null;
  questPopupOpen = () => {};

  //클래스 내부적으로 사용하는 파라미터
  runType = "";
  debugParameter = {};
  nodes = [];
  client = {};
  currentDebuggingService = {};
  instance = null;
  debugConsoleSubscribe = null;
  socketId = StringUtils.getUuid();

  logConfig = {
    preEndPoint: 0,
  };
  logText = "";
  dispatch = null;
  isDebugging = false;

  //trace
  trace = [];

  static instance = new this();

  constructor() {}

  setConnectionPopupOpen = (popupOpenMethod) => {
    this.connectionPopupOpen = popupOpenMethod;
  };

  /**
   * singleton 패턴으로 인스턴스 설정
   * @returns
   */
  static getInstance() {
    if (this.instance === null) this.instance = new this();
    return this.instance;
  }

  /**
   * workflow 실행
   */
  runWorkflow = () => {
    //컨텐츠 충돌없으면 Run 실행
    const callbackFnc = (data) => {
      const body = {
        requestParams: { ...data },
      };
      if (this.runType === "debug") {
        //디버그 모드일 때 세부항목을 추가함
        body.breakpoints = this.workflowBreakpoints.filter(
          (point) => point.check
        );
        //디버깅용 참조데이터 init
        this.currentDebuggingService = {
          currentServiceUid: this.workflow.serviceInfo?.serviceUid,
          prevWorkflow: {
            [this.workflow.serviceInfo?.serviceUid]: {
              prevService: this.workflow.prevService,
              serviceContent: this.workflow.output,
              serviceComment: this.workflow.serviceComment,
              ...this.workflow.serviceInfo,
            },
          },
        };
      }
      // 연결 요청
      Popup.close();
      this.connectSocket(body);
      this.debugParameter = data;
    };

    Popup.open(
      <RunWorkflowPopup
        callbackFnc={callbackFnc}
        debugParameter={this.debugParameter}
        nodes={this.nodes}
      />,
      {
        style: { content: { width: "800px" } },
      }
    );
  };

  /**
   * 워크플로우 테스트 실행 또는 디버그 실행
   * @param {*} e
   * @param {*} type run || debug
   */
  onOpenRunConsole = async (e, type) => {
    if (
      this.workflow.output.service.child.process.filter(
        (p) => p.type !== "processEdge"
      ).length === 0
    ) {
      return Message.alert(
        "There are no executable nodes. Please check the flow.",
        Enums.MessageType.WARN
      );
    }
    if (this.inCommunication) return;
    const connection = User.getConnection(this.workspace.tenantMstId);
    if (!connection) {
      this.connectionPopupOpen();
      return Message.alert(
        "Server connection is required.",
        Enums.MessageType.WARN
      );
    }

    const getPrevService = () => {
      return new Promise((resolve, reject) => {
        WorkflowService.getService(
          { serviceUid: this.workflow.serviceInfo.serviceUid },
          (res) => {
            resolve(res.data);
          },
          reject
        );
      });
    };

    const prevResult = await getPrevService();

    const { deployDate, serviceContent: prevServiceContent } =
      WorkflowService.setData(prevResult);

    /**
     * 1. serviceUid가 없는 경우
     * 2. 배포된 적이 없는 경우
     * 3. 수정된 날짜와 배포된 날짜가 다른 경우
     * 위 경우는 배포(저장)를 새롭게 해야한다.
     */

    if (!deployDate) {
      this.questPopupOpen();
    } else {
      //viewport는 다른경우가 많기 때문에 빼고 비교
      const prev = produce(prevServiceContent, (draft) => {
        JsonUtils.removeNode(draft, "viewport");
      });
      const next = produce(this.workflow.output, (draft) => {
        JsonUtils.removeNode(draft, "viewport");
      });

      this.runType = type;
      if (JSON.stringify(prev) !== JSON.stringify(next)) {
        this.questPopupOpen();
      } else {
        this.runWorkflow();
      }
    }
  };

  /**
   * 디버그 실행중 멈춤
   * Custom Callback은 StopDebugCallback에 지정
   * @param {*} e
   */
  stopDebug = () => {
    this.setDebugExpressionMode("");
    this.setDebugExprenssion({});
    this.dispatch(setWFProcess({})); // 현재 진행중인 프로세스 초기화
    this.dispatch(setWFInCommunication(false)); // 통신 중 종료
    this.inCommunication = false;
    this.runType = "";
    this.trace = [];
    // 아래 항목이 있으니 디버깅이 종료 될때 마지막 콘솔 로그를 가져오기 전에 unsubscribe가 되어 나오지 않음
    // 시간차로 종료함
    if (!ObjectUtils.isEmpty(this.debugConsoleSubscribe)) {
      setTimeout(() => {
        this.debugConsoleSubscribe.unsubscribe();
        this.debugConsoleSubscribe = null;
        this.disConnectSocket();
      }, 500);
    }
  };

  /**
   * disconnect WebSocket
   */
  disConnectSocket = () => {
    if (!ObjectUtils.isEmpty(this.client)) this.client.deactivate();
  };

  /**
   * 커넥션 정보 설정
   * @param {*} connection
   */
  setConnection = (connection) => {
    this.connectionInfo = connection;
  };

  run = (params) => {
    this.connectSocket(params);
  };

  /**
   * 1. Socket을 연결한다.
   * @param {*} params
   */
  connectSocket = (params) => {
    console.log("soket connect.........");
    //이미 연결된 상태 일 경우 (이전 debug 이후 socket이 disconnect되지 않았을 경우)
    if (this.client.connected) {
      this.onConnectSocket(params);
      //신규 연결
    } else {
      this.socketId = StringUtils.getUuid();
      let connectHeaders = {
        Authorization: User.getConnection(this.workspace.tenantMstId, "token"),
        userId: User.getId() /* 개발자 id */,
        serviceUid: this.workflow.serviceInfo.serviceUid,
        socketId: this.socketId,
        language: User.getLanguage(),
        socketUrl: "",
      };
      if (this.connectionInfo.connectionType === "direct") {
        connectHeaders.socketUrl = ConnectionService.setPortNumberToURL(
          this.connectionInfo.runtimeProtocol +
            "://" +
            this.connectionInfo.runtimeHost
        );
      } else {
        connectHeaders.socketUrl = ConnectionService.setPortNumberToURL(
          this.connectionInfo.protocol + "://" + this.connectionInfo.host
        );
      }

      const _runtimeProtocol = User.getConnection(
        this.workspace.tenantMstId,
        "runtimeProtocol"
      );
      const _runtimeHost = User.getConnection(
        this.workspace.tenantMstId,
        "runtimeHost"
      );

      if (StringUtils.isEmpty(_runtimeHost)) {
        return Message.alert(
          "Please set the Runtime Host.",
          Enums.MessageType.ERROR
        );
      } else {
        let _targetUrl = `${_runtimeProtocol}://${_runtimeHost}`;
        if (!_targetUrl.endsWith("/")) _targetUrl += "/";
        // return console.log(connectHeaders);
        this.client = new StompJs.Client({
          //WebSocket은 ws protocol만 가능, 따라서 일반적으로 ws가 지원되지 않는 브라우저를 위해 WebSocket 대신 SockJS를 사용
          //brokerURL: "ws://localhost:81/adm/api/WorkflowSocket",

          //CORS Policy때문에 proxy를 통한 접속을 하도록 한다.
          webSocketFactory: () => new SockJS(`${_targetUrl}api/WorkflowSocket`), //연결 URL + "/api/WorkflowSocket"
          connectHeaders: connectHeaders,
          debug: function (str) {
            console.log(str);
          },
          onConnect: () => {
            this.subscribeSocket();
            this.subscribeConsoleSocket();
            this.onConnectSocket(params);
          },
          onStompError: (frame) => {
            console.error(frame);
            // 헤더 메세지
            const { message } = frame.headers;
            if (message) {
              const _message = message.split("\\c");
              Message.alert(_message[1], Enums.MessageType.ERROR);
            }
            this.stopDebug();
          },
          reconnectDelay: 0, //try to reconnct every 5000ms
          heartbeatIncoming: 4000, // receive heartbeats every 4000ms from server
          heartbeatOutgoing: 4000, // send heartbeats every 4000ms to server
        });
        this.client.activate();
      }
    }
  };

  /**
   * 2. socket 연결 성공
   *   - subscribe socket
   *   - send debug starting message
   * @param {*} params
   */
  onConnectSocket = (params) => {
    //debug 최초 시작 - message 전송
    this.sendSocketMessage("connect", params);
  };

  /**
   * 2-1 subscribe socket
   */
  subscribeSocket = () => {
    this.client.subscribe(
      "/wfDebug/wait/" + User.getId() + "/" + this.socketId,
      async ({ body }) => {
        let receivedData;
        try {
          if (body) receivedData = JSON.parse(body);
        } catch (e) {
          console.log(e);
        }
        if (!receivedData) return;

        /**
         * forceConnect 부분
         * **/
        if (StringUtils.equalsIgnoreCase(receivedData.errType, "confirm")) {
          return Message.confirm(receivedData.message, () => {
            const body = {
              requestParams: { ...this.debugParameter },
              breakpoints: this.workflowBreakpoints.filter(
                (point) => point.check
              ),
            };
            this.sendSocketMessage("forceConnect", body);
          });
        }
        if (receivedData.isError) {
          this.dispatch(setWFErrType(receivedData.errType));
          console.log("receivedData ERROR : ", receivedData);
          this.getConsoleLog();

          //message 처리
          let msg = receivedData.message || receivedData.data;
          Message.alert(msg, Enums.MessageType.ERROR);

          if (StringUtils.equalsIgnoreCase(receivedData.errType, "system")) {
            if (!StringUtils.isEmpty(receivedData.sourceTrace)) {
              const _errorTrace = JSON.parse(receivedData.sourceTrace);
              await this.traceTracker(_errorTrace);
            }
            this.stopDebug();
          } else if (
            StringUtils.equalsIgnoreCase(receivedData.errType, "invalid")
          ) {
            const { expression, expressionResult: _expressionResult } =
              receivedData.data;
            if (_expressionResult) {
              let _msg = _expressionResult.split(" ");
              _msg.splice(0, 1);
              _msg = _msg.join(" ");
              this.setDebugExprenssion({
                expression,
                result: _msg,
              });
            }
          }
        } else if (receivedData.data) {
          //connection 성공
          if (receivedData.data.messageType === "connection") {
            this.dispatch(setWFInCommunication(true));
            this.dispatch(setWFBreakpointType(""));
            this.dispatch(setWFTrace([]));
            this.dispatch(setWFProcess({}));
            if (StringUtils.equalsIgnoreCase(this.runType, "Debug")) {
              this.dispatch(setWFIsDebugging(true));
              this.setDebugExpressionMode("variable");
            }
            //variables
          } else if (receivedData.data.messageType === "variable") {
            this.dispatch(setWFInCommunication(true));
            if (StringUtils.equalsIgnoreCase(this.runType, "Debug")) {
              this.dispatch(setWFIsDebugging(true));
            }
            /**
             * trace : 지나온 길 역추적
             * process : 현재 위치
             * breakpointType : before || after 현재 브레이크 포인트 실행 전 후
             * traceTracker : 현재 추적중인 노드의 서비스를 화면에 노출함
             *  */
            const { trace, process, breakpointType, context } =
              receivedData.data;
            this.setDebugVariables(context);
            this.dispatch(setWFProcess(process));
            this.dispatch(setWFBreakpointType(breakpointType));
            //트래킹 중인 프로세스는 selected로 처리함
            this.trace = trace; //싱글톤으로 움직이고 팝업에서도 정보를 가져오기 위해 트레이스 정보를 클래스에 담아둠
            await this.traceTracker(trace);
            // process Node 포커싱
            const _processNode = document.querySelector(
              `div[data-id="${process.compId}"]`
            );
            if (_processNode) _processNode.style.zIndex = 1000;
          } else if (receivedData.data.messageType === "expression") {
            const { expression, expressionResult: result } = receivedData.data;
            /**
             * subscribeSocket 이 실행 될때 prev state를 기억하고 있어서
             * 여기서는 state의 변화만 주고 expression List는 해당 컴포넌트에서 추가하는 것으로 수정
             *  */
            if (expression) this.setDebugExprenssion({ expression, result });
          } else if (receivedData.data.messageType === "finish") {
            this.stopDebug();
            Message.alert("Debugging Complete.", Enums.MessageType.SUCCESS);
            if (!this.isDebugging) this.dispatch(setWFIsDebugging(true)); //디버깅 중 종료
            if (receivedData.data.trace)
              this.dispatch(setWFTrace(receivedData.data.trace));
          }
        }
      }
    );
  };

  /**
   * 2-2 soket Message 전송
   * @param {*} actionType ("connect, stepOver, stepInto, stepOut,disconnect,forceConnect, expression)
   * @param {*} requestParams
   */
  sendSocketMessage = (actionType, requestParams) => {
    if (!this.client.connected) {
      //정상적으로 연결되지 않은 상태...
      return;
    }
    //headers에 token 추가
    this.client.publish({
      destination: "/" + actionType,
      body: JSON.stringify(requestParams),
    });
  };

  /**
   * 디버깅 중 로그 호출로직
   * @returns
   */
  getConsoleLog = () => {
    //Console은 debugging Mode (soket이 연결되지 않은 상태)에만 실행 됩니다.
    if (ObjectUtils.isEmpty(this.client) || !this.client.connected) {
      return;
    }

    // const connection = User.getConnection(workspace.tenantMstId)
    if (
      !this.connectionInfo ||
      User.getConnection(this.workspace.tenantMstId, "expired")
    ) {
      this.setConsoleLogAutoLoad(false);
      Message.alert("Server is not connected.", Enums.MessageType.WARN);
      return this.connectionPopupOpen();
    }

    const params = {
      ...this.connectionInfo,
      ...this.logConfig,
    };
    //parameter는 preEndPoint만 필요함..
    this.sendSocketMessage("consoleLog", params);
  };

  /**
   * Debug Console 용 subscription
   * @returns
   */
  subscribeConsoleSocket = () => {
    const clientSubscription = this.client.subscribe(
      // "/wfDebug/console/" + User.getId(),
      "/wfDebug/console/" + User.getId() + "/" + this.socketId,
      async ({ body }) => {
        let receivedData;
        try {
          if (body) receivedData = JSON.parse(body);
        } catch (e) {
          console.log(e);
        }
        if (!receivedData) return;

        if (receivedData.isError === true) {
          this.setConsoleLogAutoLoad(false);
          // this.logRef.current += receivedData.message + "\n";
          this.logText += receivedData.message + "\n";
        } else {
          this.logConfig = {
            preEndPoint: receivedData.data.endPoint,
          };
          this.logText += receivedData.data.log;
        }
        this.setLog(this.logText);
      },
      { id: User.getId() }
    );
    this.debugConsoleSubscribe = clientSubscription;
  };
}

export default WorkflowDebugger;

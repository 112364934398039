import { Enums } from "components/builder/BuilderEnum";
import BuilderHeader, {
  HeaderMapDispatchToProps,
  HeaderMapStateToProps,
} from "components/builder/BuilderHeader";
import BuilderToolbarButton from "components/builder/BuilderToolbarButton";
import { updateEventHandler } from "components/builder/eventhandler/reducer/EventHandlerAction";
import UIReduxHelper from "components/builder/ui/editor/helper/UIReduxHelper";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import User from "components/common/utils/UserUtils";
import { UserShortKey } from "components/setting/section/shortKey/ShortKeyConfig";
import produce from "immer";
import EventhandlerSavePopup from "page/popup/eventhandler/EventHandlerSavePopup";
import { connect } from "react-redux";
import TechnicalDesignService from "services/technicaldesign/TechnicalDesignService";
import { setDesign } from "./../reducer/TechnicalDesignAction";

class TechnicalDesignHeader extends BuilderHeader {
  constructor(props) {
    super(props);
    this.toolbarStyle = props.toolbarStyle || "menu";
    this.state = {
      saveDropdown: false,
      showNewDropdown: false,
    };
    this.saveDesign = this.saveDesign.bind(this);
  }

  static contextType = AppContext;

  componentDidMount() {
    this.headerInit();
  }

  /**
   * 새 창
   */
  openNewBlank = () => {
    this.props.setDesign({});
  };

  /**
   * 저장
   */
  saveDesign = () => {
    const saveData = produce(this.props.technicalDesign.design, (draft) => {
      draft.designMultilang = draft.designMultilang.map((item) => {
        item.designMstId = draft.designMstId;
        item.insertUserId =
          draft.insertUserId !== null ? draft.insertUserId : User.getId();
        item.updtUserId = User.getId();
        return item;
      });
    });

    TechnicalDesignService.saveDesignMst(saveData, (result) => {
      if (result.isError) {
        Message.alert(
          "An error occurred while saving",
          Enums.MessageType.ERROR
        );
      } else {
        Message.alert("Save completed", Enums.MessageType.SUCCESS);
      }
    });
  };

  renderHeader() {
    const saveDropdownEvent = this.setDropdownEvent("saveDropdown", "edit");
    const NewTemplDropdownEvent = this.setDropdownEvent(
      "showNewDropdown",
      "new"
    );
    return (
      <>
        <li>
          <BuilderToolbarButton
            style={this.toolbarStyle}
            buttonId="new"
            onClick={NewTemplDropdownEvent}
            iconOnly={this.state.mini}
            tooltipTitle="New"
          />
          {this.state.showNewDropdown ? (
            <div
              className="toolbar-dropdown"
              ref={(element) => {
                this.dropdownMenu = element;
              }}
            >
              <ul>
                <li
                  className="dropdown-item"
                  role="button"
                  onClick={this.openNewBlank}
                  style={{ width: "250px" }}
                >
                  New (Blank)
                </li>
              </ul>
            </div>
          ) : null}
        </li>
        <li>
          <BuilderToolbarButton
            style={this.toolbarStyle}
            buttonId="edit"
            onClick={saveDropdownEvent}
            iconOnly={this.state.mini}
            tooltipTitle={"Edit"}
          />
          {this.state.saveDropdown ? (
            <div
              className="toolbar-dropdown"
              ref={(element) => {
                this.dropdownMenu = element;
              }}
            >
              <ul>
                <li
                  className="dropdown-item"
                  role="button"
                  style={{ width: "250px" }}
                  onClick={this.saveDesign}
                >
                  Save
                </li>
              </ul>
            </div>
          ) : null}
        </li>
      </>
    );
  }
}

export default connect(
  (state) => ({
    eventHandler: state.eventHandler,
    output: state.outputUI.output,
    memo: state.outputUI.memo,
    information: state.outputUI.information,
    activeComponent: state.activedUIComponent.component,
    workspace: state.workspace,
    technicalDesign: state.technicalDesign,
    ...HeaderMapStateToProps(state),
  }),
  (dispatch) => ({
    ...HeaderMapDispatchToProps(dispatch),
    setDesign: (object) => dispatch(setDesign(object)),
  })
)(TechnicalDesignHeader);

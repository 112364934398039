import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import UTextarea from "components/common/element/UTextarea";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import { useEffect, useState } from "react";
import { Col, Form, FormGroup, Row } from "react-bootstrap";
import AppService from "services/common/AppService";
import WorkflowService from "services/workflow/WorkflowService";

function WorkflowDetailPopup({
  serviceUid,
  onEditWorkflow,
  edit,
  navigate,
  remove,
  ...props
}) {
  const [selectedService, setSelectedService] = useState({});
  const [appName, setAppName] = useState("");
  const [moduleName, setModuleName] = useState("");

  useEffect(() => {
    getServiceInfo().then(getApp).then(getModuldeInfo);
  }, []);

  const getServiceInfo = () => {
    return new Promise((resolve, reject) => {
      if (serviceUid) {
        WorkflowService.getService({ serviceUid }, (res) => {
          const serviceDetail = WorkflowService.setData(res.data);
          setSelectedService(serviceDetail);
          resolve(res.data);
        });
      } else {
        reject("ServiceUid EMPTY");
      }
    });
  };

  const getApp = (service) => {
    return new Promise((resolve, reject) => {
      AppService.getApp(
        { appId: service.appId },
        (res) => {
          setAppName(res.data.appNm);
          resolve({ ...service, ...res.data });
        },
        reject
      );
    });
  };

  const getModuldeInfo = (data) => {
    return new Promise((resolve, reject) => {
      AppService.getAppModule(
        { appId: data.appId, moduleCd: data.moduleCd },
        (res) => {
          setModuleName(res.data.moduleNm);
        }
      );
    });
  };

  return (
    <Modal>
      <Modal.Header
        title={`[ ${selectedService.serviceId} ] ${selectedService.serviceName} Detail Info`}
      />
      <Modal.Body>
        <UModalTemplate>
          <Row>
            <Col xs={6}>
              <Form.Label>Application Name</Form.Label>
              <Form.Control className="mb-3" defaultValue={appName} disabled />
            </Col>
            <Col xs={6}>
              <Form.Label>Module</Form.Label>
              <Form.Control
                className="mb-3"
                defaultValue={moduleName}
                disabled
              />
            </Col>
            <Col xs={6}>
              <Form.Label>Tenant ID</Form.Label>
              <Form.Control
                className="mb-3"
                defaultValue={selectedService.tenantId}
                disabled
              />
            </Col>
            <Col xs={6}>
              <Form.Label>Company Code</Form.Label>
              <Form.Control
                className="mb-3"
                defaultValue={selectedService.coCd}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Form.Label>Service ID</Form.Label>
              <Form.Control
                className="mb-3"
                defaultValue={selectedService.serviceId}
                disabled
              />
            </Col>
            <Col xs={6}>
              <Form.Label>Service UID</Form.Label>
              <Form.Control
                className="mb-3"
                defaultValue={selectedService.serviceUid}
                disabled
              />
            </Col>
          </Row>
          <FormGroup>
            <Form.Label>Service Name</Form.Label>
            <Form.Control
              className="mb-3"
              defaultValue={selectedService.serviceName}
              disabled
            />
            <Form.Label>Service Description</Form.Label>
            <UTextarea
              popTitle="Service Description"
              textareaId="description"
              defaultValue={selectedService.description}
              codeBox={false}
              isReadonly
              rows={10}
            />
          </FormGroup>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button
          onClick={() => {
            Message.alert("Coming Soon...", Enums.MessageType.INFO);
          }}
        >
          Quick View
        </Modal.Footer.Button>
        <Modal.Footer.Button
          variant="success"
          onClick={(e) => {
            Message.confirm("Do you want to Edit Workflow?", () => {
              Popup.close();
              if (edit) {
                edit(selectedService);
                if (navigate)
                  navigate(
                    Enums.BuilderPath.WORKFLOW.MAIN +
                      "/" +
                      Enums.BuilderPath.WORKFLOW.BUILDER
                  );
              }
            });
          }}
        >
          편집
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default WorkflowDetailPopup;

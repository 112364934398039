/**
 * Redux Store에 접근하는 Action Type 꾸러미.
 * 각 Action은 독립적인 Type을 가져야 하기 때문에 중복되면 작동이 원할하지 않음
 * 그래서 각 Type을 일괄적으로 관리하고, 서로 겹치는 일을 피하기 위해서
 * 한개의 오브젝트에서 일괄적으로 관리
 */
const ActionType = {
  /**
   * UI BUilder 관련
   * 프로그램 에디터 기능 포함
   */
  UI: {
    CREATE_PAGE: "CREATE_PAGE_UI",
    UPDATE_OUTPUT: "UPDATE_OUTPUT_UI",
    UPDATE_PROGRAM_INFORMATION: "UPDATE_PROGRAM_INFORMATION",
    INIT_OUTPUT: "INIT_OUTPUT",
    SET_PROGRAM_MEMO: "SET_PROGRAM_MEMO",
    ADD_PROGRAM_MEMO: "ADD_PROGRAM_MEMO",
    UPDATE_PROGRAM_MEMO: "UPDATE_PROGRAM_MEMO",
    DELETE_PROGRAM_MEMO: "DELETE_PROGRAM_MEMO",
    ACTIVATE_UI: "ACTIVATE_UI",
    UPDATE_ACTIVATE_PROPS_UI: "UPDATE_ACTIVATE_PROPS_UI",
    UPDATE_ACTIVATE_EDITOR_ATTR: "UPDATE_ACTIVATE_EDITOR_ATTR",
    DEACTIVATE_UI: "DEACTIVATE_UI",
    INIT_ACTIVE_UI: "INIT_ACTIVE_UI",
    SET_BUILDER_TREE_NODE_ID: "SET_BUILDER_TREE_NODE_ID",
    SET_TREE_OPEN: "SET_TREE_OPEN",
    SET_UI_OUTPUT: "SET_UI_OUTPUT",
    GET_UI_OUTPUT: "GET_UI_OUTPUT",
  },

  MOBILE: {
    IS_MOBILE_EDITOR: "IS_MOBILE_EDITOR",
    SCREEN_WIDTH: "SCREEN_WIDTH",
    SCREEN_HEIGHT: "SCREEN_HEIGHT",
    SET_MOBILE_OUTPUT: "SET_MOBILE_OUTPUT",
    GET_MOBILE_OUTPUT: "GET_MOBILE_OUTPUT",
    SET_APP_LAYOUT: "SET_APP_LAYOUT",
  },

  /**
   * 데이터 모델 관련
   */
  DATA_MODEL: {
    UPDATE: "UPDATE_DATA_MODEL",
    INIT: "INIT_DATA_MODEL",
    ACTIVATE: {
      ENTITY: "ACTIVATE_ENTITY",
      PROPERTY_UPDATE: "ACTIVATE_PROPERTY_UPDATE",
      INIT: "INIT_ACTIVED_ENTITY",
    },
  },

  /**
   * Workflow 관련
   * Debug 포함
   */
  WORKFLOW: {
    INIT: "WORKFLOW_INIT",
    ADD_MEMO: "ADD_MEMO",
    UPDATE_MEMO: "UPDATE_MEMO",
    DELETE_MEMO: "DELETE_MEMO",
    CREATE: "CREATE_NEW_SERVICE",
    PROCESS: {
      ADD: "ADD_PROCESS",
      DELETE: "DELETE_PROCESS",
      UPDATE: "UPDATE_PROCESS",
    },
    CONNECTOR: {
      ADD: "ADD_CONNECTOR",
      DELETE: "DELETE_CONNECTOR",
      UPDATE: "UPDATE_CONNECTOR",
    },
    UPDATE: "UPDATE_WORKFLOW",
    UPDATE_OUTPUT: "UPDATE_WORKFLOW_OUTPUT",
    UPDATE_SERVICE: "UPDATE_SERVICE",
    UPDATE_VIEWPORT: "UPDATE_VIEWPORT",
    SET_CS_FILE_INFO: "SET_CS_FILE_INFO",
    UPDATE_CS_FILE_INFO: "UPDATE_CS_FILE_INFO",
    SET_LOG_VIEW_MODE: "SET_LOG_VIEW_MODE",
    LOG_STATE_CHANGE: "LOG_STATE_CHANGE",
    UPDATE_CS_SERVICE: "UPDATE_CS_SERVICE",
    SET_CS_SERVICE: "SET_CS_SERVICE",
    UPDATE_BUNDLE: "UPDATE_BUNDLE",
    UPDATE_BUNDLE_VIEWPORT: "UPDATE_BUNDLE_VIEWPORT",

    DEBUG: {
      BREAK_POINT: {
        INIT: "INIT_BREAK_POINT",
        ADD: "ADD_BREAK_POINT",
        UPDATE: "UPDATE_BREAK_POINT",
        DELETE: "DELETE_BREAK_POINT",
        SET: "SET_BREAK_POINT",
        SET_TYPE: "SET_BREAK_POINT_TYPE",
      },
      INIT: "INIT_DEBUG",
      SET_IN_COMMUNICATION: "SET_IN_COMMUNICATION",
      SET_TRACE: "SET_TRACE",
      SET_PROCESS: "SET_PROCESS",
      SET_IS_DEBUGGING: "SET_IS_DEBUGGING",
      SET_ERROR_TYPE: "SET_ERROR_TYPE",
    },
  },
  /**
   * Technical Design 관련
   */
  PROGRAM_DESIGN: {
    SET_DESIGN: "SET_DESIGN",
    SET_DESIGN_LAYOUT_COLUMN: "SET_DESIGN_LAYOUT_COLUMN",
  },
  /**
   * Workspace 관련
   */
  WORKSPACE: {
    SET: "SET_WORKSPACE",
    INIT: "INIT_WORKSPACE",
  },

  /**
   * Menu
   * 사이드마 메뉴
   * 전체 검색 관련
   */
  MENU: {
    SET_SIDEBAR_COLLAPSE: "SET_SIDEBAR_COLLAPSE",
    INIT: "INIT_MENU",
    SEARCH_TERM: {
      PROGRAM: "SET_PROGRAM_SEARCH_TERM",
      DATA_MODEL: "SET_DATA_MODEL_SEARCH_TERM",
      WORKFLOW: "SET_WORKFLOW_SEARCH_TERM",
    },
  },

  //Setting - 설정 관련
  SETTING: {
    INIT: "INIT_SETTING",
    STYLE: {
      SET: "SET_STYLE",
    },
  },

  /**
   * 이벤트 핸들러 관련
   */
  EVENT_HANDLER: {
    WORKSPACE: {
      INIT: "INIT_EVENT_WORKSPACE",
      UPDATE: "UPDATE_EVENT_WORKSPACE",
      SET_IS_INIT: "SET_IS_INIT_EVENT_WORKSPACE",
    },
    INIT: "INIT_EVENT_HANDLER",
    UPDATE_HANDLER: "UPDATE_EVENT_HANDLER",
    UPDATE_OUTPUT: "UPDATE_EVENT_OUTPUT",
    UPDATE_VARIABLES: "UPDATE_EVENT_VARIABLES",
    UPDATE_COMPONENT_PROP: "UPDATE_EVENT_COMPONENT_PROP",
  },

  /**
   * ERD 관련
   */
  ERD: {
    INIT: "INIT_ERD",
    UPDATE: "UPDATE_ERD",
    EDIT: "EDIT_ERD",
    CREATE: "CREATE_ERD",
    SELECT: "SELECT_ERD",
    UPDATE_VIEWPORT: "UPDATE_ERD_VIEWPORT",
  },
};

export default ActionType;

import { Enums } from "components/builder/BuilderEnum";
import UIComponent from "components/builder/ui/uiComponents/UIComponent";
import {
  PropertiesHeader,
  PropertyLable,
  PropertyValue,
} from "components/builder/ui/uiComponents/UIComponentStyle";
import { AppContext } from "components/common/AppContextProvider";
import UPopover from "components/common/element/UPopover";
import USelectbox from "components/common/element/USelectbox";
import Popup from "components/common/Popup";
import ArrayUtils from "components/common/utils/ArrayUtils";
import JsonUtils from "components/common/utils/JsonUtils";
import StringUtils from "components/common/utils/StringUtils";
import { ComponentSavePopupButton } from "page/popup/ComponentSavePopup";
import DefaultInputPopup from "page/popup/DefaultInputPopup";
import ProcedureConfigPopup from "page/popup/ProcedureConfigPopup";
import { Fragment } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import DataModelService from "services/datamodel/DataModelService";

class FormComponent extends UIComponent {
  constructor(props) {
    super(props);
    this.AppContext = AppContext;
    this.openDefaultPopup = this.openDefaultPopup.bind(this);
    this.onWorkflowDetailClick = this.onWorkflowDetailClick.bind(this);
    this.state = {
      ...this.state,
      comboBindingList: {},
      componentOperator: {},
    };
  }

  static contextType = AppContext;

  /**
   * Component Type변경
   * @param {*} event
   */
  onChangeComponentType = (event) => {
    this.props.fn.changeComponentType(event.target.value);
  };

  /**
   * Default popup
   * @param {*} e
   */
  openDefaultPopup = (e) => {
    //팝업창 열기
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "45%",
        },
      },
    };

    let id = StringUtils.defaultString(e.target.id, e.currentTarget.formTarget);

    Popup.open(
      <DefaultInputPopup
        id={id}
        onChooseDefaultValue={(result) => {
          this.setInputValue("input", result.target.id, result.target.value);
        }}
      />,
      options
    );
    if (e) e.preventDefault();
  };

  /**
   * insertOption return
   * @param {Object} _propertyValue
   * @returns {Object} insertOption
   */
  settingInsertOption = (_propertyValue) => {
    const insertOption = {};

    insertOption.arguments = _propertyValue.parameterList.map((_param) => {
      let type =
        _param.parameterType.indexOf("int") > -1 ||
        _param.parameterType.indexOf("number") > -1 ||
        _param.parameterType.indexOf("long") > -1 ||
        _param.parameterType.indexOf("double") > -1 ||
        _param.parameterType.indexOf("float") > -1
          ? "INTEGER"
          : "STRING";
      return {
        mode: "IN",
        name: _param.parameterName,
        type: type,
      };
    });
    insertOption.procedureName = _propertyValue.procedureName;

    return insertOption;
  };

  /**
   * 프로시져 등록 상세 팝업
   * @param {*} event
   * @param {*} entityId
   */
  onNumProcDetailClick = (event, entityId) => {
    const options = {
      style: {
        content: {
          width: "60%",
        },
      },
    };

    Popup.open(
      <ProcedureConfigPopup
        title="Procedure Call Setting"
        propertyValue={this.state.propertyValue}
        procedureName={JsonUtils.defaultString(
          this.state.propertyValue.insertOption,
          "procedureName"
        )}
        procedureOptions={this.state.propertyValue.insertOption}
        entityId={entityId ? entityId : this.getEntity()}
        readonly={
          this.state.propertyValue.autoNumberingType ===
          Enums.Numbering.STANDARD
        }
        callbackFnc={(data) => {
          this.onChangePropertyValue("insertOption", data);
        }}
      />,
      options
    );
  };

  /**
   * 해당 Component의 Title
   * @param {*} child
   * @param {boolean} saveable 저장가능 여부 default true
   * @returns
   */
  renderComponentTitle = (child, saveable = true) => {
    return (
      <PropertiesHeader isMobile={this.props.mobile.isMobileEditor}>
        <div>
          {"Form Components > "}
          {child}
        </div>
        {saveable && (
          <ComponentSavePopupButton
            getComponentCodeFromOutput={
              this.props.fn.getComponentCodeFromOutput
            }
            componentInfo={this.props.componentInfo}
          />
        )}
      </PropertiesHeader>
    );
  };

  /**
   * 해당 Component의 Title
   * @param {*} childCompClass
   * @param {*} previousText
   * @returns
   */
  renderChangeableComponentTitle = (childCompClass, previousText) => {
    let title = "Form  > " + (previousText ? previousText + " > " : "");
    return (
      <PropertiesHeader
        className="properties-header"
        isMobile={this.props.mobile.isMobileEditor}
      >
        <div>
          {title}
          <USelectbox
            onChange={this.onChangeComponentType}
            defaultValue={childCompClass}
            items={[
              { text: "Input", id: "form/Input" },
              { text: "Select", id: "form/Select" },
              { text: "Textarea", id: "form/Textarea" },
              { text: "TextEditor", id: "form/TextEditor" },
              { text: "Checkbox", id: "form/Checkbox" },
              { text: "Radio Button", id: "form/RadioButton" },
              { text: "Single Date Picker", id: "form/SingleDatePicker" },
              { text: "Range Date Picker", id: "form/RangeDatePicker" },
              { text: "Text", id: "form/Text" },
            ]}
            options={{ matchCd: "id", matchNm: "text" }}
          />
        </div>
        <ComponentSavePopupButton
          getComponentCodeFromOutput={this.props.fn.getComponentCodeFromOutput}
          componentInfo={this.props.componentInfo}
        />
      </PropertiesHeader>
    );
  };

  /**
   * Combo의 Path (selectedValuePath, displayMemberPath, headerPath) 설정화면을 그린다.
   * @returns
   */
  renderComboPathBinding = () => {
    if (this.state.propertyValue.searchTp === "ENTITY") {
      this.setComboBindingList();
    }

    return (
      <Fragment>
        {this.state.propertyValue.searchTp === "ENTITY" ? (
          <Fragment>
            <PropertyLable>Value Field</PropertyLable>
            <PropertyValue requried="true">
              <USelectbox
                type="static"
                id="idColumn"
                defaultValue={StringUtils.defaultString(
                  this.state.propertyValue.idColumn
                )}
                onChange={this.onChange}
                items={
                  this.state.comboBindingList[
                    this.state.propertyValue.comboEntityId
                  ]
                }
                options={{
                  matchId: "id",
                  matchNm: "text",
                }}
              />
            </PropertyValue>
            <PropertyLable requried="true">Text Field</PropertyLable>
            <PropertyValue>
              <USelectbox
                type="static"
                id="textColumn"
                defaultValue={StringUtils.defaultString(
                  this.state.propertyValue.textColumn
                )}
                onChange={this.onChange}
                items={
                  this.state.comboBindingList[
                    this.state.propertyValue.comboEntityId
                  ]
                }
                options={{
                  matchId: "id",
                  matchNm: "text",
                }}
              />
            </PropertyValue>
            {this.state.propertyValue.idColumn === "id" &&
            this.state.propertyValue.textColumn === "text"
              ? this.renderComboDisplayTextRender()
              : ""}
          </Fragment>
        ) : (
          <Fragment>
            <PropertyLable requried="true">Value Field</PropertyLable>
            <PropertyValue>
              <FormControl
                type="text"
                id="idColumn"
                defaultValue={StringUtils.defaultString(
                  this.state.propertyValue.idColumn,
                  "id"
                )}
                className="form-control form-control-sm"
                onBlur={this.onChange}
                readOnly={
                  ["TABLE", "UNIERP"].indexOf(
                    this.state.propertyValue.searchTp
                  ) !== -1
                }
              />
            </PropertyValue>
            <PropertyLable requried="true">Text Field</PropertyLable>
            <PropertyValue>
              <FormControl
                type="text"
                id="textColumn"
                defaultValue={StringUtils.defaultString(
                  this.state.propertyValue.textColumn,
                  "text"
                )}
                className="form-control form-control-sm"
                onBlur={this.onChange}
                readOnly={
                  ["TABLE", "UNIERP"].indexOf(
                    this.state.propertyValue.searchTp
                  ) !== -1
                }
              />
            </PropertyValue>
            {this.state.propertyValue.searchTp === "TABLE" ||
            this.state.propertyValue.searchTp === "UNIERP"
              ? this.renderComboDisplayTextRender()
              : ""}
          </Fragment>
        )}
      </Fragment>
    );
  };

  /**
   * set Combo binding Field List
   */
  setComboBindingList = () => {
    if (
      !StringUtils.isEmpty(this.state.propertyValue.comboEntityId) &&
      ArrayUtils.isEmpty(
        this.state.comboBindingList[this.state.propertyValue.comboEntityId]
      )
    ) {
      DataModelService.getDataBindingList(
        { entityId: this.state.propertyValue.comboEntityId },
        (res) => {
          let newBindingFields = res.data
            ? res.data
            : [{ id: "none", text: "[none] None Data" }];

          this.setState({
            comboBindingList: {
              [this.state.propertyValue.comboEntityId]: newBindingFields,
            },
          });
        }
      );
    }
  };

  /**
   * combo displayText Render 설정
   * @returns
   */
  renderComboDisplayTextRender = () => {
    return (
      <Fragment>
        <PropertyLable>Text Template</PropertyLable>
        <PropertyValue>
          <InputGroup>
            <FormControl
              id="textRender"
              type="text"
              defaultValue={StringUtils.defaultString(
                this.state.propertyValue.textRender
              )}
              className="form-control form-control-sm"
              onBlur={this.onChange}
            />
            <UPopover title="Usage Example">
              <ul>
                <li>
                  <font color="red">Text shown on combo selection</font>
                  <br></br>
                  Applies only when the Value Column is 'id' and the Text Column
                  is 'text'.
                </li>
                <li>
                  <strong>▣ e.g.1) [Code] CodeNm </strong>
                </li>
                <li>"["+$data.id+"] "+$data.text</li>
              </ul>
            </UPopover>
          </InputGroup>
        </PropertyValue>
      </Fragment>
    );
  };
}

export default FormComponent;

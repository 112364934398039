/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import Api from "components/common/Api";
import ArrayUtils from "components/common/utils/ArrayUtils.js";
import { FaDotCircle } from "react-icons/fa";
import { Tabs, Tab, Nav, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import { Divider, InputLabel, Select } from "@mui/material";
import ModuleSelector from "components/common/element/ModuleSelector";
import User from "components/common/utils/UserUtils";
import ProgramService from "services/ui/ProgramService";
import CommonUtils from "components/common/utils/CommonUtils";

const TemplatePopupStyle = styled.div`
  /* template CSS */
  .tmpl-content .tmpl-title {
    font-size: 21px;
    margin-bottom: 5px;
  }
  .tmpl-content .tmpl-title svg {
    color: #23a41a;
    vertical-align: middle;
    font-size: 14px;
  }
  .tmpl-content .tmpl-col {
    padding: 5px;
  }
  .tmpl-content .tmpl-col button {
    border: none;
    background: none;
    text-align: left;
    padding: 8px 10px 0px 10px;
    width: 100%;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .tmpl-content .tmpl-col button:hover {
    background-color: #cbcbcb24;
    border-radius: 8px;
  }
  .tmpl-content .tmpl-image {
    width: 100%;
    height: 100%;
    border: solid 1px #d1d1d1;
    margin-bottom: 8px;
    border-radius: 8px;
    text-align: center;
    font-size: 700%;
    color: gray;
    background-color: #fff;
  }
  .tmpl-content .tmpl-image.selected {
    border: 1px solid #4374d9;
  }
  .tmpl-content .tmpl-image.tmpl-image-text p {
    vertical-align: middle;
  }
  .modal-body {
    height: 100%;
  }

  /* Nav CSS */
  .nav-link:hover {
    cursor: pointer;
  }
  .nav-tabs {
    border: none;
  }
  .nav-link {
    padding: 6px;
    border-botton: 1px solid #f6f6f6;
    color: #495057;
    background-color: #f6f6f6;
  }
  .flex-column > .nav-link.active {
    border-left: 3px solid #4374d9;
    border-top: none;
    border-right: none;
  }
  .nav-link.active {
    padding: 6px;
    border: none;
    border-top: 3px solid #4374d9;
    color: #4374d9;
  }

  .ml-10 {
    margin-left: 10px;
  }
  .mb-3 {
    margin-bottom: 3px;
  }
  .pt-2 {
    pagging-top: 2px;
  }
  .h-full {
    height: 100%;
  }
  .program_description .description {
    width: 100%;
    height: 50px;
    border: solid 1px #d1d1d1;
    border-radius: 8px;
    color: gray;
    background-color: #fff;
    overflow-y: auto;
  }
`;

const TemplatePopup = (props) => {
  const [tmplList, setTmplList] = useState({});
  const [shareList, setShareList] = useState([]);
  const { title, callbackFnc, templateType, user } = props;

  const [selectedTmpl, setSelectedTmpl] = useState();
  const componentDidMount = () => {
    /* 표준/공유 Template 조회 */
    const params = {
      useYn: "Y",
    };
    Api.post("/popup/getTemplateList", params, (res) => {
      setTmplList({
        standardList: res.data.filter((code) => code.templateType === "B"),
      });
    });
    ProgramService.getShareProgramList({}, (res) => {
      setShareList(res.data);
    });
  };

  /**
   * eventClickTmpl: 팝업을 닫으면서 output 전달
   */
  const eventClickTmpl = (e) => {
    const dataset = e.currentTarget.dataset;
    let tmplOutput = "";
    if (dataset.tmplType === "B") {
      tmplOutput = tmplList.standardList.filter(
        (code) => code.templateId === dataset.tmplId
      )[0].templateOutput;
      callbackFnc.call(this, tmplOutput);
      Popup.close();
    } else {
      ProgramService.getProgram(
        { programUid: selectedTmpl.programUid },
        (res) => {
          const { programContent } = res.data;
          tmplOutput = programContent;
          callbackFnc.call(this, tmplOutput);
          Popup.close();
        },
        () => {
          throw new Error("Error occured while loading program.");
        }
      );
    }
  };

  useEffect(() => {
    componentDidMount();
    /*
    //esc key popup.close();
    document.addEventListener('keydown', (e) => {
      console.log('key event', e.key);
      if (e.key === 'Escape') {
        Popup.close();
      }
    });
    */
  }, []);

  const onSelectTmpl = (value) => {
    setSelectedTmpl(value);
  };

  return (
    <TemplatePopupStyle>
      <Modal>
        <Modal.Header title={title} />
        <Modal.Body class="h-full">
          <div className="tab-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <Tab.Container
                    defaultActiveKey={templateType || "StandardTmpl"}
                  >
                    <Row>
                      <Col sm={1}>
                        <Nav justify variant="tabs" className="flex-column">
                          <Nav.Link eventKey="StandardTmpl">
                            Standard Template
                          </Nav.Link>
                          <Nav.Link eventKey="ShareTmpl">
                            Share Template
                          </Nav.Link>
                        </Nav>
                      </Col>
                      <Col sm={11}>
                        <Tab.Content className="tmpl-content">
                          <Tab.Pane eventKey="StandardTmpl">
                            <div className="tmpl-title">
                              <FaDotCircle /> Standard Template
                            </div>
                            <div className="row tmpl-list">
                              {ArrayUtils.isEmpty(tmplList.standardList)
                                ? ""
                                : tmplList.standardList.map((tmpl, index) => (
                                    <div
                                      className="col col-3 tmpl-col"
                                      key={tmpl.templateId}
                                    >
                                      <button
                                        onClick={eventClickTmpl}
                                        data-tmpl-type={tmpl.templateType}
                                        data-tmpl-id={tmpl.templateId}
                                      >
                                        <img
                                          className="tmpl-image"
                                          src={
                                            process.env.PUBLIC_URL +
                                            `/assets/images/${tmpl.templateId}.jpg`
                                          }
                                        />
                                        <p className="ml-10 mb-3">
                                          - {tmpl.templateNm}
                                        </p>
                                      </button>
                                    </div>
                                  ))}
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="ShareTmpl">
                            <div className="content">
                              <div className="tmpl-title">
                                <FaDotCircle /> Share Template
                              </div>
                              <div className="row">
                                <div className="col col-12">
                                  <div
                                    className="row"
                                    style={{
                                      height: "430px",
                                      overflowY: "auto",
                                    }}
                                  >
                                    {ArrayUtils.isEmpty(shareList)
                                      ? ""
                                      : shareList.map((tmpl) => {
                                          return (
                                            <div
                                              className="col col-2 tmpl-col pt-2"
                                              key={tmpl.programUid}
                                            >
                                              <button
                                                onDoubleClick={eventClickTmpl}
                                                onClick={() =>
                                                  onSelectTmpl(tmpl)
                                                }
                                                data-tmpl-type={
                                                  tmpl.templateType || "S"
                                                }
                                                data-tmpl-id={tmpl.programId}
                                              >
                                                <div
                                                  className={`tmpl-image ${
                                                    selectedTmpl?.programUid ===
                                                    tmpl.programUid
                                                      ? "selected"
                                                      : ""
                                                  } `}
                                                >
                                                  <p>
                                                    {tmpl.programNm == null
                                                      ? " "
                                                      : tmpl.programNm.substring(
                                                          0,
                                                          1
                                                        )}
                                                  </p>
                                                </div>

                                                <p className="ml-10 mb-3">
                                                  - ({tmpl.moduleCd}){" "}
                                                  {tmpl.programNm}
                                                </p>
                                              </button>
                                            </div>
                                          );
                                        })}
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="program_description">
                                    <Divider textAlign="left">
                                      Module Information
                                    </Divider>
                                    {selectedTmpl ? (
                                      <div className="row gy-1">
                                        <div className="col-6 title">
                                          Program ID
                                        </div>
                                        <div className="col-6 title">
                                          Program Name
                                        </div>
                                        <div className="col-6">
                                          {selectedTmpl.templateId}
                                        </div>
                                        <div className="col-6">
                                          {selectedTmpl.templateNm}
                                        </div>
                                        <div className="col-6 title">
                                          Writer
                                        </div>
                                        <div className="col-6 title">
                                          Write Date
                                        </div>
                                        <div className="col-6">
                                          {selectedTmpl.insertUserId}
                                        </div>
                                        <div className="col-6">
                                          {CommonUtils.getDate(
                                            selectedTmpl.insertDt,
                                            "datetime"
                                          )}
                                        </div>
                                        <div className="col-6 title">
                                          Update User
                                        </div>
                                        <div className="col-6 title">
                                          Update Date
                                        </div>
                                        <div className="col-6">
                                          {selectedTmpl.updtUserId}
                                        </div>
                                        <div className="col-6">
                                          {CommonUtils.getDate(
                                            selectedTmpl.updtDt,
                                            "datetime"
                                          )}
                                        </div>
                                        <div className="col-12 title">
                                          Program Description
                                        </div>
                                        <div className="col-12 description">
                                          <div className="content">
                                            {selectedTmpl.description
                                              ? selectedTmpl.description
                                              : "No Description."}
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="fixed" />
      </Modal>
    </TemplatePopupStyle>
  );
};

export default TemplatePopup;

import React, { Component, Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Scrollbar } from "smooth-scrollbar-react";
import { AppContext } from "components/common/AppContextProvider";
import * as Enums from "components/builder/BuilderEnum";
import {
  ObjectUtils,
  JsonUtils,
  ArrayUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import UIReduxHelper from "components/builder/ui/editor/helper/UIReduxHelper";
import UITemplateHelper from "components/builder/ui/editor/helper/UITemplateHelper";
import * as Event from "components/builder/ui/editor/handler/UIEditorEventHandler";
import { initCommand } from "./reducers/CommandAction";
import { initDebug } from "../workflow/reducer/WorkflowDebugAction";
import { updateService } from "../workflow/reducer/WorkflowAction";
import {
  updateActivateEditorAttr,
  updateActivateProps,
} from "./reducers/UIBuilderAction";
import produce from "immer";
import Popup from "components/common/Popup";
import {
  updateEventOutput,
  updateEventWorkspace,
  updateEventWorkspacePath,
} from "../eventhandler/reducer/EventHandlerAction";

class PropertiesTab extends Component {
  state = {
    refresh: false,
  };

  static contextType = AppContext;
  components = this.context.component.getComponentList("B"); //모든 표준 component들
  pageCompnent = this.context.component.getPageComponent(); //page component

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      nextState.refresh === true ||
      this.props.component.compId !== nextProps.component.compId
    );
  };
  componentDidUpdate = () => {
    this.setState({ refresh: false });
  };
  /**
   * update output
   * @param {String} compId
   * @param {String} propertyValue
   */
  updateProperty = (propertyValue, targetComponent, refresh) => {
    if (this.props.component.componentType === Enums.ComponentType.PAGE) {
      //popup page이고 footer template을 만들어야 하는 경우 base template component를 넘긴다.
      if (propertyValue.programType === Enums.ProgramType.POPUP_PROGRAM) {
        const popupOptions = propertyValue.popupOptions || {};
        let footerTemplate;
        //footer template 생성
        if (
          popupOptions.displayFooter !== false &&
          ObjectUtils.isEmpty(this.props.output.page.footer)
        ) {
          /* template 용 표준 base components 로 popup footer template을 만든다. */
          footerTemplate = UITemplateHelper.getPopupFooterTemplate(
            this.context,
            popupOptions
          );
          //page propertyValue update & footer children 추가 with template
          this.props.updatePageWithFooter(
            this.props.output,
            this.props.component,
            footerTemplate,
            propertyValue
          );
          //footer 제거
        } else if (
          popupOptions.displayFooter === false &&
          !ObjectUtils.isEmpty(this.props.output.page.footer)
        ) {
          //page propertyValue update & footer children 제거
          this.props.updatePageWithFooter(
            this.props.output,
            this.props.component,
            null,
            propertyValue
          );

          //page propertyValue만 update
        } else {
          this.props.updatePage(
            this.props.output,
            this.props.component,
            propertyValue
          );
        }
        //Menu Page/Tab Page component
      } else {
        //page propertyValue만 update
        this.props.updatePage(
          this.props.output,
          this.props.component,
          propertyValue
        );
      }
      //page 외 component의 변경
    } else {
      this.props.updateOutput(
        this.props.output,
        this.props.component,
        propertyValue,
        targetComponent
      );
    }
    if (refresh === true) this.refresh();
  };

  /**
   * Component 추가
   * @param {Map} propertyValue
   * @param {String} type "B" : Before, "A" : After
   * @param {Object} target 복사 대상 component
   */
  createComponent = (output, propertyValue, type, target, targetComponents) => {
    if (ObjectUtils.isEmpty(target)) target = this.props.component;
    if (ObjectUtils.isEmpty(targetComponents))
      targetComponents = this.props.component;
    if (ObjectUtils.isEmpty(output)) output = this.props.output;
    this.props.createComponent(
      output,
      target,
      this.components,
      propertyValue,
      type,
      targetComponents
    );
    this.refresh();
  };

  /**
   * Component 추가
   * @param {Map} propertyValue
   * @param {String} type "B" : Before, "A" : After
   * @param {Object} target 복사 대상 component
   */
  addComponent = (propertyValue, type, target) => {
    if (ObjectUtils.isEmpty(target)) target = this.props.component;
    this.props.addComponent(
      this.props.output,
      target,
      this.components,
      propertyValue,
      type,
      this.props.component
    );
    this.refresh();
  };

  /**
   * Component 삭제
   * @param {Object} target 복사 대상 component
   */
  deleteComponent = (target, output) => {
    if (ObjectUtils.isEmpty(output)) output = this.props.output;
    this.props.deleteComponent(
      output,
      target,
      this.components,
      this.pageCompnent
    );
    this.refresh();
  };

  /**
   * properties tab 강제 refresh
   */
  refresh = () => {
    this.setState({ refresh: true });
  };
  /**
   * Component Type 변경
   * @param {*} newComponentClass
   * @param {Object} target 변경 대상 component
   */
  changeComponentType = (newComponentClass, target) => {
    if (ObjectUtils.isEmpty(target)) target = this.props.component;

    const newComponent = UITemplateHelper.getComponentInfo(
      this.context,
      newComponentClass
    );
    if (newComponent) {
      this.props.changeComponentType(this.props.output, target, newComponent);
    }
  };

  /**
   * get Data Model
   */
  getDataModel = () => {
    if (
      this.props.component.type === Enums.ComponentType.GRID_CELL ||
      this.props.component.type === Enums.ComponentType.GRID_HEADER
    ) {
      return JsonUtils.findGridDataModel(
        this.props.output,
        this.props.component.gridId,
        true
      );
    }

    return JsonUtils.findDataModel(
      this.props.output,
      this.props.component.compId
    );
  };

  /**
   * get Data Model Entity
   */
  getEntity = () => {
    const parentForm = this.getForm();
    return !ObjectUtils.isEmpty(parentForm["propertyValue"])
      ? parentForm.propertyValue.dataModelEntityId
      : "";
  };

  /**
   * get Data Model Entity
   */
  getEntityType = () => {
    const parentForm = this.getForm();
    return !ObjectUtils.isEmpty(parentForm["propertyValue"])
      ? parentForm.propertyValue.entityType
      : "TABLE";
  };
  isTableType = () => {
    return !StringUtils.equalsIgnoreCase(this.getEntityType(), "PROCEDURE")
      ? true
      : false;
  };

  /**
   * 현재 active 되어있는 component 기준으로 상위 From을 가져온다.
   * @returns parentForm
   */
  getForm = () => {
    const parentForm = JsonUtils.findParentNode(
      this.props.output,
      "compId",
      this.props.component.compId,
      "type",
      Enums.ComponentType.FORM
    );

    return parentForm;
  };

  getGridInfo = () => {
    //현재 선택중인 타입 유형
    const activeType =
      this.props.component.type || this.props.component.componentType;

    if (StringUtils.equalsIgnoreCase(activeType, Enums.ComponentType.GRID)) {
      //그리드 선택 했을때
      const grid = JsonUtils.findNode(
        this.props.output,
        "compId",
        this.props.component.compId
      );
      return grid;
    } else if (
      StringUtils.equalsIgnoreCase(
        activeType,
        Enums.ComponentType.GRID_HEADER
      ) ||
      StringUtils.equalsIgnoreCase(activeType, Enums.ComponentType.GRID_CELL)
    ) {
      //그리드의 헤더나 셀 선택했을때
      const cellCompId = this.props.component.compId
        ? this.props.component.compId.split("-")[1]
        : "";
      const grid = JsonUtils.findParentNode(
        this.props.output,
        "compId",
        cellCompId,
        "type",
        Enums.ComponentType.GRID
      );
      return grid;
    } else {
      return null;
    }
  };

  /**
   * 그리드에 사용된 엔티티 타입
   * @returns
   */
  getGridEntityType = () => {
    const grid = this.getGridInfo();
    if (grid) {
      return grid.propertyValue.gridOptions.entityType;
    } else {
      return "";
    }
  };
  /**
   * 그리드에 사용된 엔티티 타입이 테이블 타입? 프로시져?
   * @returns
   */
  isGridEntityTableType = () => {
    return StringUtils.equalsIgnoreCase(
      this.getGridEntityType(),
      "PROCEDURE"
    ) || StringUtils.equalsIgnoreCase(this.getGridEntityType(), "FUNCTION")
      ? false
      : true;
  };

  /**
   * Event 대상 component를 조회한다.
   * @param {*} componentTypes
   * @returns
   */
  getEventTargetNode = (componentTypes, parentFormPriority, excludeTypes) => {
    let targetNodes = [];
    JsonUtils.findEventTargetNods(
      this.props.output,
      componentTypes,
      excludeTypes,
      targetNodes
    );

    if (!ArrayUtils.isEmpty(targetNodes) && parentFormPriority === true) {
      const parentForm = JsonUtils.findParentNode(
        this.props.output,
        "compId",
        this.props.component.compId,
        "type",
        Enums.ComponentType.FORM
      );
      const index = ArrayUtils.getIndex(
        targetNodes,
        "compId",
        parentForm.compId
      );
      if (index > -1) {
        targetNodes[index]["default"] = true;
      }
    }
    return targetNodes;
  };

  /**
   * output에 있는 코드중 해당  component_ID 의 에디터 코드를 가져온다.
   * @param {String} component_ID
   * @returns {Object}
   */
  getComponentCodeFromOutput = (compId) => {
    try {
      const Object = JsonUtils.findNode(this.props.output, "compId", compId);
      return Object;
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * 현재 component의 하위 자식 component 목록을 가져온다.
   * @returns
   */
  getChild = () => {
    const currentNode = JsonUtils.findNode(
      this.props.output,
      "compId",
      this.props.component.compId
    );
    return ObjectUtils.isEmpty(currentNode) ? [] : currentNode.child || [];
  };

  /**
   * EditorAttr 업데이트
   * @param {String} compId
   * @param {*} editorAttr
   */
  updateEditorAttr = (compId, editorAttr) => {
    const newOutput = produce(this.props.output, (draft) => {
      const targetNode = JsonUtils.findNode(draft, "compId", compId);
      if (targetNode) {
        targetNode.editorAttr = editorAttr;
      }
    });
    this.props.updateOutputUI(newOutput);
    this.props.updateActivateEditorAttr(editorAttr);
  };

  /**
   * ActiveTarget Node 업데이트
   * propertyValue와 EntityAttr을 함께 업데이트 하는 경우에 사용
   * @param {String} compId
   * @param {*} editorAttr
   */
  updateNodeInfo = (compId, { propertyValue, editorAttr, ...others }) => {
    const newOutput = produce(this.props.output, (draft) => {
      let targetNode = JsonUtils.findNode(draft, "compId", compId);
      if (targetNode) {
        targetNode.propertyValue = propertyValue;
        for (const key in others) {
          targetNode[key] = others[key];
        }
      }
    });
    this.props.updateOutputUI(newOutput);
    this.props.updateActivateProps(propertyValue);
  };

  /**
   *
   * UI Builder Main에서 사용
   * JS 이벤트 설정화면에서 바로 빌더로 이동하는 메서드
   * @param {Object} eventWorkspace : 함수 처리형
   */
  onClickEventBuilder = (compProps) => {
    const { eventWorkspace, ...others } = compProps;
    this.props.updateEventWorkspacePath({
      ...{
        eventType: Enums.EventHandlerEventType.USR_EVENT_FUNCTION,
        targetType: "all",
        programType: "M",
        ...others,
      },
    });

    if (!ObjectUtils.isEmpty(eventWorkspace)) {
      this.props.updateEventWorkspace(eventWorkspace);
    } else {
      this.props.updateEventWorkspace({});
      this.props.updateEventOutput("");
    }

    this.props.navigate(
      Enums.BuilderPath.EVENT_HANDLER.MAIN +
        "/" +
        Enums.BuilderPath.EVENT_HANDLER.BUILDER
    );
    Popup.close();
  };

  render() {
    const renderComponentDetails = () => {
      if (!ObjectUtils.isEmpty(this.props.component)) {
        const component = { ...this.props.component };
        const DraggableComponent = lazy(() =>
          import(
            "components/builder/ui/uiComponents/" + component.componentClass
          )
        );
        return (
          <Suspense fallback={<div></div>}>
            <DraggableComponent
              compId={component.compId}
              componentInfo={component}
              output={this.props.output}
              fn={{
                updateProperty: this.updateProperty,
                createComponent: this.createComponent,
                addComponent: this.addComponent,
                deleteComponent: this.deleteComponent,
                getDataModel: this.getDataModel,
                getEntity: this.getEntity,
                getEntityType: this.getEntityType,
                isTableType: this.isTableType,
                getForm: this.getForm,
                getEventTargetNode: this.getEventTargetNode,
                changeComponentType: this.changeComponentType,
                getComponentCodeFromOutput: this.getComponentCodeFromOutput,
                getChild: this.getChild,
                refresh: this.refresh,
                getGridInfo: this.getGridInfo,
                getGridEntityType: this.getGridEntityType,
                isGridEntityTableType: this.isGridEntityTableType,
                updateEditorAttr: this.updateEditorAttr,
                updateNodeInfo: this.updateNodeInfo,
                updateService: this.props.updateService,
                updateOutputUI: this.props.updateOutputUI,
                updateActivateEditorAttr: this.props.updateActivateEditorAttr,
                updateActivateProps: this.props.updateActivateProps,
                onClickEventBuilder: this.onClickEventBuilder,
              }}
              navigate={this.props.navigate}
              workspace={this.props.workspace}
              mobile={this.props.mobile}
              event="renderPropertiesPanel"
            />
          </Suspense>
        );
      } else {
        return <div className="empty-properties">No Selected Component.</div>;
      }
    };
    return (
      <Scrollbar
        className="custom-class"
        plugins={{
          overscroll: {
            effect: "glow",
          },
        }}
      >
        <div
          className="properties-component-panel"
          style={{
            height: `${
              StringUtils.equalsIgnoreCase(this.props.trdUseYn, "Y") &&
              "calc(100vh - 50px - 55px)"
            }`,
          }}
        >
          <div>{renderComponentDetails()}</div>
        </div>
      </Scrollbar>
    );
  }
}

const dispatchUpdateProperty = (dispatch) => {
  return {
    updateOutput: (output, componentInfo, propertyValue, targetComponent) =>
      UIReduxHelper.updateOutputByProps(
        dispatch,
        output,
        componentInfo,
        propertyValue,
        targetComponent
      ),
    updatePage: (output, componentInfo, propertyValue) =>
      UIReduxHelper.updatePageByProps(
        dispatch,
        output,
        componentInfo,
        propertyValue,
        true
      ),
    updatePageWithFooter: (
      output,
      componentInfo,
      footerTemplate,
      propertyValue
    ) =>
      UIReduxHelper.updatePageWithFooterByProps(
        dispatch,
        output,
        componentInfo,
        footerTemplate,
        propertyValue
      ),
    createComponent: (
      output,
      componentInfo,
      components,
      propertyValue,
      type,
      activedComponent
    ) =>
      Event.createComponent(
        null,
        type,
        componentInfo,
        output,
        components,
        dispatch,
        propertyValue,
        activedComponent
      ),
    addComponent: (
      output,
      componentInfo,
      components,
      propertyValue,
      type,
      activedComponent
    ) =>
      Event.insertNode(
        null,
        type,
        componentInfo,
        output,
        components,
        dispatch,
        propertyValue,
        activedComponent
      ),
    deleteComponent: (output, componentInfo, components, pageCompnent) =>
      Event.removeNode(
        null, //event
        componentInfo, //item
        output,
        components,
        pageCompnent,
        dispatch
      ),
    changeComponentType: (output, componentInfo, newComponent) =>
      UIReduxHelper.changeComponentType(
        output,
        componentInfo,
        newComponent,
        dispatch
      ),
    updateService: (service) => {
      dispatch(initCommand());
      dispatch(initDebug());
      dispatch(updateService(service));
    },
    updateOutputUI: (output) => UIReduxHelper.updateOutput(output, dispatch),
    updateActivateEditorAttr: (editorAttr) => {
      dispatch(updateActivateEditorAttr(editorAttr));
    },
    updateActivateProps: (propertyValue) => {
      dispatch(updateActivateProps(propertyValue));
    },
    updateEventOutput: (payload) => dispatch(updateEventOutput(payload)),
    updateEventWorkspace: (payload) => dispatch(updateEventWorkspace(payload)),
    updateEventWorkspacePath: (payload) =>
      dispatch(updateEventWorkspacePath(payload)),
  };
};

export default connect((state) => {
  return {
    component: state.activedUIComponent.component,
    output: state.outputUI.output,
    workspace: state.workspace,
    mobile: state.mobile,
  };
}, dispatchUpdateProperty)(PropertiesTab);
